import { openDB } from 'idb';

let dbInstance, transaction, spectraTable;

const initDb = async () => {
    dbInstance = await openDB('scatr_offline', 1, {
        upgrade(db, oldVersion) {
            if (oldVersion === 0) {
                db.createObjectStore('spectra', {keyPath: 'scanTimestamp'});
            }
        }
    });
}

const loadTransaction = () => {
    transaction = dbInstance.transaction('spectra', 'readwrite');
    spectraTable = transaction.objectStore('spectra');
}

export const addSpectraToOfflineDb = async (data) => {
    try {
        if (!dbInstance) {
            await initDb();
        }
        loadTransaction();

        await spectraTable.add(data)
        await transaction.complete;
    } catch (error) {
        console.error('error during spectra upload to indexdb: ', error)
        throw error;
    }
}

export const deleteMapFromOffline = async (mapID) => {
    if (!dbInstance) {
        await initDb();
    }
    loadTransaction();

    try {
        await spectraTable.delete(mapID);
    } catch (error) {
        console.error(error);
    }
}

export const updateSpectraInOfflineDb = async (record) => {
    if (!dbInstance) {
        await initDb();
    }
    loadTransaction();
    try {
        let result = await spectraTable.get(record.scanTimestamp);
        // await transaction.complete;
        result.expected_substances = record.expected_substances;
        result.impact = record.impact;
        result.client_code = record.client_code;
        result.notes = record.notes;
        result.share_notes = record.share_notes;
        result.substance_description = record.substance_description;
        result.substance_strength = record.substance_strength;

        await spectraTable.put(result);
        return result;
    } catch (error) {
        console.error('error during spectra fetch from indexdb: ', error)
    }
}

export const fetchSpectraFromOfflineDb = async () => {
    if (!dbInstance) {
        await initDb();
    }
    loadTransaction();

    try {
        let result = await spectraTable.getAll();
        await transaction.complete;
        spectraTable.clear();
        return result;
    } catch (error) {
        console.error('error during spectra fetch from indexdb: ', error)
    }
}
