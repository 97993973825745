import React, { useState, useEffect } from 'react';
import { useAtom } from 'jotai';
import ReactEcharts from 'echarts-for-react';
import { cloneDeep } from 'lodash';
import {
    pointsPerSideState,
    numLevelsState,
    numPointsState,
} from '../stateManagement/processState';
import { colorsState } from '../stateManagement/graphState';
import { mapState } from '../stateManagement/commonState';
import { getIntensityScatterChartConfig } from './configs/intensityScatterChartConfig';

const IntensityScatterChart = ({ updateSpectrumLineChart }) => {
    const [pointsPerSide] = useAtom(pointsPerSideState);
    const [numLevels] = useAtom(numLevelsState);
    const [numPoints] = useAtom(numPointsState);
    const [colors] = useAtom(colorsState);
    const [map] = useAtom(mapState);

    const getMarkArea = () => {
        let markAreaData = [];
        for (let i = 1; i < numLevels; i += 2) {
            markAreaData.push([
                {
                    xAxis: pointsPerSide ** 2 * i - 0.5
                },
                {
                    xAxis: pointsPerSide ** 2 * (i + 1) - 0.5
                }
            ])
        }
        return markAreaData;
    }

    const intensityChartEventHandler = {
        click: e => {
            if (map[e.dataIndex]) {
                updateSpectrumLineChart({
                    index: e.dataIndex,
                    rawSpectrum: map[e.dataIndex].rawSpectrum,
                    badPixelCorrectedSpectrum: map[e.dataIndex].badPixelCorrectedSpectrum,
                    smoothedSpectrum: map[e.dataIndex].smoothedSpectrum,
                    baselineCorrectedSpectrum: map[e.dataIndex].baselineCorrectedSpectrum,
                    intensityCorrectedSpectrum: map[e.dataIndex].intensityCorrectedSpectrum,
                    normalizedSpectrum: map[e.dataIndex].normalizedSpectrum,
                    interpolatedSpectrum: map[e.dataIndex].interpolatedSpectrum,
                    processedSpectrum: map[e.dataIndex].processedSpectrum,
                    integrationTime: map[e.dataIndex].integrationTime
                });
            }
        },
    }

    const [intensityScatterChartOption, setIntensityScatterChartOption] = useState(getIntensityScatterChartConfig(colors, getMarkArea(), map, numPoints));

    useEffect(() => {
        // let sortedResults = map.map(e => {
        //     let sorted = e.result.map((e, i) => [substanceArray[i], e]).sort((a, b) => b[1] - a[1]);
        //     return sorted.map(e => [e[0], Math.round(e[1] * 1000) / 1000]);
        // });
        setIntensityScatterChartOption(prevOption => {
            let newOption = cloneDeep(prevOption);
            newOption.xAxis.data = new Array(numPoints).fill(null).map((e, i) => i);
            // newOption.yAxis.min = map.length ? Math.min(...map.map(e => Math.max(...e.rawSpectrum))) - 100 : 0;
            // newOption.yAxis.max = map.length ? Math.max(...map.map(e => Math.max(...e.rawSpectrum))) + 100 : 60000;
            newOption.yAxis.min = map.length ? null : 0;
            newOption.yAxis.max = map.length ? null : 60000;
            newOption.xAxis.max = numPoints - 1;
            newOption.series[0].data = map.length ? map.map((e, i) => [i, Math.max(...e.rawSpectrum), e.label, e.x, e.y, e.z, e.mlOutput, e.power, e.integrationTime, e.reconstructionError]) : new Array(numPoints).fill(null).map((e, i) => [i, 0, 'Unknown', 0, 0, 0]);
            newOption.series[0].markArea.data = getMarkArea();
            newOption.visualMap.categories = [...new Set(map.map(e => e.label))];
            newOption.visualMap.inRange.color = colors;
            return newOption
        });
    }, [map, numPoints, colors]);

    return (
        <ReactEcharts
            option={intensityScatterChartOption}
            onEvents={intensityChartEventHandler}
            style={{ width: "100%" }}
        />
    );
};

export default IntensityScatterChart;