import React from 'react';
import { useAtom } from 'jotai';
import { Button, Descriptions, Row, Typography, message } from 'antd';
import { addDeviceToDatabase } from '../utils/dbHelpers';
import { deviceInfoState } from '../stateManagement/commonState';

const key = 'AddDevice';

export const DeviceInfoTable = () => {
  const [deviceInfo] = useAtom(deviceInfoState);
  let deviceInfoRows = [];
  const registerDevice = () => {
    message.loading({ content: 'Adding device to database...', key, duration: 0 });
    addDeviceToDatabase({
      device_id: deviceInfo.device_id,
      spectrometer_serial: deviceInfo.spectrometer_serial,
      wavelength_calibration: deviceInfo.wavelength_calibration,
      active_pixels: deviceInfo.active_pixels,
      excitation_wavelength: deviceInfo.excitation_wavelength,
      bad_pixels: deviceInfo.bad_pixels,
      intensity_calibration: deviceInfo.intensity_calibration
    }, message, key);
  }
  if (deviceInfo?.eeprom) {
    for (const [key, value] of Object.entries(deviceInfo.eeprom)) {
      deviceInfoRows.push(<Descriptions.Item key={key} label={key}>{value.toString()}</Descriptions.Item>)
    }
    return (<>
      <Descriptions bordered={true} column={1} size="small">
        {deviceInfoRows}
      </Descriptions>
      <Row justify="center" style={{ marginTop: '10px' }}>
        <Button onClick={registerDevice}>Register Device</Button>
      </Row>
    </>);
  } else {
    let deviceValues = {
      "Scatr Device ID": deviceInfo.device_id.toString(),
      "Spectrometer Serial Number": deviceInfo.spectrometer_serial.toString(),
      "Wavelength Calibration Coefficients": deviceInfo.wavelength_calibration,
      "Active Pixels": deviceInfo.active_pixels.toString(),
      "Excitation Wavelength": deviceInfo.excitation_wavelength.toString(),
      "Bad Pixels": deviceInfo.bad_pixels,
      "Intensity Calibration Coefficients": deviceInfo.intensity_calibration
    }
    for (const [key, value] of Object.entries(deviceValues)) {
      deviceInfoRows.push(<Descriptions.Item key={key} label={key}>{typeof (value) == 'object' ? value.map(e => <>{e}<br /></>) : value}</Descriptions.Item>)
    }
    return (
      <>
        <Typography.Text>Values from most recently viewed map:</Typography.Text>
        <Descriptions bordered={true} column={1} size="small">
          {deviceInfoRows}
        </Descriptions>
      </>
    );
  }
}