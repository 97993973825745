import React, { useState, useEffect, useRef } from "react";
import { Button, Typography, Empty } from "antd"
import { CloseOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons';
import { Rnd } from "react-rnd";
import { useAtom } from "jotai";
import { isImageModalOpenState, imageFilenameState, isTestInfoModalOpenState } from "../stateManagement/commonState";

const { Text } = Typography;

const ImageModal = () => {
    const [isImageModalOpen, setIsImageModalOpen] = useAtom(isImageModalOpenState);
    const [, setIsTestInfoModalOpen] = useAtom(isTestInfoModalOpenState);
    const [imageWindowState, setImageWindowState] = useState({
        width: `${window.innerWidth > 1000 ? 300 : window.innerWidth * 0.35}px`,
        height: `${window.innerWidth > 1000 ? 300 : window.innerWidth * 0.35}px`,
        x: window.innerWidth > 1000 ? window.innerWidth / 2 - 500 - 12 : window.innerWidth * 0.05 - 12,
        y: 10,
    });
    const [imageState, setImageState] = useState({
        x: 0,
        y: 0,
    });
    const [imageZoom, setImageZoom] = useState(1);
    const [imageContainerDragDisabled, setImageContainerDragDisabled] = useState(false);
    const [imageDragDisabled, setImageDragDisabled] = useState(true);
    const [imageInfoText, setImageInfoText] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const imgRef = useRef(null);
    const [imageFilename] = useAtom(imageFilenameState);
    const [hasImageError, setHasImageError] = useState(false);

    useEffect(() => {
        if (imageFilename) {
            setImageInfoText(`Serial ${imageFilename.split('_')[0]}`);
        } else {
            setImageInfoText(null);
        }
    }, [isImageModalOpen, imageFilename]);

    useEffect(() => {
        setHasImageError(false);
    }, [imageFilename]);

    const adjustImagePosition = (imageZoom) => {
        let x = imageState.x;
        let y = imageState.y;
        const imageWidth = parseFloat(imageWindowState.width) - 12;
        const imageHeight = parseFloat(imageWindowState.height) - 12;
        if (x > ((imageZoom - 1) / 0.5 * (imageWidth / 4))) {
            x = ((imageZoom - 1) / 0.5 * (imageWidth / 4));
        } else if (x < -((imageZoom - 1) / 0.5 * (imageWidth / 4))) {
            x = -((imageZoom - 1) / 0.5 * (imageWidth / 4));
        }
        if (y > ((imageZoom - 1) / 0.5 * (imageHeight / 4))) {
            y = ((imageZoom - 1) / 0.5 * (imageHeight / 4));
        } else if (y < -((imageZoom - 1) / 0.5 * (imageHeight / 4))) {
            y = -((imageZoom - 1) / 0.5 * (imageHeight / 4));
        }
        setImageState({ x: x, y: y });
    }


    return (
        <>
            {isImageModalOpen && (
                <div style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    zIndex: 100,
                    pointerEvents: 'none'
                }}
                >

                    <Rnd
                        style={{
                            pointerEvents: 'auto',
                            borderRadius: '15px',
                            position: 'relative',
                        }}
                        size={{ width: imageWindowState.width, height: imageWindowState.height }}
                        position={{ x: imageWindowState.x, y: imageWindowState.y }}
                        onDragStop={(e, d) => {
                            setIsDragging(false);
                            setImageWindowState(prev => ({ ...prev, x: d.x, y: d.y }))
                        }}
                        onResize={(e, direction, ref, delta, position) => {
                            setImageWindowState(prev => ({
                                ...prev,
                                width: ref.style.width,
                                height: ref.style.height,
                                ...position
                            }));
                        }}
                        onResizeStop={(e, direction, ref, delta, position) => { setIsDragging(false); }}
                        lockAspectRatio={true}
                        minWidth={200}
                        maxWidth={600}
                        disableDragging={imageContainerDragDisabled}
                        onMouseDown={() => setIsDragging(true)}
                        onResizeStart={() => setIsDragging(true)}
                        onMouseMove={(e) => {
                            if (isDragging) { return; }

                            const offsetX = e.clientX - imageWindowState.x;
                            const offsetY = e.clientY - imageWindowState.y;
                            const windowWidth = parseFloat(imageWindowState.width);
                            const windowHeight = parseFloat(imageWindowState.height);
                            const edgeThreshold = 6;

                            if (
                                offsetX < edgeThreshold ||
                                offsetY < edgeThreshold ||
                                (windowWidth - offsetX) < edgeThreshold ||
                                (windowHeight - offsetY) < edgeThreshold
                            ) {
                                setImageInfoText('Resize window');
                                return;
                            }

                            if (
                                imageZoom > 1 &&
                                offsetX > windowWidth * 0.25 &&
                                offsetX < windowWidth * 0.75 &&
                                offsetY > windowHeight * 0.25 &&
                                offsetY < windowHeight * 0.75
                            ) {
                                if (imageContainerDragDisabled === false) {
                                    setImageContainerDragDisabled(true);
                                }
                                if (imageDragDisabled === true) {
                                    setImageDragDisabled(false);
                                }
                                setImageInfoText('Pan image');
                            } else {
                                if (imageContainerDragDisabled === true) {
                                    setImageContainerDragDisabled(false);
                                }
                                if (imageDragDisabled === false) {
                                    setImageDragDisabled(true);
                                }
                                setImageInfoText('Move window');
                            }
                        }}
                        onMouseOut={() => {
                            if (isDragging) { return; }
                            setImageInfoText(`Serial ${imageFilename.split('_')[0]}`);
                        }}
                    >

                        <div
                            style={{
                                backgroundColor: 'transparent',
                                position: 'relative',
                                top: '6px',
                                left: '6px',
                                width: `calc(${imageWindowState.width} - 12px)`,
                                height: `calc(${imageWindowState.height} - 12px)`,
                                zIndex: 101,
                                borderRadius: '15px',
                                overflow: 'hidden',
                                boxShadow: `0 1px 2px rgba(0,0,0,0.15), 
                                        0 2px 4px rgba(0,0,0,0.15), 
                                        0 4px 8px rgba(0,0,0,0.15), 
                                        0 8px 16px rgba(0,0,0,0.15),
                                        0 16px 32px rgba(0,0,0,0.15), 
                                        0 32px 64px rgba(0,0,0,0.15)`
                            }}>
                            {!hasImageError ?
                                <>
                                    <div
                                        className="imageBounds"
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            width: `${(imgRef?.current?.offsetWidth + ((imageZoom - 1) / 0.5 * (imgRef?.current?.offsetWidth / 2))) || parseFloat(imageWindowState.width) - 12}px`,
                                            height: `${(imgRef?.current?.offsetHeight + ((imageZoom - 1) / 0.5 * (imgRef?.current?.offsetWidth / 2))) || parseFloat(imageWindowState.height) - 12}px`,
                                            transform: 'translate(-50%, -50%)',
                                            zIndex: 102,
                                            pointerEvents: 'none',
                                        }}
                                    ></div>
                                    <Rnd
                                        style={{ pointerEvents: 'auto', borderRadius: '15px' }}
                                        enableResizing={false}
                                        position={{ x: imageState.x, y: imageState.y }}
                                        onDragStop={(e, d) => {
                                            setIsDragging(false);
                                            setImageState(prev => ({ ...prev, x: d.x, y: d.y }));
                                        }}
                                        disableDragging={imageDragDisabled}
                                        bounds=".imageBounds"
                                    >
                                        <img
                                            ref={imgRef}
                                            src={`https://scatr-sample-images.s3.amazonaws.com/${imageFilename}`}
                                            onError={() => setHasImageError(true)}
                                            style={{ width: '100%', height: '100%', transform: `scale(${imageZoom})` }}
                                            onDragStart={(e) => { e.preventDefault() }}
                                        />
                                    </Rnd>
                                </> :
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: 'white'
                                }}>
                                    <Text style={{ marginBottom: '9px' }}>Image not found.</Text>
                                    <Button type="primary" ghost onClick={() => setHasImageError(false)}>Retry</Button>
                                </div>
                            }
                        </div>
                        <Button
                            type="primary"
                            danger
                            shape="circle"
                            size="small"
                            icon={<CloseOutlined style={{ fontSize: '9px' }} />}
                            onClick={() => {
                                setIsImageModalOpen(false);
                                setIsTestInfoModalOpen(false);
                                setImageWindowState({
                                    width: `${window.innerWidth > 1000 ? 300 : window.innerWidth * 0.35}px`,
                                    height: `${window.innerWidth > 1000 ? 300 : window.innerWidth * 0.35}px`,
                                    x: window.innerWidth > 1000 ? window.innerWidth / 2 - 500 - 12 : window.innerWidth * 0.05 - 12,
                                    y: 10,
                                });
                                setImageState({
                                    x: 0,
                                    y: 0,
                                });
                                setImageZoom(1);
                            }}
                            style={{
                                position: 'fixed',
                                top: '21px',
                                left: '21px',
                                minWidth: '15px',
                                width: '15px',
                                height: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 103,
                            }}
                            onMouseMove={(e) => { e.stopPropagation(); setImageInfoText('Close window') }}
                        />
                        {!hasImageError ? <>
                            <Button
                                ghost
                                shape="circle"
                                icon={<MinusOutlined style={{ fontSize: '9px' }} />}
                                onClick={() => {
                                    setImageZoom(prev => {
                                        adjustImagePosition(prev - 0.5 > 1 ? prev - 0.5 : 1);
                                        return (prev - 0.5 > 1 ? prev - 0.5 : 1)
                                    });
                                }}
                                style={{
                                    position: 'fixed',
                                    bottom: '21px',
                                    right: '21px',
                                    minWidth: '15px',
                                    width: '15px',
                                    height: '15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 103,
                                }}
                                onMouseMove={(e) => { e.stopPropagation(); setImageInfoText('Zoom out') }}
                            />
                            <Button
                                ghost
                                shape="circle"
                                icon={<PlusOutlined style={{ fontSize: '9px' }} />}
                                onClick={() => {
                                    setImageZoom(prev => {
                                        adjustImagePosition(prev + 0.5 < 4 ? prev + 0.5 : 4);
                                        return (prev + 0.5 < 4 ? prev + 0.5 : 4)
                                    });
                                }}
                                style={{
                                    position: 'fixed',
                                    bottom: '21px',
                                    right: '42px',
                                    minWidth: '15px',
                                    width: '15px',
                                    height: '15px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 103,
                                }}
                                onMouseMove={(e) => { e.stopPropagation(); setImageInfoText('Zoom in') }}
                            />
                            {
                                !isDragging ? <Text style={{
                                    position: 'absolute',
                                    fontSize: '12px',
                                    bottom: '-30px',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    zIndex: 102,
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    color: 'white',
                                    padding: ' 5px',
                                    borderRadius: '6px',
                                    pointerEvents: 'none'
                                }}>{imageInfoText}</Text> : null
                            }
                        </> : null}

                    </Rnd>
                </div>
            )}
        </>
    )
}

export default ImageModal;