import { norm, std, add, multiply, matrix } from 'mathjs';
import PolynomialRegression from 'ml-regression-polynomial';
import solveBanded from 'solve-banded';

const l_data = 2100,
  _epsilon = Number.EPSILON,
  _infinity = Number.POSITIVE_INFINITY

let _value = Number.prototype.valueOf

export function ASPLS({
  data,
  x_data = null,
  weights = null,
  alpha = null,
  diff_order = 2,
  side = 'both',
  width_scale = 0.1,
  height_scale = 1.,
  sigma_scale = 1. / 12.,
  min_value = 2,
  max_value = 8,
  step = 1
}) {
  x_data = x_data ? x_data : linspace(1, 1, l_data)

  const added_window = Math.floor(x_data.length * width_scale)

  const max_x = Math.max(...x_data),
    min_x = Math.min(...x_data),
    x_range = max_x - min_x,
    half_added_window = added_window / 2

  let known_background = [],
    fit_x_data = [...x_data],
    fit_data = [...data],
    lower_bound = 0,
    upper_bound = 0

  let [added_left, added_right] = getEdges(data, added_window)

  let added_gaussian = gaussian(
    linspace(-half_added_window, half_added_window, added_window), height_scale * Math.abs(Math.max(...data)), 0, added_window * sigma_scale
  )

  if ((side === 'right ') || (side === 'both')) {
    var added_x = linspace(max_x, max_x + x_range * (width_scale / 2), added_window + 1)
    fit_x_data = fit_x_data.concat(added_x.slice(1, added_x.length))
    fit_data = fit_data.concat(add(added_gaussian, added_right))

    known_background = added_right
    upper_bound += added_window
  }

  if ((side === 'left ') || (side === 'both')) {
    var added_x = linspace(min_x - x_range * (width_scale / 2), min_x, added_window + 1)
    fit_x_data = added_x.slice(0, -1).concat(fit_x_data)
    fit_data = add(added_gaussian, added_left).concat(fit_data)

    known_background = known_background.concat(added_left)
    lower_bound += added_window
  }

  let added_len = added_window,
    l_fit_data = fit_data.length

  if (side === 'both') {
    added_len = 2 * added_window
  }

  // set condition: weights null
  weights = weights ? weights : Array.apply(null, Array(l_fit_data)).map(_value, 1)

  // set Alpha Array from weights
  alpha = alpha ? alpha : Array.apply(null, Array(l_fit_data)).map(_value, 1)

  let upper_idx = l_fit_data - upper_bound,
    min_sum_squares = _infinity,
    sum_squares = 0,
    baseline = [],
    fit_baseline = [],
    residual = [],
    rolled = []

  const diagonals = diffPenaltyDiagonals(l_fit_data, diff_order)

  for (let val = min_value; val <= max_value; val += step) {
    fit_baseline = fitFunction({
      y: fit_data,
      diff_order: diff_order,
      diagonals: multiply(10 ** val, diagonals),
      weights: [...weights],
      alpha: [...alpha]
    })

    // Residual update
    rolled = roll(fit_baseline, upper_bound).slice(0, added_len)
    for (let i = known_background.length; i--;) {
      residual[i] = known_background[i] - rolled[i]
    }

    // Dot product residual
    sum_squares = residual.reduce((l, r) => { return l += (r * r) }, 0);

    if (sum_squares < min_sum_squares) {
      baseline = fit_baseline.slice(lower_bound, upper_idx)
      min_sum_squares = sum_squares
    }
  }

  return baseline
}

function linspace(a, b, n) {
    if (typeof n === 'undefined') n = Math.max(Math.round(b - a) + 1, 1)
    if (n < 2) {
        return n === 1 ? [a] : []
    }
    var i, ret = Array(n)
    n--
    for (i = n; i >= 0; i--) {
        ret[i] = (i * b + (n - i) * a) / n
    }
    return ret
}

function roll(array, shift) {
    if (!isFinite(shift)) {
        throw new Error(`${shift} shift should be scalars`)
    }
    const len_data = array.length


    let positif_number = true,
        temp = [],
        temp_first_array = [],
        temp_second_array = []

    if (shift < 0) {
        positif_number = false
    }


    if (Math.abs(shift) > len_data) {
        shift = shift % len_data
        if (!positif_number) {
            shift = (-1 * shift)
        }
    }


    if (shift === 0) {
        return array
    } else if (shift < 0) {
        let abs_shift = Math.abs(shift) - 1
        temp_first_array = array.slice(0, abs_shift + 1)
        temp_second_array = array.slice(abs_shift + 1, len_data - 1)
        temp = temp_second_array.concat(temp_first_array)
    } else if (shift > 0) {
        temp_first_array = array.slice(-1 * (shift + 1), len_data - 1)
        temp_second_array = array.slice(0, -1 * (shift + 1))
        temp = temp_first_array.concat(temp_second_array)
    }

    return temp
}

function getEdges(data, pad_length, extrapolate_window = null) {
    if (pad_length === 0) {
        return [[], []]
    }

    // extrapolate_windows
    let extrapolate_windows = Array(2)
    if (extrapolate_window == null) {
        for (let i = 2; i--;) {
            extrapolate_windows[i] = pad_length
        }
    }

    // left_edge and right_edge
    var l = l_data + 2 * pad_length,
        x = Array(l),
        l_x = x.length,
        left_edge = Array(pad_length), right_edge = Array(pad_length)


    while (l--) {
        x[l] = l
    }

    const x_sliced = x.slice(pad_length, -pad_length)

    for (let i = 0, len = extrapolate_windows.length - 1; i <= len; i++) {
        if (extrapolate_windows[i] === 1) {
            var z = pad_length;

            while (z--) {
                left_edge[z] = data[-i]
                right_edge[z] = data[-i]
            }

        } else if (i === 0) {
            let poly = new PolynomialRegression(x_sliced.slice(0, extrapolate_windows[i]), data.slice(0, extrapolate_windows[i]), 1);
            left_edge = poly.predict(x.slice(0, pad_length))
        } else {
            let poly = new PolynomialRegression(x_sliced.slice(-extrapolate_windows[i], l_x), data.slice(-extrapolate_windows[i], l_data), 1)
            right_edge = poly.predict(x.slice(-pad_length, l_x))
        }

    }

    return [left_edge, right_edge]
}

function gaussian(x, height = 1.0, center = 0.0, sigma = 1.0) {
    var i = x.length, new_array = Array(i)
    while (i--) {
        new_array[i] = height * Math.exp(-0.5 * ((x[i] - center) ** 2) / Math.max(sigma, _epsilon) ** 2)

    }

    return new_array
}

function fitFunction({ y, diff_order = 2, diagonals, weights, alpha, max_iter = 100, tol = 1e-3 }) {

    let tol_history = Array(max_iter + 1),
        r_len = weights.length,
        right_hand_side = Array(r_len),
        baseline = Array(r_len),
        new_weights = [],
        residual = [],
        banded_matrix = diagonals.clone(),
        abs_d = Array(r_len),
        max_abs = 0

    let m_size = banded_matrix._size[0],
        n_size = banded_matrix._size[1]

    for (let i = max_iter + 1; i--;) {
        // Banded Matrix
        for (let j = m_size; j--;) {
            for (let k = n_size; k--;) {
                banded_matrix._data[j][k] = diagonals._data[j][k] * alpha[k]

                if (j === diff_order) {
                    banded_matrix._data[j][k] = (banded_matrix._data[j][k] + weights[k]).toPrecision(12)
                }
            }
        }

        // Right Hand side
        for (let j = r_len; j--;) {
            right_hand_side[j] = weights[j] * y[j]
        }

        // The output of solved banded will saved to the 4th parameter (right hand side)
        let is_solved = solveBanded(banded_matrix._data, diff_order, diff_order, right_hand_side, r_len)
        if (is_solved) {
            for (let j = r_len; j--;) {
                baseline[j] = right_hand_side[j]
            }
        } else {
            break
        }

        [new_weights, residual] = weightingASPLS(y, right_hand_side)

        tol_history[i] = relativeDifference(weights, new_weights)

        if (tol_history[i] < tol) { break }

        var j = r_len
        while (j--) {
            abs_d[j] = Math.abs(residual[j])

            // save max value
            if (abs_d[j] > max_abs) {
                max_abs = abs_d[j]
            }
        }

        // Update Weights dan Alpha Array
        for (let j = weights.length; j--;) {
            weights[j] = new_weights[j]
        }

        for (let j = alpha.length; j--;) {
            alpha[j] = abs_d[j] / max_abs
        }
    }

    return baseline
}

function weightingASPLS(y, baseline) {
    let r_len = y.length,
        weights = Array(r_len),
        residual = Array(r_len),
        target_residual = [],
        std_val = 0,
        std_temp = 0

    // Residual and Target Residual (<0)
    for (let i = r_len; i--;) {
        residual[i] = y[i] - baseline[i]

        if (residual[i] < 0) {
            target_residual.push(residual[i])
        }
    }


    // _safe_std
    std_val = _epsilon
    std_temp = std(target_residual)

    if ((std_temp !== 0) && (target_residual.length >= 2)) {
        std_val = std_temp
    }

    // Weights Update
    for (let i = r_len; i--;) {
        weights[i] = expit(-(0.5 / std_val) * (residual[i] - std_val))
    }

    return [weights, residual]
}

function expit(x) {
    return Math.exp(x) / (1 + Math.exp(x))
}

function relativeDifference(old_val, new_val, norm_order = null) {
    let numerator = 0,
        denominator = 0,
        l_n = new_val.length,
        subtracted = Array(l_n)


    let i = l_n
    while (i--) {
        subtracted[i] = new_val[i] - old_val[i]
    }


    if (norm_order != null) {
        numerator = norm(subtracted, norm_order)
        denominator = Math.max(norm(old_val, norm_order), _epsilon)
    } else {
        numerator = norm(subtracted)
        denominator = Math.max(norm(old_val), _epsilon)
    }

    return numerator / denominator
}

function diffPenaltyDiagonals(data_size, diff_order = 2) {
    switch (diff_order) {
        case 1:
            return diff1Diag(data_size)
        case 2:
            return diff2Diag(data_size)
        default:
            throw new Error('consider using difforder 1 or 2')
    }
}

function diff2Diag(data_size) {

    let output = new Array(5), i = output.length
    while (i--) {
        output[i] = Array.apply(null, Array(data_size)).map(_value, 1)
    }
    output = matrix(output)

    const output_d = output._data,
        m_size = output._size[0],
        n_size = output._size[1],
        m_size_1 = m_size - 1,
        n_size_1 = n_size - 1,
        m_size_2 = m_size - 2,
        n_size_2 = n_size - 2,
        m_size_3 = m_size - 3

    output_d[m_size_1][n_size_1] = output_d[m_size_1][n_size_2] = output_d[m_size_2][n_size_1] = 0
    output_d[m_size_2][0] = output_d[m_size_2][n_size_2] = -2

    for (let i = 1; i < n_size_2; i++) {
        output_d[m_size_2][i] = -4
    }

    output_d[m_size_3][1] = output_d[m_size_3][n_size_2] = 5

    for (let i = 2; i < n_size_2; i++) {
        output_d[m_size_3][i] = 6
    }

    // not lower
    output_d[0][0] = output_d[1][0] = output_d[0][1] = 0
    output_d[1][1] = output_d[1][n_size_1] = -2

    for (let i = 2; i < n_size_1; i++) {
        output_d[1][i] = -4
    }

    return output
}

function diff1Diag(data_size) {
    let output = matrix(Array.apply(null, Array(data_size)).map(_value, -1)),
        output_d = output._data

    const m_size = output._size[0],
        n_size = output._size[1],
        m_size_1 = m_size - 1,
        n_size_1 = n_size - 1,
        m_size_2 = m_size - 2

    output_d[m_size_1][n_size_1] = 0
    output_d[m_size_2][0] = output_d[m_size_2][n_size_1] = 1

    for (let i = 1; i < n_size_1; i++) {
        output_d[m_size_2][i] = 2
    }

    // not lower
    output_d[0][0] = 0
}
