import React, { useState, useEffect } from "react";
import { Modal, Descriptions, Typography } from "antd"
import { useAtom } from "jotai";
import { isSubstanceInfoModalOpenState, infoModalSubstanceState, allSubstancesDataState } from '../stateManagement/commonState';
const { Text } = Typography;

const SubstanceInfoModal = () => {

    const [isSubstanceInfoModalOpen, setIsSubstanceInfoModalOpen] = useAtom(isSubstanceInfoModalOpenState);
    const [infoModalSubstance, setInfoModalSubstance] = useAtom(infoModalSubstanceState);
    const [allSubstancesData] = useAtom(allSubstancesDataState);
    const [substanceInfo, setSubstanceInfo] = useState(null);

    useEffect(() => {
        fetchPubchemDataAndUpdateInfo(infoModalSubstance);
    }, [infoModalSubstance]);

    const fetchPubchemDataAndUpdateInfo = async (substance) => {
        
        let substanceData = allSubstancesData[substance];
        if (substanceData?.info) {
            setSubstanceInfo([
                {
                    key: 'overview',
                    label: 'Overview',
                    children: <Text style={{ whiteSpace: 'pre-line' }}>{substanceData.info.overview}</Text>
                },
                {
                    key: 'effects',
                    label: 'Effects',
                    children: <Text style={{ whiteSpace: 'pre-line' }}>{substanceData.info.effects}</Text>
                },
                {
                    key: 'safety',
                    label: 'Safety',
                    children: <Text style={{ whiteSpace: 'pre-line' }}>{substanceData.info.safety}</Text>
                }
            ]);
        }
    }

    return (
        <Modal
            open={isSubstanceInfoModalOpen}
            onCancel={() => setIsSubstanceInfoModalOpen(false)}
            footer={null}
        >
            <Descriptions title={infoModalSubstance} bordered={true} column={1} size="small" layout="vertical" items={substanceInfo} />
        </Modal>
    )
}

export default SubstanceInfoModal;