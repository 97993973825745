import React, { useState, useEffect, useRef } from "react";
import { Button, Descriptions, Select } from "antd"
import { CloseOutlined } from '@ant-design/icons';
import { Rnd } from "react-rnd";
import { useAtom } from "jotai";
import { isInspectionModalOpenState, inspectionModalFeedState } from "../stateManagement/commonState";
import classes from './InspectionModal.module.css';

const InspectionModal = ({ map }) => {
    const [selectedSubstance, setSelectedSubstance] = useState('Fentanyl');
    const [isInspectionModalOpen, setIsInspectionModalOpen] = useAtom(isInspectionModalOpenState);
    const [modalWindowState, setModalWindowState] = useState({
        width: '360px',
        height: '639px',
        x: 10,
        y: 10,
    });
    const [inspectionModalFeed] = useAtom(inspectionModalFeedState);
    const [pointIndex, setPointIndex] = useState(null);
    const [traceDetectionStatus, setTraceDetectionStatus] = useState(null);
    const [prerequisites, setPrerequisites] = useState([
        { prerequisiteLabel: 'Label Approved', prerequisiteValue: null, passed: null },
    ]);
    const descriptionsComponentRef = useRef(null);
    const [maxHeight, setMaxHeight] = useState(360);

    const mapRef = useRef(null);

    const wavenumberIntensities = [];
    const [maxTraceValues, setMaxTraceValues] = useState([]);

    const fentanylLabels = ['Acetylfentanyl', 'Carfentanil', 'Despropionyl fentanyl', 'Fentanyl', 'Furanylfentanyl', 'Parafluorofentanyl', 'Remifentanil'];
    const hydromorphoneLabels = ['Hydromorphone'];
    const benzodiazepineLabels = ['Alprazolam', 'Bromazepam', 'Bromazolam', 'Clonazepam', 'Desalkylgidazepam', 'Diazepam', 'Etizolam', 'Flubromazepam', 'Flurazepam', 'Lorazepam', 'Oxazepam', 'Temazepam', 'Triazolam'];
    const xylazineLabels = ['Xylazine', 'Xylazine hydrochloride'];

    const getMaxTraceValues = (map, labels, predictionKey) => {
        let maxValues = Array.from({ length: labels.length }, () => 0);
        let maxValuesIndex = Array.from({ length: labels.length }, () => null);
        map.forEach(point => {
            if (!['Unknown', 'No Signal'].includes(point.label)) {
                point[predictionKey].forEach((value, index) => {
                    if (value > maxValues[index]) {
                        maxValues[index] = value;
                        maxValuesIndex[index] = point.index;
                    }
                });
            }
        });
        return maxValues.map((value, index) => ({
            label: labels[index],
            maxValue: value,
            maxValueIndex: maxValuesIndex[index]
        }));
    }

    useEffect(() => {
        mapRef.current = map;
    }, [map]);

    useEffect(() => {
        setTraceDetectionStatus(null);
        if (selectedSubstance === 'Fentanyl') {
            setPrerequisites([{ prerequisiteLabel: 'Label Approved', prerequisiteValue: null, passed: null }]);
            setMaxTraceValues(getMaxTraceValues(mapRef.current, fentanylLabels, 'fentanylPrediction'));
        } else if (selectedSubstance === 'Hydromorphone') {
            setPrerequisites([{ prerequisiteLabel: 'Label Approved', prerequisiteValue: null, passed: null }]);
            setMaxTraceValues(getMaxTraceValues(mapRef.current, hydromorphoneLabels, 'hydromorphonePrediction'));
        } else if (selectedSubstance === 'Benzodiazepine') {
            setPrerequisites([{ prerequisiteLabel: 'Label Approved', prerequisiteValue: null, passed: null }]);
            setMaxTraceValues(getMaxTraceValues(mapRef.current, benzodiazepineLabels, 'benzodiazepinePrediction'));
        } else if (selectedSubstance === 'Xylazine') {
            setPrerequisites([{ prerequisiteLabel: 'Label Approved', prerequisiteValue: null, passed: null }]);
            setMaxTraceValues(getMaxTraceValues(mapRef.current, xylazineLabels, 'xylazinePrediction'));
        }
    }, [selectedSubstance]);

    useEffect(() => {
        if (descriptionsComponentRef.current) {
            const contentHeight = descriptionsComponentRef.current.offsetHeight;
            setMaxHeight(contentHeight + 92);
        }
    }, [descriptionsComponentRef]);

    useEffect(() => {
        if (inspectionModalFeed) {
            setPointIndex(inspectionModalFeed.index);
            const { label, processedSpectrum, result, normalizedSpectrum, mlOutput } = inspectionModalFeed;
            if (selectedSubstance === 'Fentanyl') {
                let prerequisites = [
                    { prerequisiteLabel: 'Label Approved', prerequisiteValue: label, passed: false },
                ];
                setPrerequisites(prerequisites);
            } else if (selectedSubstance === 'Hydromorphone') {
                setPrerequisites([
                    { prerequisiteLabel: 'Label Approved', prerequisiteValue: label, passed: false }
                ]);
            } else if (selectedSubstance === 'Benzodiazepine') {
                setPrerequisites([
                    { prerequisiteLabel: 'Label Approved', prerequisiteValue: label, passed: false },
                ]);
            } else if (selectedSubstance === 'Xylazine') {
                setPrerequisites([
                    { prerequisiteLabel: 'Label Approved', prerequisiteValue: label, passed: false },
                ]);
            }
        }
        console.log(inspectionModalFeed);
    }, [inspectionModalFeed]);

    const items = [
        {
            key: 'index',
            label: 'Index',
            span: 2,
            children: <div>{pointIndex ?? '-'}</div>,
        },
        {
            key: 'status',
            label: 'Status',
            span: 2,
            children: <div>{traceDetectionStatus ?? '-'}</div>,
        },
        ...(prerequisites.length ? [{
            key: 'prerequisites',
            label: 'Prerequisites',
            span: 3,
            children: <table border="1" cellPadding="5" style={{ textAlign: 'center', fontSize: '12px', border: '1px solid rgba(5, 5, 5, 0.06)' }}>
                <thead>
                    <tr>
                        <th>Condition</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {prerequisites.map(log => (
                        <tr key={log.prerequisiteLabel} style={{ backgroundColor: log.passed === null ? 'transparent' : log.passed ? '#d9f7be' : '#ffccc7' }}>
                            <td>{log.prerequisiteLabel ?? '-'}</td>
                            <td>{log.prerequisiteValue ?? '-'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        }] : []),
        {
            key: 'maxTraceValues',
            label: 'Max Trace Values',
            children: <table border="1" cellPadding="5" style={{ textAlign: 'center', fontSize: '12px', border: '1px solid rgba(5, 5, 5, 0.06)' }}>
                <thead>
                    <tr>
                        <th width={'40%'}>Substance</th>
                        <th width={'30%'}>Value</th>
                        <th width={'30%'}>Index</th>
                    </tr>
                </thead>
                <tbody>
                    {maxTraceValues.map(e => (
                        <tr key={e.label}>
                            <td>{e.label ?? '-'}</td>
                            <td>{e.maxValue?.toFixed(4) ?? '-'}</td>
                            <td>{e.maxValueIndex ?? '-'}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        },

    ];

    return (
        <>
            {isInspectionModalOpen && (
                <div style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    zIndex: 100,
                    pointerEvents: 'none'
                }}
                >

                    <Rnd
                        style={{
                            pointerEvents: 'auto',
                            borderRadius: '15px',
                            position: 'relative',
                        }}
                        size={{ width: modalWindowState.width, height: modalWindowState.height }}
                        position={{ x: modalWindowState.x, y: modalWindowState.y }}
                        onDragStop={(e, d) => {
                            setModalWindowState(prev => ({ ...prev, x: d.x, y: d.y }))
                        }}
                        onResize={(e, direction, ref, delta, position) => {
                            setModalWindowState(prev => ({
                                ...prev,
                                // width: ref.style.width,
                                height: ref.style.height,
                                ...position
                            }));
                        }}
                        lockAspectRatio={true}
                        enableResizing={{
                            bottom: true,
                        }}
                        minHeight={360}
                        maxHeight={maxHeight}
                    >
                        <div
                            style={{
                                backgroundColor: '#f5f5f5',
                                position: 'relative',
                                top: '0px',
                                left: '0px',
                                width: `calc(${modalWindowState.width})`,
                                height: `calc(${modalWindowState.height})`,
                                zIndex: 101,
                                borderRadius: '15px',
                                padding: '15px'
                            }}>
                            <Select
                                defaultValue="Fentanyl"
                                style={{
                                    width: `100%`,
                                    marginTop: '24px',
                                    textAlign: 'center',
                                }}
                                dropdownStyle={{
                                    textAlign: 'center'
                                }}
                                onChange={(e) => setSelectedSubstance(e)}
                                options={[
                                    {
                                        value: 'Fentanyl',
                                        label: 'Fentanyl',
                                    },
                                    {
                                        value: 'Hydromorphone',
                                        label: 'Hydromorphone',
                                    },
                                    {
                                        value: 'Benzodiazepine',
                                        label: 'Benzodiazepine',
                                    },
                                    {
                                        value: 'Xylazine',
                                        label: 'Xylazine',
                                    },
                                ]}
                            />
                            <div
                                style={{
                                    width: '100%',
                                    height: `calc(${modalWindowState.height} - 90px)`,
                                    marginTop: '6px',
                                    overflow: 'auto',
                                }}
                                className={classes.popup}
                            >
                                <div ref={descriptionsComponentRef}>
                                    <Descriptions
                                        layout="vertical" bordered
                                        items={items}
                                        size="small"
                                        labelStyle={{ fontSize: '12px' }}
                                        contentStyle={{ fontSize: '12px', lineHeight: '9px', height: '9px' }} />
                                </div>
                            </div>
                        </div>
                        <Button
                            type="primary"
                            danger
                            shape="circle"
                            size="small"
                            icon={<CloseOutlined style={{ fontSize: '9px' }} />}
                            onClick={() => {
                                setIsInspectionModalOpen(false);
                            }}
                            style={{
                                position: 'fixed',
                                top: '18px',
                                left: '18px',
                                minWidth: '15px',
                                width: '15px',
                                height: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 103,
                            }}
                        />
                    </Rnd>
                </div>
            )}
        </>
    )
}

export default InspectionModal;

// const mlOutput = mapRef.current[e.data[4]].mlOutput;
// console.log(mapRef.current[e.data[4]]);
// console.log(mapRef.current);

// const fentanylLabels = ['Acetylfentanyl', 'Carfentanil', 'Despropionyl fentanyl', 'Fentanyl', 'Furanylfentanyl', 'Parafluorofentanyl', 'Remifentanil'];
// const fentanylPrediction = mapRef.current[e.data[4]].fentanylPrediction;
// const fentanylPredictionClassification = fentanylPrediction.slice(fentanylPrediction.length / 2);
// const passingFentanyls = [];
// // console.log(mlOutput);
// console.log(mlOutput.filter(row => fentanylLabels.includes(row[0])));
// console.log(fentanylPrediction.map((e, i) => [fentanylLabels[i], e]).sort((a, b) => b[1] - a[1]));
// fentanylPrediction.forEach((e,i) => {
//   if (e > 0.07575) { 
//     const indexML = mlOutput.findIndex(row => row[0] === fentanylLabels[i]);
//     const values = [mlOutput[indexML][2], mlOutput[indexML][3]];
//     const max = Math.max(...values);
//     const min = Math.min(...values);
//     const difference = max / min;
//     const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
//     const variance = values.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / values.length;
//     const standardDeviation = Math.sqrt(variance);
//     passingFentanyls.push({label: fentanylLabels[i], traceMLValue: e, standardDeviation, difference});
//   }
// });
// console.log(passingFentanyls.sort((a, b) => b.traceMLValue - a.traceMLValue))


// const benzodiazepineLabels = ['Alprazolam', 'Bromazepam', 'Bromazolam', 'Clonazepam', 'Desalkylgidazepam', 'Diazepam', 'Etizolam', 'Flubromazepam', 'Flurazepam', 'Lorazepam', 'Oxazepam', 'Temazepam', 'Triazolam'];
// const benzodiazepinePrediction = mapRef.current[e.data[4]].benzodiazepinePrediction;
// // const benzodiazepinePredictionClassification = benzodiazepinePrediction.slice(benzodiazepinePrediction.length / 2);
// const benzodiazepinePredictionClassification = benzodiazepinePrediction;
// const passingBenzodiazepines = [];
// // console.log(mlOutput);
// console.log(mlOutput.filter(row => benzodiazepineLabels.includes(row[0])));
// // console.log(benzodiazepinePrediction.map((e, i) => [benzodiazepineLabels[i], e]).sort((a, b) => b[1] - a[1]));
// // console.log(benzodiazepinePrediction.slice(0, benzodiazepinePrediction.length / 2).map((e, i) => [benzodiazepineLabels[i], e]).sort((a, b) => b[1] - a[1]));
// // console.log(benzodiazepinePrediction.slice(benzodiazepinePrediction.length / 2).map((e, i) => [benzodiazepineLabels[i], e]).sort((a, b) => b[1] - a[1]));
// benzodiazepinePredictionClassification.forEach((e,i) => {
//   if (e > 0) { 
//     const indexML = mlOutput.findIndex(row => row[0] === benzodiazepineLabels[i]);
//     const values = [mlOutput[indexML][2], mlOutput[indexML][3]];
//     const max = Math.max(...values);
//     const min = Math.min(...values);
//     const difference = max / min;
//     const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
//     const variance = values.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / values.length;
//     const standardDeviation = Math.sqrt(variance);
//     passingBenzodiazepines.push({label: benzodiazepineLabels[i], traceMLValue: e, standardDeviation, difference});
//   }
// });
// console.log(passingBenzodiazepines.sort((a, b) => b.traceMLValue - a.traceMLValue))
// console.log(passingBenzodiazepines.sort((a, b) => b.traceMLValue - a.traceMLValue)[0])

// const xylazineLabels = ['Xylazine', 'Xylazine hydrochloride'];
// const xylazinePrediction = mapRef.current[e.data[4]].xylazinePrediction;
// // const xylazinePredictionClassification = xylazinePrediction.slice(xylazinePrediction.length / 2);
// const xylazinePredictionClassification = xylazinePrediction;
// const passingXylazines = [];
// console.log(mlOutput.filter(row => xylazineLabels.includes(row[0])));
// xylazinePredictionClassification.forEach((e, i) => {
//   if (e > 0.15) {
//     const indexML = mlOutput.findIndex(row => row[0] === xylazineLabels[i]);
//     const values = [mlOutput[indexML][2], mlOutput[indexML][3]];
//     const max = Math.max(...values);
//     const min = Math.min(...values);
//     const difference = max / min;
//     const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
//     const variance = values.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / values.length;
//     const standardDeviation = Math.sqrt(variance);
//     let threshold = e >= 0.9 ? 0.0075 : 0.02;
//     if (min >= threshold) {
//     // if (mlOutput[indexML][1] > 0.015) {
//         passingXylazines.push({ label: xylazineLabels[i], traceMLValue: e, standardDeviation, difference });
//     }
//   }
// });
// console.log(passingXylazines.sort((a, b) => b.traceMLValue - a.traceMLValue));