import { atom } from 'jotai';
const pointsPerSideValue = 20;
const numLevelsValue = 1;

export const defaultCalibrationInfo = {
    calibrate: true,
    calibrating: false,
    firstXYCalibration: {
        active: false,
        data: [],
        settings: {
            pointsPerSide: 5,
            numLevels: 1,
            scanWidth: 4,
            laserPower: 450,
            integrationTime: 500,
            signalThreshold: 150
        },
        calculated: {
            center_x: null,
            center_y: null,
            scanWidth: null,
        }
    },
    firstHeightCalibration: {
        active: false,
        data: [],
        settings: {
            pointsPerSide: 1,
            numLevels: 21,
            scanHeight: 2,
            center_z: 14.25,
        },
        calculated: {
            center_z: null,
        }
    },
    secondXYCalibration: { 
        active: false,
        data: [],
        settings: {
            pointsPerSide: 6,
            numLevels: 1,
            signalThreshold: 250
        },
        calculated: {
            center_x: null,
            center_y: null,
        }
    },
    secondHeightCalibration: { 
        active: false,
        data: [],
        settings: {
            pointsPerSide: 1,
            numLevels: 21,
            scanHeight: 1,
        },
        calculated: {
            center_z: null
        }
    },
}

export const scanningState = atom(false);

export const calibratingState = atom(false);

export const scanHeightState = atom(1);

export const scanWidthState = atom(5);

export const calibrationState = atom([]);

export const pointsPerSideState = atom(pointsPerSideValue);

export const numLevelsState = atom(numLevelsValue);

export const numPointsState = atom(Math.pow(pointsPerSideValue, 2) * numLevelsValue);

export const singlePointMultiplierState = atom(1);

export const currentPathState = atom([]);

export const calibrationInfoState = atom(defaultCalibrationInfo);

export const scanTypeState = atom('standard');