import { cross } from 'mathjs';
var nerdamer = require('nerdamer');
require('nerdamer/Solve');

export const generateSectionVertexIndices = numPointsPerSide => {
	let numSections = (numPointsPerSide - 1) ** 2;
	let sectionRow = 0;
	let sectionVertexIndices = [];
	for (let section = 0; section < numSections; section++) {
		if (section && section % (numPointsPerSide - 1) === 0) { sectionRow++ }
		sectionVertexIndices.push([section, section + 1, numPointsPerSide + section + 1, numPointsPerSide + section].map(index => index + sectionRow));
	}
	return sectionVertexIndices;
}

export const generateCalibrationCoordinates = (numCalibrationPointsPerSide, center, scanWidth) => {
	let calibrationCoordinates = [];
	let step = scanWidth / (numCalibrationPointsPerSide - 1);
	for (let i = 0; i < numCalibrationPointsPerSide; i++) {
		for (let j = 0; j < numCalibrationPointsPerSide; j++) {
			calibrationCoordinates.push([center.x + scanWidth / 2 - (j * step), center.y + scanWidth / 2 - (i * step)]);
		}
	}
	return calibrationCoordinates;
}

export const getCalibrationPoints = (numVerticalPoints, center, calibrationRange, calibrationCoordinates) => {
	let levels = new Array(numVerticalPoints).fill(0).map((e, i) => ((center.z - (calibrationRange / 2)) + (i * (calibrationRange / (numVerticalPoints - 1))))).map(e => (Math.round(e * 1000) / 1000));
	let calibrationPoints = calibrationCoordinates.map(coordinate => levels.map(level => [...coordinate, level]));
	return calibrationPoints.map((e, i) => i % 2 ? e.reverse() : e).flat();
}

export const groupVerticals = (numCalibrationPointsPerSide, numVerticalCalibrationPoints, surfaceCalibration) => {
	let verticals = [];
	for (let i = 0; i < numCalibrationPointsPerSide ** 2; i++) {
		verticals.push(surfaceCalibration.slice(i * numVerticalCalibrationPoints, (i + 1) * numVerticalCalibrationPoints));
	}
	return verticals;
};

export const computeSectionAveragePoints = (calibrationPoints, sectionVertexIndices) => {
	return sectionVertexIndices.map(e => {
		let average = e.reduce((a, b) => a + calibrationPoints[b][2], 0) / 4;
		let x = (calibrationPoints[e[1]][0] + calibrationPoints[e[0]][0]) / 2;
		let y = (calibrationPoints[e[2]][1] + calibrationPoints[e[0]][1]) / 2;
		return [x, y, average];
	});
};

export const reverseArray = (array, segmentLength) => {
	for (let i = segmentLength; i < array.length; i += segmentLength * 2) {
		array.splice(i, 0, ...array.splice(i, segmentLength).reverse());
	}
	return array
};

export const interpolateVerticals = (verticals) => {
	return verticals.map(e => {
		let vals = e.sort((a, b) => Math.max(...b.rawSpectrum) - Math.max(...a.rawSpectrum)).slice(0, 3).map(e => [e.z, Math.max(...e.rawSpectrum)]);
		let eqn = nerdamer.solveEquations([`${vals[0][1]}=a*(${vals[0][0]})^2+b*(${vals[0][0]})+c`, `${vals[1][1]}=a*(${vals[1][0]})^2+b*(${vals[1][0]})+c`, `${vals[2][1]}=a*(${vals[2][0]})^2+b*(${vals[2][0]})+c`]);
		let vertex = -eqn[1][1] / (2 * eqn[0][1]);
		return eqn[0][1] < 0 && vertex > Math.min(vals[1][0], vals[2][0]) && vertex < Math.max(vals[1][0], vals[2][0]) ? Math.round(vertex * 1000) / 1000 : vals[0][0];
	});
};

export const applyCalibrationOffset = (points, center, surfacePoints, sectionAveragePoints) => {
	let newPoints = points.map(point => {
		let quadCenter = sectionAveragePoints.map(quadPoint => [...quadPoint, Math.sqrt((quadPoint[0] - point[0]) ** 2 + (quadPoint[1] - point[1]) ** 2)]).sort((a, b) => a[3] - b[3]).slice(0, 1);
		let nearestEdge = surfacePoints.map(calPoint => [...calPoint, Math.sqrt((calPoint[0] - point[0]) ** 2 + (calPoint[1] - point[1]) ** 2)]).sort((a, b) => a[3] - b[3]).slice(0, 2);
		let p = [...quadCenter, ...nearestEdge];
		let a = [p[1][0] - p[0][0], p[1][1] - p[0][1], p[1][2] - p[0][2]];
		let b = [p[2][0] - p[0][0], p[2][1] - p[0][1], p[2][2] - p[0][2]];
		let n = cross(a, b);
		point[2] = point[2] - center.z + (-(n[0] * (point[0] - p[0][0]) + n[1] * (point[1] - p[0][1])) / n[2]) + p[0][2];
		return point;
	});

	return newPoints;
};


export const generateSurfaceSeries = (calibrationPoints) => {
	return [{
		type: 'surface',
		silent: true,
		itemStyle: {
			color: '#1890ff',
			opacity: 0.6
		},
		data: calibrationPoints,
	}];
};

export const generateHeightSurfaceSeries = (center, scanWidth) => {
	return {
		type: 'surface',
		silent: true,
		itemStyle: {
			color: '#1890ff',
			opacity: 0.6
		},
		data: [
			[center.x + scanWidth / 2, center.y + scanWidth / 2, center.z],
			[center.x + scanWidth / 2, center.y - scanWidth / 2, center.z],
			[center.x - scanWidth / 2, center.y + scanWidth / 2, center.z],
			[center.x - scanWidth / 2, center.y - scanWidth / 2, center.z]
		],
	};
};