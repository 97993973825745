var nerdamer = require('nerdamer');
require('nerdamer/Solve');

export const parseEEPROM = eepromDataBuffer => {
    let eepromData = [];
    let eepromDataParsed = {};

    for (let i = 0; i < eepromDataBuffer.length; i += 64) {
        eepromData.push(eepromDataBuffer.slice(i, i + 64));
    }

    eepromDataParsed["Scatr Device ID"] = eepromData[7].slice(0, 4).readUInt32LE(0);
    eepromDataParsed["Scatr Firmware Version"] = eepromData?.[7]?.length >= 18
        ? eepromData[7].slice(4, 18).toString().replace(/ /g, '')
        : null;
    eepromDataParsed["Device Network Status"] = eepromData?.[7]?.length >= 19
        ? eepromData[7].slice(18, 19).readInt8(0)
        : null;
    eepromDataParsed["Camera Status"] = eepromData?.[7]?.length >= 20
    ? eepromData[7].slice(19, 20).readInt8(0)
    : null;
    eepromDataParsed["Model Name"] = eepromData[0].slice(0, 16).toString();
    eepromDataParsed["Serial Number"] = eepromData[0].slice(16, 32).toString().replace(/\0/g, '');
    eepromDataParsed["Baud Rate"] = eepromData[0].slice(32, 36).readUInt32LE(0);
    eepromDataParsed["Cooling Available"] = eepromData[0].slice(36, 37).readInt8(0);
    eepromDataParsed["Battery Available"] = eepromData[0].slice(37, 38).readInt8(0);
    eepromDataParsed["Laser Available"] = eepromData[0].slice(38, 39).readInt8(0);
    eepromDataParsed["Feature Mask"] = eepromData[0].slice(39, 41).readUInt16LE(0);
    eepromDataParsed["Slit Size in um"] = eepromData[0].slice(41, 43).readUInt16LE(0);
    eepromDataParsed["Startup Integration Time in ms"] = eepromData[0].slice(43, 45).readUInt16LE(0);
    eepromDataParsed["Startup Temperature in °C"] = eepromData[0].slice(45, 47).readInt16LE(0);
    eepromDataParsed["Startup Triggering Mode"] = eepromData[0].slice(47, 48).readInt8(0);
    eepromDataParsed["Gain (for InGaAs: Even Pixel Gain)"] = eepromData[0].slice(48, 52).readFloatLE(0);
    eepromDataParsed["Offset (for InGaAs: Even Pixel Offset)"] = eepromData[0].slice(52, 54).readInt16LE(0);
    eepromDataParsed["Odd Pixel Gain (InGaAs systems only)"] = eepromData[0].slice(54, 58).readFloatLE(0);
    eepromDataParsed["Odd Pixel Offset (InGaAs systems only)"] = eepromData[0].slice(58, 60).readInt16LE(0);

    eepromDataParsed["Wavelength Calibration Coeff0"] = eepromData[1].slice(0, 4).readFloatLE(0);
    eepromDataParsed["Wavelength Calibration Coeff1"] = eepromData[1].slice(4, 8).readFloatLE(0);
    eepromDataParsed["Wavelength Calibration Coeff2"] = eepromData[1].slice(8, 12).readFloatLE(0);
    eepromDataParsed["Wavelength Calibration Coeff3"] = eepromData[1].slice(12, 16).readFloatLE(0);
    eepromDataParsed["°C → DAC TEC Coeff0"] = eepromData[1].slice(16, 20).readFloatLE(0);
    eepromDataParsed["°C → DAC TEC Coeff1"] = eepromData[1].slice(20, 24).readFloatLE(0);
    eepromDataParsed["°C → DAC TEC Coeff2"] = eepromData[1].slice(24, 28).readFloatLE(0);
    eepromDataParsed["Tmax (max TEC setpoint in °C)"] = eepromData[1].slice(28, 30).readInt16LE(0);
    eepromDataParsed["Tmin (min TEC setpoint in °C)"] = eepromData[1].slice(30, 32).readInt16LE(0);
    eepromDataParsed["ADC → °C Detector Temperature Coeff0"] = eepromData[1].slice(32, 36).readFloatLE(0);
    eepromDataParsed["ADC → °C Detector Temperature Coeff1"] = eepromData[1].slice(36, 40).readFloatLE(0);
    eepromDataParsed["ADC → °C Detector Temperature Coeff2"] = eepromData[1].slice(40, 44).readFloatLE(0);
    eepromDataParsed["Thermistor Resistance at 298K"] = eepromData[1].slice(44, 46).readInt16LE(0);
    eepromDataParsed["Thermistor Beta Value"] = eepromData[1].slice(46, 48).readInt16LE(0);
    eepromDataParsed["Calibration Date"] = eepromData[1].slice(48, 60).toString().replace(/\0/g, '');
    eepromDataParsed["Calibrated By"] = eepromData[1].slice(60, 63).toString().replace(/\0/g, '');

    eepromDataParsed["Detector Name"] = eepromData[2].slice(0, 16).toString().replace(/\0/g, '');
    eepromDataParsed["Active Pixels Horizontal"] = eepromData[2].slice(16, 18).readUInt16LE(0);
    eepromDataParsed["Laser Warmup Time (sec)"] = eepromData[2].slice(18, 19).readUInt8(0);
    eepromDataParsed["Active Pixels Vertical "] = eepromData[2].slice(19, 21).readUInt16LE(0);
    eepromDataParsed["Wavelength Calibration Coeff4"] = eepromData[2].slice(21, 25).readFloatLE(0);
    eepromDataParsed["Actual Horizontal Pixels"] = eepromData[2].slice(25, 27).readUInt16LE(0);
    eepromDataParsed["ROI Horizontal Start"] = eepromData[2].slice(27, 29).readUInt16LE(0);
    eepromDataParsed["ROI Horizontal End"] = eepromData[2].slice(29, 31).readUInt16LE(0);
    eepromDataParsed["ROI Vertical Region 1 Start"] = eepromData[2].slice(31, 33).readUInt16LE(0);
    eepromDataParsed["ROI Vertical Region 1 End"] = eepromData[2].slice(33, 35).readUInt16LE(0);
    eepromDataParsed["ROI Vertical Region 2 Start"] = eepromData[2].slice(35, 37).readUInt16LE(0);
    eepromDataParsed["ROI Vertical Region 2 End"] = eepromData[2].slice(37, 39).readUInt16LE(0);
    eepromDataParsed["ROI Vertical Region 3 Start"] = eepromData[2].slice(39, 41).readUInt16LE(0);
    eepromDataParsed["ROI Vertical Region 3 End"] = eepromData[2].slice(41, 43).readUInt16LE(0);
    eepromDataParsed["Reserved: Linearity Coeff0"] = eepromData[2].slice(43, 47).readFloatLE(0);
    eepromDataParsed["Reserved: Linearity Coeff1"] = eepromData[2].slice(47, 51).readFloatLE(0);
    eepromDataParsed["Reserved: Linearity Coeff2"] = eepromData[2].slice(51, 55).readFloatLE(0);
    eepromDataParsed["Reserved: Linearity Coeff3"] = eepromData[2].slice(55, 59).readFloatLE(0);
    eepromDataParsed["Reserved: Linearity Coeff4"] = eepromData[2].slice(59, 63).readFloatLE(0);

    eepromDataParsed["Reserved: Device lifetime operation (minutes)"] = eepromData[3].slice(0, 4).readUInt32LE(0);
    eepromDataParsed["Reserved: Laser lifetime operation (minutes) "] = eepromData[3].slice(4, 8).readUInt32LE(0);
    eepromDataParsed["Reserved: Max laser temperature (°C)"] = eepromData[3].slice(8, 10).readInt16LE(0);
    eepromDataParsed["Reserved: Min laser temperature (°C)"] = eepromData[3].slice(10, 12).readInt16LE(0);
    eepromDataParsed["Laser Power perc → mW Coefficient 0"] = eepromData[3].slice(12, 16).readFloatLE(0);
    eepromDataParsed["Laser Power perc → mW Coefficient 1"] = eepromData[3].slice(16, 20).readFloatLE(0);
    eepromDataParsed["Laser Power perc → mW Coefficient 2"] = eepromData[3].slice(20, 24).readFloatLE(0);
    eepromDataParsed["Laser Power perc → mW Coefficient 3"] = eepromData[3].slice(24, 28).readFloatLE(0);
    eepromDataParsed["Maximum Laser Power (mW)"] = eepromData[3].slice(28, 32).readFloatLE(0);
    eepromDataParsed["Minimum Laser Power (mW)"] = eepromData[3].slice(32, 36).readFloatLE(0);
    eepromDataParsed["Excitation Wavelength (nm)"] = eepromData[3].slice(36, 40).readFloatLE(0);
    eepromDataParsed["Min Integration Time (ms, 24-bit)"] = eepromData[3].slice(40, 44).readUInt32LE(0);
    eepromDataParsed["Max Integration Time (ms, 24-bit)"] = eepromData[3].slice(44, 48).readUInt32LE(0);
    eepromDataParsed["Average FWHM (nm or cm-1 per Excitation)"] = eepromData[3].slice(48, 52).readFloatLE(0);

    eepromDataParsed["Bad Pixel 1"] = eepromData[5].slice(0, 2).readInt16LE(0);
    eepromDataParsed["Bad Pixel 2"] = eepromData[5].slice(2, 4).readInt16LE(0);
    eepromDataParsed["Bad Pixel 3"] = eepromData[5].slice(4, 6).readInt16LE(0);
    eepromDataParsed["Bad Pixel 4"] = eepromData[5].slice(6, 8).readInt16LE(0);
    eepromDataParsed["Bad Pixel 5"] = eepromData[5].slice(8, 10).readInt16LE(0);
    eepromDataParsed["Bad Pixel 6"] = eepromData[5].slice(10, 12).readInt16LE(0);
    eepromDataParsed["Bad Pixel 7"] = eepromData[5].slice(12, 14).readInt16LE(0);
    eepromDataParsed["Bad Pixel 8"] = eepromData[5].slice(14, 16).readInt16LE(0);
    eepromDataParsed["Bad Pixel 9"] = eepromData[5].slice(16, 18).readInt16LE(0);
    eepromDataParsed["Bad Pixel 10"] = eepromData[5].slice(18, 20).readInt16LE(0);
    eepromDataParsed["Bad Pixel 11"] = eepromData[5].slice(20, 22).readInt16LE(0);
    eepromDataParsed["Bad Pixel 12"] = eepromData[5].slice(22, 24).readInt16LE(0);
    eepromDataParsed["Bad Pixel 13"] = eepromData[5].slice(24, 26).readInt16LE(0);
    eepromDataParsed["Bad Pixel 14"] = eepromData[5].slice(26, 28).readInt16LE(0);
    eepromDataParsed["Bad Pixel 15"] = eepromData[5].slice(28, 30).readInt16LE(0);
    eepromDataParsed["Product Configuration"] = eepromData[5].slice(30, 46).toString().replace(/\0/g, '');
    eepromDataParsed["Page 6 and 7 subformat"] = eepromData[5].slice(63, 64).readInt8(0); // 1 -> NIST SRM Raman Intensity Calibration

    eepromDataParsed["Raman Intensity Calibration order"] = eepromData[6].slice(0, 1).readInt8(0);
    eepromDataParsed["Coeff0"] = eepromData[6].slice(1, 5).readFloatLE(0);
    eepromDataParsed["Coeff1"] = eepromData[6].slice(5, 9).readFloatLE(0);
    eepromDataParsed["Coeff2"] = eepromData[6].slice(9, 13).readFloatLE(0);
    eepromDataParsed["Coeff3"] = eepromData[6].slice(13, 17).readFloatLE(0);
    eepromDataParsed["Coeff4"] = eepromData[6].slice(17, 21).readFloatLE(0);
    eepromDataParsed["Coeff5"] = eepromData[6].slice(21, 25).readFloatLE(0);
    eepromDataParsed["Coeff6"] = eepromData[6].slice(25, 29).readFloatLE(0);
    eepromDataParsed["Coeff7"] = eepromData[6].slice(29, 33).readFloatLE(0);

    let numPixels = eepromDataParsed["Actual Horizontal Pixels"];
    let wavelengthCoefficients = [
        eepromDataParsed["Wavelength Calibration Coeff0"],
        eepromDataParsed["Wavelength Calibration Coeff1"],
        eepromDataParsed["Wavelength Calibration Coeff2"],
        eepromDataParsed["Wavelength Calibration Coeff3"],
        eepromDataParsed["Wavelength Calibration Coeff4"]
    ];
    let measuredExcitation = eepromDataParsed["Excitation Wavelength (nm)"];
    let intensityCoefficients = [
        eepromDataParsed["Coeff0"],
        eepromDataParsed["Coeff1"],
        eepromDataParsed["Coeff2"],
        eepromDataParsed["Coeff3"],
        eepromDataParsed["Coeff4"],
        eepromDataParsed["Coeff5"],
        eepromDataParsed["Coeff6"],
        eepromDataParsed["Coeff7"]
    ].filter(c => !isNaN(c));
    let badPixels = [
        eepromDataParsed["Bad Pixel 1"],
        eepromDataParsed["Bad Pixel 2"],
        eepromDataParsed["Bad Pixel 3"],
        eepromDataParsed["Bad Pixel 4"],
        eepromDataParsed["Bad Pixel 5"],
        eepromDataParsed["Bad Pixel 6"],
        eepromDataParsed["Bad Pixel 7"],
        eepromDataParsed["Bad Pixel 8"],
        eepromDataParsed["Bad Pixel 9"],
        eepromDataParsed["Bad Pixel 10"],
        eepromDataParsed["Bad Pixel 11"],
        eepromDataParsed["Bad Pixel 12"],
        eepromDataParsed["Bad Pixel 13"],
        eepromDataParsed["Bad Pixel 14"],
        eepromDataParsed["Bad Pixel 15"]
    ].filter(p => p !== -1);

    let lpc = [
        eepromDataParsed["Laser Power perc → mW Coefficient 0"],
        eepromDataParsed["Laser Power perc → mW Coefficient 1"],
        eepromDataParsed["Laser Power perc → mW Coefficient 2"],
        eepromDataParsed["Laser Power perc → mW Coefficient 3"]
    ];

    var toFixed = function (value, n) {
        var img = Number(nerdamer.imagpart(value).text()).toFixed(n);
        var real = Number(nerdamer.realpart(value).text()).toFixed(n);

        // Format the number assuming i denotes imaginary in your case
        var formatted = '';

        if (real !== '0.0000') {
            formatted += real;
        }

        if (img !== '0.0000') {
            // Put the plus sign betweent the real and imaginary
            if (img.charAt(0) !== '-' && formatted) {
                formatted += '+';
            }
            // Assuming you're using i and not j for instance
            formatted += img + 'i';
        }

        return formatted;
    };

    let minLaserPower = eepromDataParsed["Minimum Laser Power (mW)"];
    let maxLaserPower = eepromDataParsed["Maximum Laser Power (mW)"];

    let spectrometerSerial = eepromDataParsed["Serial Number"];

    return ({
        device_id: eepromDataParsed["Scatr Device ID"],
        device_firmware_version: eepromDataParsed["Scatr Firmware Version"],
        device_network_status: eepromDataParsed["Device Network Status"],
        device_camera_status: eepromDataParsed["Camera Status"],
        eeprom: eepromDataParsed,
        spectrometer_serial: spectrometerSerial,
        wavelength_calibration: wavelengthCoefficients,
        active_pixels: numPixels,
        excitation_wavelength: measuredExcitation,
        bad_pixels: badPixels,
        intensity_calibration: intensityCoefficients,
        minLaserPower,
        maxLaserPower,
    });
}