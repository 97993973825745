import { Steps, Row, Col } from 'antd';
import classes from '../App.module.css';

const { Step } = Steps;

export const ProgressSteps = ({ step, currentStep, stepsClickHandler }) => {
  const steps = [
    {
      title: step.text[0],
      icon: step.icon[0],
      disabled: step.disabled[0]
    },
    {
      title: step.text[1],
      icon: step.icon[1],
      disabled: step.disabled[1],
      subTitle: step.subTitle[1]
    },
    {
      title: step.text[2],
      icon: step.icon[2],
      disabled: step.disabled[2]
    },
  ]
  return (
    <>
      <Row justify="center" align="middle" style={{ margin: '24px 0' }}>
      <Col span={15} >
        <Steps
          size="small"
          current={currentStep}
          onChange={stepsClickHandler}
          className={classes.steps}
          items={steps}
        />
      </Col>
      </Row>
    </>
  )
};