import { Buffer } from 'buffer';
import { ASPLS } from './baseline_aspls';
const dayRegexp = /([A-z]+)\s(\d+)/g;

export function indexOfMax(arr) {
  if (arr.length === 0) {
    return -1;
  }

  var max = arr[0];
  var maxIndex = 0;

  for (var i = 1; i < arr.length; i++) {
    if (arr[i] > max) {
      maxIndex = i;
      max = arr[i];
    }
  }

  return maxIndex;
}

export const normalizeSpectra = (spectra) => {
  let normalizedSpectra = [];
  for (let i = 0; i < spectra.length; i++) {
    normalizedSpectra[i] = spectra[i].map(s => {
      return Math.round((s / Math.sqrt(spectra[i].map(x => Math.pow(x, 2)).reduce((a, b) => a + b, 0))) * 65535);
    });
  }
  return normalizedSpectra;
}

export const removeBadPixels = (rawSpectrum, badPixels) => {
  let spectrum = [...rawSpectrum];
  for (let i = 0; i < badPixels.length; i++) {
    if (badPixels[i] >= spectrum.length) {
      continue;
    }
    if (badPixels[i] === 0) {
      spectrum[0] = spectrum[1]
    } else if (badPixels[i] === spectrum.length - 1) {
      spectrum[spectrum.length - 1] = spectrum[spectrum.length - 2]
    } else {
      spectrum[badPixels[i]] = Math.round((spectrum[badPixels[i] - 1] + spectrum[badPixels[i] + 1]) / 2)
    }
  }

  return spectrum;
}

export const calibrateRamanIntensity = (spectrum, coeffs) => {
    let factors = [...Array(spectrum.length).keys()].map(pixel => {
        return Math.pow(10, coeffs.reduce((prev, curr, i) => prev + (curr * Math.pow(pixel, i))));
    });
    return spectrum.map((p, i) => p * factors[i]);
}

export const correctBaseline = (spectra) => {
    let correctedSpectra = [];
    for (let i = 0; i < spectra.length; i++) {
        let baseline = ASPLS({ data: spectra[i] });
        correctedSpectra[i] = [];
        for (let j = 0; j < spectra[i].length; j++) {
            correctedSpectra[i][j] = Math.round(spectra[i][j] - baseline[j]);
            if (correctedSpectra[i][j] < 0) {
                correctedSpectra[i][j] = 0;
            }
        }
    }
    return correctedSpectra;
}

export const addWavenumbers = (wavenumbers, spectra) => {
    let wavenumberCorrected = [];
    for (let i = 0; i < spectra.length; i++) {
        wavenumberCorrected[i] = [];
        for (let j = 0; j < spectra[i].length; j++) {
            wavenumberCorrected[i][j] = [wavenumbers[j], spectra[i][j]];
        }
    }
    return wavenumberCorrected;
}

export const mean = (array) => array.reduce((a, b) => a + b) / array.length;

export const getWeek = () => {
  let week = new Date();
  week.setDate(week.getDate() - week.getDay());
  return (week.toString().match(/([A-z]+)\s(\d+)\s(\d+)/g)[0]);
}

export const getFormattedWeekString = (week) => {
  week = week ? new Date(week) : new Date();
  let firstDay = week.toString().match(dayRegexp)[0];
  week.setDate(week.getDate() + 7);
  let lastDay = week.toString().match(dayRegexp)[0];

  return `${firstDay} - ${lastDay}`
}

export const setClipboard = async (ClipboardItem, map, index, wavenumbers) => {
    const type = 'text/plain';
    const blob = new Blob([`${wavenumbers}\n${map[index].spectrum.processed}`], { type });

    const cbi = new ClipboardItem({
        [type]: blob
    });
    await navigator.clipboard.write([cbi]);
}

export const interpolate = (standardWavenumbers, deviceWavenumbers, intensityValues) => {
  if (standardWavenumbers[0] < deviceWavenumbers[0] || standardWavenumbers.slice(-1)[0] > deviceWavenumbers.slice(-1)[0]) {
    console.error(`Interpolation out of range.`);
    return;
  }
  let interpolatedSpectrum = [];
  let deviceWavenumberIndex = 0
  for (let i = 0; i < standardWavenumbers.length; i++) {
    while (deviceWavenumbers[deviceWavenumberIndex] < standardWavenumbers[i]) {
      deviceWavenumberIndex++;
    }
    interpolatedSpectrum.push(linearInterpolation(standardWavenumbers[i], deviceWavenumbers[deviceWavenumberIndex - 1], intensityValues[deviceWavenumberIndex - 1], deviceWavenumbers[deviceWavenumberIndex], intensityValues[deviceWavenumberIndex]));
  }
  return interpolatedSpectrum;
}

function linearInterpolation(x, x0, y0, x1, y1) {
  return y0 + (y1 - y0) * ((x - x0) / (x1 - x0));
}

export const snvNormalize = (spectrum) => {
  const mean = spectrum.reduce((a, b) => a + b) / spectrum.length;
  const std = Math.sqrt(spectrum.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / (spectrum.length - 1));
  const snvNormalizedSpectrum = spectrum.map(x => (x - mean) / std);
  const minValue = Math.min(...snvNormalizedSpectrum);
  const normalizedSpetrum = snvNormalizedSpectrum.map(value => value - minValue);
  return normalizedSpetrum;
}

export function arrayBufferToBufferCycle(ab) {
  var buffer = new Buffer(ab.byteLength);
  var view = new Uint8Array(ab);
  for (var i = 0; i < buffer.length; ++i) {
    buffer[i] = view[i];
  }
  return buffer;
}

export function isUUID(str) {
  const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return uuidRegex.test(str);
}

export function hasKey(obj) {
  return typeof obj === 'object' && obj !== null && Object.keys(obj).length > 0; // add check to see if first key is not empty string?
}

export const chunkArrayIntoN = (arr, n) => {
  let baseChunkSize = Math.floor(arr.length / n);
  let numChuncksToIncrement = arr.length % n;
  let startIndex = 0;
  let chunkedArray = [];
  for (let i = 0; i < n; i++) {
    chunkedArray[i] = arr.slice(startIndex, startIndex + baseChunkSize + (i < numChuncksToIncrement ? 1 : 0));
    startIndex += baseChunkSize + (i < numChuncksToIncrement ? 1 : 0);
  }
  return chunkedArray;
}

export const sumValuesByKeyMatch = (obj, stringArray) => {
  if (!obj) return null;
  let matchingKeys = Object.keys(obj).filter(key => stringArray.some(str => key === str));
  if (matchingKeys.length === 0) return -1;
  let sum = matchingKeys.reduce((sum, key) => sum + obj[key], 0);
  return sum ? sum : 0;
}