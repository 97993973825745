import React, { useState } from 'react';
import { useAtom } from 'jotai';
import {
  InputNumber, Row, Col, message, Space
} from 'antd';
import {
  minLaserPowerState,
  maxLaserPowerState,
  minIntegrationTimeState,
  maxIntegrationTimeState,
  centerState
} from '../stateManagement/controlButtonState';
import { mapState } from '../stateManagement/commonState';
import { scanningState } from '../stateManagement/processState';

export const SettingsButtons = () => {
  const [laserPowerError, setLaserPowerError] = useState('');
  const [integrationTimeError, setIntegrationTimeError] = useState('');
  const [minLaserPower, setMinLaserPower] = useAtom(minLaserPowerState);
  const [maxLaserPower, setMaxLaserPower] = useAtom(maxLaserPowerState);
  const [scanning] = useAtom(scanningState);
  const [minIntegrationTime, setMinIntegrationTime] = useAtom(minIntegrationTimeState);
  const [maxIntegrationTime, setMaxIntegrationTime] = useAtom(maxIntegrationTimeState);
  const [map] = useAtom(mapState);
  const [center, setCenter] = useAtom(centerState);

  const laserPowerChangeHandler = (value, type) => {
    type === 'min' ? setMinLaserPower(value) : setMaxLaserPower(value);
    validateLaserInput(value, type);
  }

  const validateLaserInput = (value, type) => {
    let error = '';
    if (isNaN(value)) {
      error = 'Please input a number';
    } else if (value < 1 || value > 450) {
      error = 'Value must be between 1 and 450';
    } else if (type === 'min' && value > maxLaserPower) {
      error = 'Min power must be less than max power';
    } else if (type === 'max' && value < minLaserPower) {
      error = 'Max power must be greater than min power';
    }
    setLaserPowerError(error);
  }

  const validateIntegrationTimeInput = (value, type) => {
    let error = '';
    if (isNaN(value)) {
      error = 'Please input a number';
    } else if (value < 1 || value > 60000) {
      error = 'Value must be between 1 and 60000';
    } else if (type === 'min' && value > maxIntegrationTime) {
      error = 'Min integration time must be less than max integration time';
    } else if (type === 'max' && value < minIntegrationTime) {
      error = 'Max integration time must be greater than min integration time';
    }
    setIntegrationTimeError(error);
  }

  const integrationTimeChangeHandler = (value, type) => {
    type === 'min' ? setMinIntegrationTime(value) : setMaxIntegrationTime(value);
    validateIntegrationTimeInput(value, type);
  }

  const centerCoordinateChangeHandler = (value, axis) => {
    value = Number(value);
    setCenter(prevCenter => {
      let newCenter = { ...prevCenter };
      newCenter[axis] = value;
      message.success({ content: `Center coordinate updated. (${newCenter.x}, ${newCenter.y}, ${newCenter.z})`, duration: 3 });
      return newCenter
    });
  }

  return (
    <>
      <Row justify="center" align="middle" gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ padding: '10px 0' }}>
        <Col span={7} className="gutter-row">
          <Row justify="center">Laser Power</Row>
          {laserPowerError && <Row justify="center" style={{ color: 'red' }}>{laserPowerError}</Row>}
          <Space.Compact direction={'vertical'} block>
            <InputNumber
              value={minLaserPower}
              addonBefore='min'
              addonAfter='mW'
              onChange={value => laserPowerChangeHandler(Number(value), 'min')}
              controls={false}
              style={{ width: '100%' }}
              disabled={scanning || map.length}
            />
            <InputNumber
              value={maxLaserPower}
              addonBefore='max'
              addonAfter='mW'
              onChange={value => laserPowerChangeHandler(Number(value), 'max')}
              controls={false}
              style={{ width: '100%' }}
              disabled={scanning || map.length}
            />
          </Space.Compact>
        </Col>
        <Col span={7} className="gutter-row">
          <Row justify="center">Integration Time</Row>
          {integrationTimeError && <Row justify="center" style={{ color: 'red' }}>{integrationTimeError}</Row>}
          <Space.Compact direction={'vertical'} block>
            <InputNumber
              value={minIntegrationTime}
              addonBefore='min'
              addonAfter='ms'
              onChange={value => integrationTimeChangeHandler(Number(value), 'min')}
              style={{ width: '100%' }}
              disabled={scanning || map.length}
            />
            <InputNumber
              value={maxIntegrationTime}
              addonBefore='max'
              addonAfter='ms'
              onChange={value => integrationTimeChangeHandler(Number(value), 'max')}
              style={{ width: '100%' }}
              disabled={scanning || map.length}
            />
          </Space.Compact>
        </Col>
        <Col span={7} className="gutter-row">
          <Row justify="center">Center Coordinate</Row>
          <Space.Compact direction={'vertical'} block>
            <InputNumber
              value={center.x}
              min={0}
              max={25}
              addonBefore='x'
              addonAfter='mm'
              onChange={value => centerCoordinateChangeHandler(value, 'x')}
              style={{ width: '100%' }}
              disabled={scanning || map.length}
            />
            <InputNumber
              value={center.y}
              min={0}
              max={30}
              addonBefore='y'
              addonAfter='mm'
              onChange={value => centerCoordinateChangeHandler(value, 'y')}
              style={{ width: '100%' }}
              disabled={scanning || map.length}
            />
            <InputNumber
              value={center.z}
              min={0}
              max={20}
              addonBefore='z'
              addonAfter='mm'
              onChange={value => centerCoordinateChangeHandler(value, 'z')}
              style={{ width: '100%' }}
              disabled={scanning || map.length}
            />
          </Space.Compact>
        </Col>
      </Row>
    </>
  );
};
