/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ffc375e9-e9b9-4301-9bf6-f416064ccf70",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ORW34NEj9",
    "aws_user_pools_web_client_id": "1otii5nrd5141lrecng86pmtou",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://nbvmguvkkrft3h2c27lxba25qm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-4pg6erwd45c77ftiocik43u7fe"
};


export default awsmobile;