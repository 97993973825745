import React, { useEffect } from 'react';
import { notification } from 'antd';

const onlineConfig = {
    message: 'You are online!',
    description: 'You can operate normally! Previous scan is being uploaded to the database.',
    placement: 'topLeft',
    duration: 5
};

const offlineConfiguration = {
    message: 'You are offline!',
    description: 'You are currently offline. You can still scan but the result will be uploaded only when you connect. Do not close the tab!',
    placement: 'topLeft',
    duration: 0
}

export const OnlineOfflineIndicator = () => {
    useEffect(() => {
        window.addEventListener('online', () => {
            notification.open(onlineConfig);
        });

        window.addEventListener('offline', () => {
            notification.warning(offlineConfiguration);
        });
    }, []);

    return <></>
}