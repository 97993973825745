import React, { useState, useRef, useEffect } from 'react';
import { Modal, Row, Col, Avatar, List, Input, Button, Form, Tooltip, Popconfirm, Alert, Empty, Spin, message } from 'antd';
import { useAtom } from "jotai";
import { isOperatorModalOpenState, userNameState, operatorsState, operatorsMapState, currentOperatorState } from '../stateManagement/commonState';
import { fetchOperators, addOperator, updateOperator, deleteOperator } from '../utils/dbHelpers';
import { EditOutlined } from '@ant-design/icons';

const OperatorModal = ({ stepStartScan }) => {

    const [username] = useAtom(userNameState);
    const [isOperatorModalOpen, setIsOperatorModalOpen] = useAtom(isOperatorModalOpenState);
    const [operatorSearchTerm, setOperatorSearchTerm] = useState('');
    const [isAddingOperator, setIsAddingOperator] = useState(false);
    const [isEditingOperator, setIsEditingOperator] = useState(false);
    const operatorSearchInput = useRef(null);
    const [operators, setOperators] = useAtom(operatorsState);
    const [operatorsMap, setOperatorsMap] = useAtom(operatorsMapState);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [fetchingOperators, setFetchingOperators] = useState(false);
    const [currentOperator, setCurrentOperator] = useAtom(currentOperatorState);

    const [form] = Form.useForm();
    const firstNameValue = Form.useWatch('first_name', form);
    const lastNameValue = Form.useWatch('last_name', form);
    const [colorValue, setColorValue] = useState('#8c8c8c');


    useEffect(() => {
        if ((username && !isAddingOperator && !isEditingOperator && isOperatorModalOpen) || (username && isOperatorModalOpen && operators.length === 0)) {
            loadOperators();
        }
    }, [username, isAddingOperator, isEditingOperator, isOperatorModalOpen]);

    const loadOperators = async () => {
        setFetchingOperators(true);
        const res = await fetchOperators(username);
        if (res.success) {
            setOperators(res.data);
            setOperatorsMap((res.data || []).reduce((acc, operator) => {
                acc[operator.operator_id] = operator;
                return acc;
            }, {}));
            setFetchingOperators(false);
        } else {
            setErrorMessage(res.message);
            setShowErrorMessage(true);
            setFetchingOperators(false);
        }
    };

    const handleSearch = (e) => {
        setOperatorSearchTerm(e.target.value);
    };

    const filteredData = operators.filter((operator) =>
        `${operator.first_name} ${operator.last_name}`.toLowerCase().includes(operatorSearchTerm.toLowerCase())
    );

    const handleOperatorSelection = (operator) => {
        setCurrentOperator(operator.operator_id);
        stepStartScan();
        form.resetFields();
        setColorValue('#8c8c8c');
        setIsAddingOperator(false);
        setIsEditingOperator(false);
        setOperatorSearchTerm('');
        setErrorMessage('');
        setShowErrorMessage(false);
        setIsOperatorModalOpen(false);
    };

    const onFinish = async (values) => {
        values.color = colorValue;
        values.username = username;
        if (isAddingOperator) {
            const res = await addOperator(values);
            if (!res.success) {
                setErrorMessage(res.message);
                setShowErrorMessage(true);
                return;
            }
            message.success(res.message, 4);
        } else if (isEditingOperator) {
            const res = await updateOperator(values);
            if (!res.success) {
                setErrorMessage(res.message);
                setShowErrorMessage(true);
                return;
            }
            message.success(res.message, 4);
        }
        form.resetFields();
        setColorValue('#8c8c8c');
        setIsAddingOperator(false);
        setIsEditingOperator(false);
        setOperatorSearchTerm('');
        setErrorMessage('');
        setShowErrorMessage(false);
    };

    const handleEditButton = (operator) => {
        form.setFieldsValue(operator);
        setColorValue(operator.color);
        setIsEditingOperator(true);
    };

    const handleDeleteButton = async (operator) => {
        operator.username = username;
        const res = await deleteOperator(operator);
        if (!res.success) {
            setErrorMessage(res.message);
            setShowErrorMessage(true);
            return;
        }
        message.success(res.message, 4);
        form.resetFields();
        setColorValue('#8c8c8c');
        setIsEditingOperator(false);
    };

    const toTitleCase = (str) => {
        return str.replace(/\w\S*/g, (txt) => {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };

    return (
        <>
            <Modal
                title={isAddingOperator ? "Create your operator profile." : isEditingOperator ? "Update your operator profile below." : "Please select or create your operator profile."}
                centered
                open={isOperatorModalOpen}
                onOk={() => setIsOperatorModalOpen(false)}
                onCancel={() => {
                    setOperatorSearchTerm('');
                    setErrorMessage('');
                    setShowErrorMessage(false);
                    if (isAddingOperator) {
                        setIsAddingOperator(false);
                        form.resetFields();
                        setColorValue('#8c8c8c');
                    } else if (isEditingOperator) {
                        setIsEditingOperator(false);
                        form.resetFields();
                        setColorValue('#8c8c8c');
                    } else {
                        setIsOperatorModalOpen(false);
                    }
                }}
                footer={null}
                afterOpenChange={_ => {
                    if (isOperatorModalOpen && operatorSearchInput.current) {
                        operatorSearchInput.current.focus({ cursor: 'end' });
                    }
                }}
            >
                <Row style={{ maxHeight: '400px' }}>
                    {(isAddingOperator || isEditingOperator) ?
                        <Col span={24}>
                            <Form
                                form={form}
                                onFinish={onFinish}
                                style={{ width: '100%', margin: '24px 0 0 0' }}
                            >
                                <Form.Item name="operator_id" hidden />
                                <Form.Item
                                    name="first_name"
                                    rules={[
                                        {
                                            required: true,
                                            min: 2,
                                            message: 'Please enter your first name.',
                                        },
                                    ]}
                                >
                                    <Input placeholder="First Name" onChange={e => form.setFieldValue('first_name', toTitleCase(e.target.value))} />
                                </Form.Item>
                                <Form.Item
                                    name="last_name"
                                    rules={[
                                        {
                                            required: true,
                                            min: 1,
                                            message: 'Please enter your last name.',
                                        },
                                    ]}
                                >
                                    <Input placeholder="Last Name" onChange={e => form.setFieldValue('last_name', toTitleCase(e.target.value))} />
                                </Form.Item>
                                <Form.Item name="color" style={{ textAlign: 'center' }}>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <Button type="primary" size="small" shape="circle" style={{ backgroundColor: '#8c8c8c', margin: '0 4px' }} onClick={_ => setColorValue('#8c8c8c')} />
                                        <Button type="primary" size="small" shape="circle" style={{ backgroundColor: '#597ef7', margin: '0 4px' }} onClick={_ => setColorValue('#597ef7')} />
                                        <Button type="primary" size="small" shape="circle" style={{ backgroundColor: '#9254de', margin: '0 4px' }} onClick={_ => setColorValue('#9254de')} />
                                        <Button type="primary" size="small" shape="circle" style={{ backgroundColor: '#f759ab', margin: '0 4px' }} onClick={_ => setColorValue('#f759ab')} />
                                        <Button type="primary" size="small" shape="circle" style={{ backgroundColor: '#ff4d4f', margin: '0 4px' }} onClick={_ => setColorValue('#ff4d4f')} />
                                        <Button type="primary" size="small" shape="circle" style={{ backgroundColor: '#ff7a45', margin: '0 4px' }} onClick={_ => setColorValue('#ff7a45')} />
                                        <Button type="primary" size="small" shape="circle" style={{ backgroundColor: '#73d13d', margin: '0 4px' }} onClick={_ => setColorValue('#73d13d')} />
                                    </div>
                                </Form.Item>
                                <Button
                                    style={{ width: '100%', height: '64px', padding: '4px 16px', marginBottom: '24px', display: 'flex', alignItems: 'center', flexDirection: 'row', transitionDuration: '0s' }}
                                    icon={<Avatar style={{ backgroundColor: colorValue, color: '#fff' }}>{firstNameValue?.[0]}{lastNameValue?.[0]}</Avatar>}
                                >
                                    <p style={{ margin: '0px' }}>{firstNameValue} {lastNameValue}</p>
                                </Button>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" style={{ width: '100%' }}>{isAddingOperator ? 'Add' : 'Update'}</Button>
                                </Form.Item>
                            </Form>
                            {isEditingOperator &&
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                    <Popconfirm
                                        placement="top"
                                        title={'Are you sure you want to delete this profile?'}
                                        okText="Yes"
                                        cancelText="No"
                                        onConfirm={_ => handleDeleteButton(form.getFieldsValue())}
                                    >
                                        <Button type="link" danger>Delete Profile</Button>
                                    </Popconfirm>
                                </div>
                            }
                        </Col>
                        : <Col span={24} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                            <Input ref={operatorSearchInput} style={{ marginBottom: '4px' }} placeholder="Search by name" value={operatorSearchTerm} onChange={handleSearch} allowClear />
                            {fetchingOperators &&
                                <div style={{ width: '100%', height: '328px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Spin />
                                </div>
                            }
                            {(Boolean(operators.length) && !fetchingOperators) &&
                                <List
                                    style={{ width: '100%', height: '328px', overflow: 'auto' }}
                                    split={false}
                                    dataSource={filteredData}
                                    renderItem={(operator, index) => (
                                        <List.Item style={{ position: 'relative', padding: `${operators.length > 4 ? '4px 4px 4px 0' : '4px 0'}` }}>
                                            <Button
                                                style={{ width: '100%', height: '64px', padding: '4px 16px', display: 'flex', alignItems: 'center', flexDirection: 'row', transitionDuration: '0s' }}
                                                icon={<Avatar style={{ backgroundColor: `${operator.color}`, color: '#fff' }}>{operator.first_name[0]}{operator.last_name[0]}</Avatar>}
                                                onClick={_ => handleOperatorSelection(operator)}
                                            >
                                                <p style={{ margin: '0px' }}>{operator.first_name} {operator.last_name}</p>
                                            </Button>
                                            <div style={{ position: 'absolute', right: '16px' }}>
                                                <Tooltip title="Edit Profile">
                                                    <Button type="dashed" shape="circle" onClick={_ => handleEditButton(operator)} icon={<EditOutlined />} />
                                                </Tooltip>
                                            </div>
                                        </List.Item>
                                    )}
                                />}
                            {(!Boolean(operators.length) && !fetchingOperators) &&
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={"No Operators"} style={{ height: '328px', margin: '0', display: 'flex', flexDirection: 'column', justifyContent: 'center' }} />
                            }
                            <Button type="link" style={{ margin: '12px 0 24px 0' }} onClick={_ => setIsAddingOperator(true)}>+ Add Operator</Button>
                        </Col>
                    }
                </Row>
                {showErrorMessage && <Alert style={{ marginTop: '24px' }} message={errorMessage} type="error" showIcon closable afterClose={_ => setShowErrorMessage(false)} />}
            </Modal>
        </>
    );
};
export default OperatorModal;