import React, { useEffect } from 'react';
import {
  Table,
  Progress
} from 'antd';

const ResultsTable = ({ resultsTableData }) => {

  const resultTableColumns = [
    {
      title: 'Substance',
      dataIndex: 'substance',
      key: 'substance',
      width: '50%',
    },
    {
      title: 'Percent Composition',
      dataIndex: 'percentComposition',
      key: 'percentComposition',
      width: '50%',
      sorter: (a, b) => a.percentComposition - b.percentComposition,
      sortOrder: true,
      render: (percentComposition, results) => (
        <>
          <Progress strokeColor={'#91caff'} percent={percentComposition} success={{ percent: (percentComposition - results.tracePercentComposition), strokeColor: '#1677ff' }} style={{ width: '95%' }} status="normal" />
        </>
      ),
    }
  ];

  useEffect(() => {
    console.log('ResultsTable.js - resultsTableData:', resultsTableData);
  }, [resultsTableData]);


  return (
    <Table
      dataSource={resultsTableData}
      columns={resultTableColumns}
      // className={classes.resultsTable}
      pagination={{ position: ['bottomCenter'], pageSize: 5 }}
      style={{ width: `${21 / 24 * 100}%`, margin: '10px auto' }}
    />
  )
}

export default ResultsTable;