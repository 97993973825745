const getExpandingSquareSearchPointsForOneLayer = (center, scanWidth, pointsPerSide) => {
    let numPoints = Math.pow(pointsPerSide, 2);
    let points = [];
    let [x, y, z] = [center.x, center.y, center.z];
    let stepSize = scanWidth / (pointsPerSide - 1);
    let axis = 0;
    let direction = 1;
    if (pointsPerSide % 2 === 0) {
        x -= stepSize / 2
        y -= stepSize / 2
    }
    points.push([x, y, z])
    for (let i = 2; i < 2000; i += 2) {
        for (let j = 0; j < i; j++) {
            if (j % (i / 2) === 0) {
                axis = axis === 1 ? 0 : 1;
            }
            if (axis === 0) {
                x = x + (direction * stepSize);
            } else {
                y = y + (direction * stepSize);
            }
            points.push([x, y, z]);
            if (points.length === numPoints) {
                return points;
            };
        }
        if (points.length === numPoints) { break; };
        direction = direction === 1 ? -1 : 1;
    }
}

export const getExpandingSquareSearchPoints = (centerCoordiante, scanWidth, scanHeight, pointsPerSide, numLevels, singlePointMultiplier) => {
    let center = { ...centerCoordiante };
    let points = [];
    let levels = [];
    if (numLevels === 1 || scanHeight === 0) { levels.push(center.z) }
    else {
        for (let i = 0; i < numLevels; i++) {
            levels.push(center.z - (scanHeight / 2) + (scanHeight / (numLevels - 1)) * i);
        }
    }
    if (pointsPerSide === 1 && numLevels === 1) {
        return new Array(singlePointMultiplier).fill(null).map(() => [center.x, center.y, center.z]);
    } else if (pointsPerSide === 1 || scanWidth === 0) {
        return levels.map(z => [center.x, center.y, z])
    }
    for (let i = 0; i < levels.length; i++) {
        center.z = levels[i];
        let layerPoints = getExpandingSquareSearchPointsForOneLayer(center, scanWidth, pointsPerSide);
        if (i % 2 === 1) {
            points.push(...layerPoints.reverse());
        } else {
            points.push(...layerPoints);
        }
    }
    return points.map(e => e.map(e => Math.round(e * 1000) / 1000));
}