import React, { useEffect, useState } from "react";
import { Row, Col, Table } from 'antd';
import { useAtom } from 'jotai';
import { massSpectrometryTableDataState } from "../../stateManagement/commonState";
import { hasKey } from "../../utils/helpers";
function PipelineSummaryTable() {

    let [pipelineSummaryTableData, setPipelineSummaryTableData] = useState([]);
    const [massSpectrometryTableData] = useAtom(massSpectrometryTableDataState);

    useEffect(() => {
        let pipelineSummaryTableData = massSpectrometryTableData.reduce((accumulator, current) => {
            let username = current.username;
            if (!accumulator[username]) {
                accumulator[username] = {
                    key: username,
                    username: username,
                    pendingTransit: 0,
                    inProgress: 0,
                    analyzed: 0,
                    exception: 0,
                };
            }
            
            if (hasKey(accumulator, username)) {
                accumulator[username].pendingTransit += current.status === "Pending Transit" ? 1 : 0;
                accumulator[username].inProgress += current.status === "In Progress" ? 1 : 0;
                accumulator[username].analyzed += current.status === "Analyzed" ? 1 : 0;
                accumulator[username].exception += current.status === "Exception" ? 1 : 0;
            } else {
                accumulator[username] = {
                    username: username,
                    pendingTransit: current.status === "Pending Transit" ? 1 : 0,
                    inProgress: current.status === "In Progress" ? 1 : 0,
                    analyzed: current.status === "Analyzed" ? 1 : 0,
                    exception: current.status === "Exception" ? 1 : 0,
                }
            }
            return accumulator;
        }, {});
        pipelineSummaryTableData = Object.values(pipelineSummaryTableData);
        setPipelineSummaryTableData(pipelineSummaryTableData);
    }, [massSpectrometryTableData]);

    let pipelineSummaryTableColumns = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            align: 'center',
            width: 180
        },
        {
            title: 'Number of Samples by Status',
            children: [
                {
                    title: 'Pending Transit',
                    dataIndex: 'pendingTransit',
                    key: 'pendingTransit',
                    align: 'center',
                    width: 100,
                    sorter: (a, b) => b.pendingTransit - a.pendingTransit,
                    defaultSortOrder: 'ascend',
                },
                {
                    title: 'In Progress',
                    dataIndex: 'inProgress',
                    key: 'inProgress',
                    align: 'center',
                    width: 100,
                    sorter: (a, b) => b.inProgress - a.inProgress,
                },
                {
                    title: 'Analyzed',
                    dataIndex: 'analyzed',
                    key: 'analyzed',
                    align: 'center',
                    width: 100,
                    sorter: (a, b) => b.analyzed - a.analyzed,
                },
                {
                    title: 'Exception',
                    dataIndex: 'exception',
                    key: 'exception',
                    align: 'center',
                    width: 100,
                    sorter: (a, b) => b.exception - a.exception,
                },
            ]
        }
    ];

    return (
        <>
            <Row justify="center" align="middle" style={{ marginTop: "6px" }}>
                <Col span={24} style={{ textAlign: "center" }} >
                    <Table
                        dataSource={pipelineSummaryTableData}
                        columns={pipelineSummaryTableColumns}
                        scroll={{ x: '480px' }}
                        sticky
                        pagination={{
                            position: ['bottomCenter'],
                            pageSize: 12,
                            showSizeChanger: false,
                        }}
                    >
                    </Table>
                </Col>
            </Row>
        </>
    )
}

export default PipelineSummaryTable;