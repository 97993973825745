import {
  EditableProTable,
} from '@ant-design/pro-components';
import { Input, InputNumber, ConfigProvider, AutoComplete } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import classes from './MassSpectrometry.module.css';
import { useAtom } from 'jotai';
import { Progress } from 'antd';
import { isAdminState, massSpectrometryTableDataState } from '../../stateManagement/commonState';
import enUS from 'antd/lib/locale/en_US';
import cloneDeep from 'lodash/cloneDeep';

let timeout;

const toTitleCase = (str) => {
  return str.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

const ResultsTable = ({ parentRecord, setResultsAreValid, results, setResults, isEditing }) => {
  const [isAdmin] = useAtom(isAdminState);
  const [editableKeys, setEditableRowKeys] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [substanceOptions, setSubstanceOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [massSpectrometryTableData] = useAtom(massSpectrometryTableDataState);
  const [pubChemOptions, setPubChemOptions] = useState([]);

  useEffect(() => {
    let newResultsTableData = results
      ? Object.entries(results).map(([key, value], index) => {
        return {
          id: (Date.now() + index).toString(),
          substance: key,
          fraction: value === null ? null : Number(value),
          percentComposition: Math.round(value * 100 * 100) / 100
        }
      })
      : [];

    newResultsTableData.sort((a, b) => b.percentComposition - a.percentComposition);

    if (isAdmin) {
      newResultsTableData.push({
        id: (Date.now() + newResultsTableData.length).toString(),
        substance: "",
        fraction: null,
        percentComposition: null
      });
    }

    setEditableRowKeys(newResultsTableData.map((item) => item.id));
    setDataSource(newResultsTableData);
  }, [parentRecord]);

  useEffect(() => {
    if (isAdmin) {
      let substanceFractionObject = dataSource.reduce((accumulator, current) => {
        if (current.substance !== "") {
          accumulator[current.substance] = (current.fraction > 0 && current.fraction <= 1) ? current.fraction : null;
        }
        return accumulator;
      }, {});

      setResults(results => {
        const oldResults = cloneDeep(results);
        return ({ ...oldResults, [parentRecord.id]: substanceFractionObject });
      });

      let indexOfEmptyRow = dataSource.findIndex((result, index, results) => result.substance === "" && index !== results.length - 1);

      if (dataSource.length > 0 && dataSource[dataSource.length - 1].substance !== "") {
        const newDataSource = [...dataSource, {
          id: (Date.now() + dataSource.length).toString(),
          substance: "",
          fraction: null,
          percentComposition: null
        }];
        setEditableRowKeys(newDataSource.map((result) => result.id));
        setDataSource(newDataSource);
      } else if (indexOfEmptyRow !== -1) {
        const newDataSource = [...dataSource];
        newDataSource.splice(indexOfEmptyRow, 1);
        setEditableRowKeys(newDataSource.map((result) => result.id));
        setDataSource(newDataSource);
      }
    }
  }, [dataSource]);

  useEffect(() => {
    let uniqueSubstances = new Set();
    massSpectrometryTableData.forEach(object => {
      if (object.results) {
        for (let key in object.results) {
          uniqueSubstances.add(key);
        }
      }
    });
    let substanceOptions = [...uniqueSubstances].map((substance) => { return { key: `previous_${substance}`, value: substance } });
    setSubstanceOptions(substanceOptions);
  }, [massSpectrometryTableData]);

  const validateSubstance = async (substance) => {
    try {
      const response = await fetch(`https://pubchem.ncbi.nlm.nih.gov/rest/autocomplete/compound/${substance}/json?limit=6`)
      const data = await response.json();
      if (data.total > 0 && data.dictionary_terms.compound[0].toLowerCase() === substance.toLowerCase()) {
        setPubChemOptions(data.dictionary_terms.compound.map((compound) => { return { key: `pubchem_${compound}`, value: toTitleCase(compound) } }))
        return true;
      } else if (data.total > 0) {
        setPubChemOptions(data.dictionary_terms.compound.map((compound) => { return { key: `pubchem_${compound}`, value: toTitleCase(compound) } }));
      } else {
        setPubChemOptions([]);
        return false;
      }
    } catch (error) {
      console.error('Error:', error);
      return false;
    }
  }

  const validateSubstanceDebounced = async (value, resolve, reject) => {
    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(async () => {
      const isValid = await validateSubstance(value);

      if (isValid) {
        resolve();
      } else {
        reject(new Error("Please enter a valid PubChem compound."));
      }
    }, 200);
  };

  const columns = [
    {
      title: 'Substance',
      dataIndex: 'substance',
      align: 'center',
      className: classes.msResultsTableFormItem,
      formItemProps: {
        className: classes.substanceFormItem,
      },
      sorter: isAdmin && isEditing ? null : (a, b) => a.substance.localeCompare(b.substance),
      sortDirections: isAdmin && isEditing ? null : ['descend', 'ascend'],
      render: (_, record) => {
        return <div>{record.substance}</div>;
      },
      renderFormItem: (e, { isEditable }) => {
        return isAdmin && isEditing ?
          <AutoComplete
            // options={substanceOptions.filter(option => option.value.toLowerCase().includes(searchText.toLowerCase()))}
            // options={
            //   substanceOptions.filter(option => option.value.toLowerCase().includes(searchText.toLowerCase())).length > 1 
            //     ? substanceOptions.filter(option => option.value.toLowerCase().includes(searchText.toLowerCase()))
            //     : pubChemOptions
            // }
            options={[
              {
                label: 'Previously Used',
                options: substanceOptions.filter(option => option.value.toLowerCase().includes(searchText.toLowerCase())),
              },
              {
                label: 'PubChem',
                options: pubChemOptions,
              },
            ]}
            style={{ width: '100%' }}
            onSearch={setSearchText}
            onBlur={() => { setSearchText(""); setPubChemOptions([]); }}
          />
          : e.entry.substance;
      },
      formItemProps: {
        rules: [
          {
            validator: (_, value) => {
              return new Promise((resolve, reject) => {
                if (!value) {
                  resolve();
                } else {
                  validateSubstanceDebounced(value, resolve, reject);
                }
              });
            },
          },
        ]
      }
    },
    ...(isAdmin && isEditing
      ? [
        {
          title: 'Fraction',
          key: 'fraction',
          dataIndex: 'fraction',
          width: '110px',
          align: 'center',
          className: classes.msResultsTableFormItem,
          formItemProps: {
            rules: [
              {
                message: 'Please enter a value between 0 and 1 or leave blank.',
                pattern: /^(1(\.0*)?|0\.\d+)$/,
              }
            ],
          },
          renderFormItem: (e, { isEditable }) => {
            return <InputNumber controls={false} style={{ width: '100%' }} disabled={e.entry.substance === ""} />;
          },
        },
      ]
      : []),
    {
      title: 'Percent Composition',
      dataIndex: 'percentComposition',
      valueType: null,
      align: 'center',
      width: isAdmin && isEditing ? '30%' : '50%',
      sorter: isAdmin && isEditing ? null : (a, b) => a.percentComposition - b.percentComposition,
      sortDirections: isAdmin && isEditing ? null : ['descend', 'ascend'],
      render: percentComposition => {
        return percentComposition ?
          <Progress percent={percentComposition} style={{ width: '95%' }} status="normal" />
          : `–`
      }
    },
  ];

  const editableFormRef = useRef();

  const validateForm = async () => {
    try {
      const values = await editableFormRef.current.validateFields();
      setResultsAreValid(true);
    } catch (err) {
      setResultsAreValid(false);
    }
  };

  return (
    <>
      <ConfigProvider locale={enUS}>
        <EditableProTable
          className={classes.msResultsTable}
          columns={columns}
          rowKey="id"
          scroll={{
            x: '100%',
          }}
          value={dataSource}
          onChange={e => { setDataSource(e) }}
          editableFormRef={editableFormRef}
          // recordCreatorProps={{
          //   newRecordType: 'dataSource',
          //   record: () => ({
          //     id: Date.now(),
          //   }),
          // }}
          recordCreatorProps={false}
          editable={{
            type: 'multiple',
            editableKeys,
            // actionRender: (row, config, defaultDoms) => {
            //   return [defaultDoms.delete];
            // },
            onValuesChange: (record, recordList) => {
              // setDataSource(recordList);
              validateForm();
              const updatedRecordList = recordList.map((item) => {
                if (item.id === record.id) {
                  return {
                    ...item,
                    percentComposition: Math.round(item.fraction * 100 * 100) / 100,
                  };
                }
                return item;
              });
              setDataSource(updatedRecordList);
            },
            onChange: setEditableRowKeys,
          }}
          pagination={{
            position: ['bottomCenter'],
            pageSize: 10,
            showSizeChanger: false,
            showTotal: false,
            style: { margin: '6px 0' }
          }}
        />
      </ConfigProvider>
    </>
  );
};

export default ResultsTable;