import { message } from 'antd';
import Cookie from 'js-cookie';
import { addSpectraToOfflineDb, deleteMapFromOffline, fetchSpectraFromOfflineDb, updateSpectraInOfflineDb } from './offlineDbHelper';

export const fetchAllScanData = async ({ username, dateRange }) => {
    try {
        let response = await fetch(`/fetchAllScanData?username=${username}&from=${dateRange.from}&to=${dateRange.to}`, {
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
        });
        let data = await response.json();
        return data;
    } catch (error) {
        console.error('Fetch all scan data error: ', error);
        return [];
    }
}

export const fetchSpectrum = async (mapID) => {
    try {
        let response = await fetch('/fetchSpectrum?' + new URLSearchParams({ mapID }), {
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
        });
        let res = await response.json();
        return res;
    } catch (error) {
        console.error('Spectrum fetch error: ', error);
    }
}

export const updateMapInfo = (record, message) => {
    if (navigator.onLine) {
        return updateMapInfoOnline(record);
    } else {
        return updateMapInfoOffline(record, message);
    }
}

export const deleteMap = (record) => {
    if (navigator.onLine) {
        return deleteMapInfoOnline(record.map_id);
    } else {
        return deleteMapFromOffline(record.scanTimestamp);
    }
}

export const fetchSubstances = async () => {
    try {
        let response = await fetch('/substances', {
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
        });
        let result = await response.json();
        // let substances = result.map(obj => obj.label);
        return result;
    } catch (error) {
        console.error('Fetch all substances error:', error);
    }
}

export const fetchReferenceSubstanceNames = async () => {
    try {
        let response = await fetch('/referenceSubstanceNames', {
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
        });
        let result = await response.json();
        return result;
    } catch (error) {
        console.error('Fetch all substances error:', error);
    }
}

export const fetchReferenceSpectrum = async (referenceSubstanceName, integrationTime) => {
    try {
        let response = await fetch('/referenceSpectrum?' + new URLSearchParams({ referenceSubstanceName, integrationTime }), {
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
        });
        let res = await response.json();
        return res;
    } catch (error) {
        console.error('Reference spectrum fetch error: ', error);
    }
}

export const fetchReferenceSpectra = async () => {
    try {
        let response = await fetch('/referenceSpectra', {
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
        });
        let res = await response.json();
        return res;
    } catch (error) {
        console.error('Reference spectra fetch error: ', error);
    }
}

export const fetchClientCodes = (username) => {
    return new Promise((resolve, reject) => {
        fetch('/clientcodes?' + new URLSearchParams({
            username: username,
        }), {
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
        })
            .then(response => response.json())
            .then(res => {
                resolve(res);
            })
            .catch((error) => {
                console.error('Fetch all client codes error:', error);
            });
    });
}

export const createRecord = (body, message, key) => {
    return new Promise(async (resolve, reject) => {
        fetch('/upload', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
            body: JSON.stringify(body),
        })
            .then(response => response.json())
            .then(data => {
                if (data.serial || data.status === 'success') {
                    message.success({ content: 'Record added.', key, duration: 5 });
                    resolve(data);
                } else {
                    message.error({ content: 'Failed to add record.', key, duration: 5 });
                    reject();
                }
                // updateMapTable();
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    })
}

export const uploadSpectra = (body, message, key, initiateTableUpdate) => {
    if (navigator.onLine) {
        return uploadSpectraOnline(body, message, key);
    } else {
        return uploadSpectraOffline(body, message, key, initiateTableUpdate);
    }
}

export const saveClientData = async (clientData) => {
    try {
        await fetch('/addOrUpdateClientData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
            body: JSON.stringify(clientData, (key, value) => (value === undefined ? null : value)),
        });
    } catch (error) {
        console.error('saveClientData error:', error);
        throw error;
    }
}

export const deleteClient = async (username, client_code) => {
    try {
        const response = await fetch('/client', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
            body: JSON.stringify({ username, client_code }),
        });
        return response.json();
    } catch (error) {
        console.error('saveClientData error:', error);
        throw error;
    }
}

export const updateDeviceFirmwareVersionInDB = async (device_id, firmware_version) => {
    try {
        await fetch('/updateDeviceFirmwareVersion', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
            body: JSON.stringify({ device_id, firmware_version }),
        });
    } catch (error) {
        console.error('updateDeviceFirmwareVersionInDB error:', error);
        throw error;
    }
}

export const updateDeviceNetworkStatusInDB = async (device_id, network_status) => {
    try {
        await fetch('/updateDeviceNetworkStatus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
            body: JSON.stringify({ device_id, network_status }),
        });
    } catch (error) {
        console.error('updateDeviceNetworkStatusInDB error:', error);
        throw error;
    }
}

export const updateDeviceCameraStatusInDB = async (device_id, camera_status) => {
    try {
        await fetch('/updateDeviceCameraStatus', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
            body: JSON.stringify({ device_id, camera_status }),
        });
    } catch (error) {
        console.error('updateDeviceCameraStatusInDB error:', error);
        throw error;
    }
}

export const uploadResultsObject = async (results, map_id, serial, traceResults) => {
    try {
        await fetch('/uploadResultsObject', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
            body: JSON.stringify({ results, map_id, serial, traceResults }),
        });
    } catch (error) {
        console.error('uploadResultsObject error:', error);
        throw error;
    }
}

const uploadSpectraOnline = async (body, message, key) => {
    try {
        const response = await fetch('/upload', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
            body: JSON.stringify(body)
        });
        const data = response.json();
        message.success({ content: 'Saved!', key, duration: 3 });

        return data;
    } catch (error) {
        message.error({ content: 'Upload failed!', key, duration: 5 });
    }
}

const uploadSpectraOffline = async (body, message, key, initiateTableUpdate) => {
    try {
        await addSpectraToOfflineDb(body);
        window.addEventListener('online', async () => {
            try {
                let offlineSpectraArray = await fetchSpectraFromOfflineDb();
                let promises = offlineSpectraArray.map(offlineSpectra => uploadSpectraOnline(offlineSpectra, message, key))
                const mapIds = await Promise.all(promises);
                initiateTableUpdate({ mapID: mapIds[mapIds.length - 1] });
            } catch (error) {
                console.error('error while saving offline data: ', error);
                throw error;
            }
        });
        return { offline: true, mapID: body.scanTimestamp }
    } catch (error) {
        console.error('error in offline uploader: ', error);
    }
}

export const addDeviceToDatabase = async (body, message, key) => {
    try {
        let response = await fetch('/addDevice', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
            body: JSON.stringify(body),
        });
        let data = await response.json();

        if (data.status === 'success') {
            message.success({ content: 'Device registered!', key, duration: 3 });
            return data;
        }
    } catch (error) {
        message.error({ content: 'Device registration failed.', key, duration: 5 });
        console.error('Error:', error);
    }
}

export const fetchDeviceInfo = async (device_id) => {
    try {
        let result = await fetch(`/fetchDeviceInfo?device_id=${device_id || -1}`, {
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
        });
        let parsedResult = await result.json();
        return parsedResult
    } catch (error) {
        console.error('Device ID not found in databse: ', error);
        return {
            "device_id": device_id,
            "spectrometer_serial": "",
            "wavelength_calibration": [],
            "active_pixels": null,
            "excitation_wavelength": null,
            "bad_pixels": [],
            "intensity_calibration": [],
            "device_serial": "",
            "firmware_version": "",
            "network_status": 0,
            "center_calibration": {}
        }
    }
}

export const fetchDevices = async (deviceIDs) => {
    try {
        let result = await fetch(`/fetchDevices?deviceIds=${deviceIDs.toString()}`, {
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
        });
        if (!result.ok) {
            throw new Error(`fetchDevices HTTP error! status: ${result.status}`);
        }
        let parsedResult = await result.json();
        return parsedResult;
    } catch (error) {
        console.error('fetchDevices error: ', error);
    }
}

export const uploadAverageSpectrum = async (averageSpectrumInfo) => {
    try {
        await fetch('/uploadAverageSpectrum', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
            body: JSON.stringify(averageSpectrumInfo),
        });
    } catch (error) {
        console.error('uploadAverageSpectrum error: ', error);
    }
}

const updateMapInfoOnline = async (record) => {
    try {
        let response = await fetch('/updateMapInfo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
            body: JSON.stringify(record),
        });
        let data = await response.json();

        return data;
    } catch (error) {
        console.error('updateMapInfo error:', error);
        throw error;
    }
}

const updateMapInfoOffline = async (record, message) => {
    try {
        await updateSpectraInOfflineDb(record);
    } catch (error) {
        throw error;
    }
}

const deleteMapInfoOnline = async (mapID) => {
    try {
        await fetch('/map?' + new URLSearchParams({ mapID }), {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
        });
    } catch (error) {
        console.error('Map delete error:', error);
    }
}

export const fetchAllMassSpecData = async (massSpecdDateRange) => {
    try {
        let response = await fetch('/allMassSpecData?' + new URLSearchParams({ ...massSpecdDateRange }), {
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
        });
        let res = await response.json();
        return res;
    } catch (error) {
        console.error('fetchAllMassSpecData error:', error);
        throw error;
    }
}

export const fetchMassSpecDataByUser = async (massSpecdDateRange, username) => {
    try {
        let response = await fetch('/massSpecDataByUser?' + new URLSearchParams({ ...massSpecdDateRange, username }), {
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
        });
        let res = await response.json();
        return res;
    } catch (error) {
        console.error('fetchMassSpecDataByUser error:', error);
        throw error;
    }
}

export const addMassSpecRow = async (username) => {
    try {
        const response = await fetch('/addMassSpecRow', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
            body: JSON.stringify({ username }),
        });

        if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('addMassSpecRow error:', error);
        throw error;
    }
}

export const deleteMassSpecRow = async (id) => {
    try {
        const response = await fetch('/deleteMassSpecRow', {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
            body: JSON.stringify({ id }),
        });

        if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('deleteMassSpecRow error:', error);
        throw error;
    }
}

export const saveMassSpecRow = async (massSpecRowData) => {
    try {
        const response = await fetch('/updateMassSpecRow', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
            body: JSON.stringify({ ...massSpecRowData }),
        });

        if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('addMassSpecRow error:', error);
        throw error;
    }
}

export const searchMassSpecData = async (searchString) => {
    try {
        const response = await fetch('/searchMassSpecData', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
            body: JSON.stringify({ searchString }),
        });

        if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('searchMassSpecData error:', error);
        throw error;
    }
}

export const saveSubstanceInfo = async (substanceData) => {
    try {
        const response = await fetch('/updateSubstanceInfo', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
            body: JSON.stringify({ ...substanceData }),
        });

        if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }

        message.success({ content: 'Saved!', duration: 3 });

    } catch (error) {
        console.error('saveSubstanceInfo error:', error);
        throw error;
    }
}

export const getExpectedSubstances = async () => {
    try {
        let response = await fetch(`/getExpectedSubstances`, {
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            },
        });
        let data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching expected substance list: ', error);
        throw error;
    }
}

export const getMapAndLabData = async (dateRange) => {
    try {
        const response = await fetch(`/getMapAndLabData?from=${dateRange.from}&to=${dateRange.to}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'accesstoken': Cookie.get('accessToken'),
            }
        });

        if (!response.ok) { throw new Error(`HTTP error! status: ${response.status}`); }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error('getMapAndLabData error:', error);
        throw error;
    }
}

export const fetchOperators = (username) => {
    return fetch('/operators?' + new URLSearchParams({
        username: username,
    }), {
        headers: {
            'Content-Type': 'application/json',
            'accesstoken': Cookie.get('accessToken'),
        },
    })
        .then(response => response.json())
        .catch(_ => ({ success: false, message: 'Failed to fetch operators.' }));
}

export const addOperator = async (operatorData) => {
    return fetch('/addOperator', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accesstoken': Cookie.get('accessToken'),
        },
        body: JSON.stringify(operatorData, (key, value) => (value === undefined ? null : value)),
    })
        .then(res => res.json())
        .catch(_ => ({ success: false, message: 'Failed to add operator.' }));
}

export const updateOperator = async (operatorData) => {
    return fetch('/updateOperator', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accesstoken': Cookie.get('accessToken'),
        },
        body: JSON.stringify(operatorData, (key, value) => (value === undefined ? null : value)),
    })
        .then(res => res.json())
        .catch(_ => ({ success: false, message: 'Update failed.' }));
};

export const deleteOperator = async (operatorData) => {
    return fetch('/deleteOperator', {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'accesstoken': Cookie.get('accessToken'),
        },
        body: JSON.stringify(operatorData, (key, value) => (value === undefined ? null : value)),
    })
        .then(res => res.json())
        .catch(_ => ({ success: false, message: 'Failed to delete operator profile.' }));
}