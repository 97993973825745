import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useAtom } from 'jotai';
import 'echarts-gl';
import ReactEcharts from 'echarts-for-react';
import { cloneDeep } from 'lodash';
import { getExpandingSquareSearchPoints } from '../utils/getExpandingSquareSearch';
import { getMappingGridConfig } from './configs/mappingGridConfig';
import {
    applyCalibrationOffset,
    generateCalibrationCoordinates,
    groupVerticals,
    interpolateVerticals,
    generateSectionVertexIndices,
    computeSectionAveragePoints
} from '../utils/calibrationHelpers';
import {
    numCalibrationPointsPerSideState,
    numVerticalCalibrationPointsState,
    centerState,
    defaultCenter,
} from '../stateManagement/controlButtonState';
import { colorsState } from '../stateManagement/graphState';
import {
    scanWidthState,
    scanHeightState,
    calibrationState,
    calibratingState,
    pointsPerSideState,
    numLevelsState,
    singlePointMultiplierState,
    currentPathState,
    calibrationInfoState,
    scanningState
} from '../stateManagement/processState';
import { mapState, deviceInfoState, currentUserState, traceMapState, mlOutputFilterIndicesState, inspectionModalFeedState } from '../stateManagement/commonState';
require('nerdamer/Solve');

const fentanylLabels = ['Acetylfentanyl', 'Carfentanil', 'Despropionyl fentanyl', 'Fentanyl', 'Furanylfentanyl', 'Parafluorofentanyl', 'Remifentanil'];

const MappingGrid = props => {
    const { calibratingHeight, heightCalibration, wavenumberFilterIndex, updateSpectrumLineChart } = props;
    const eChartsRef = React.useRef(null);
    const [surfacePoints, setSurfacePoints] = useState([]);
    const [sectionAveragePoints, setSectionAveragePoints] = useState([]);
    const [, setSectionVertexIndices] = useState([]);
    const [numCalibrationPointsPerSide] = useAtom(numCalibrationPointsPerSideState);
    const [numVerticalCalibrationPoints] = useAtom(numVerticalCalibrationPointsState);
    const [center] = useAtom(centerState);
    const [scanWidth] = useAtom(scanWidthState);
    const [scanHeight] = useAtom(scanHeightState);
    const [calibration] = useAtom(calibrationState);
    const [calibrating] = useAtom(calibratingState);
    const [pointsPerSide] = useAtom(pointsPerSideState);
    const [numLevels] = useAtom(numLevelsState);
    const [singlePointMultiplier] = useAtom(singlePointMultiplierState);
    const [colors] = useAtom(colorsState);
    const [map] = useAtom(mapState);
    const mapRef = React.useRef(null);
    const [traceMap] = useAtom(traceMapState);
    const traceMapRef = React.useRef(null);
    const [currentPath, setCurrentPath] = useAtom(currentPathState);
    const [currentUser] = useAtom(currentUserState);
    const mappingGridConfig = getMappingGridConfig(center);
    const [map3dOption, setMap3dOption] = useState(mappingGridConfig);
    const [calibrationInfo] = useAtom(calibrationInfoState);
    const [deviceInfo] = useAtom(deviceInfoState);
    const [scanning] = useAtom(scanningState);
    const [mlOutputFilterIndices] = useAtom(mlOutputFilterIndicesState);
    const maxMapValueAtFilteredWavenumberIndex = useRef(null);
    const maxMapValueForMLOutputFilterIndices = useRef(null);
    const [, setInspectionModalFeed] = useAtom(inspectionModalFeedState);

    useEffect(() => {
        mapRef.current = map;
        traceMapRef.current = traceMap;
    }, [map, traceMap]);

    useEffect(() => {
        if (!calibrating && calibration.length) {
            let currentSectionVertexIndices = generateSectionVertexIndices(numCalibrationPointsPerSide);
            setSectionVertexIndices(currentSectionVertexIndices);
            let calibrationCoordinates = generateCalibrationCoordinates(numCalibrationPointsPerSide, center, scanWidth);
            let verticals = groupVerticals(numCalibrationPointsPerSide, numVerticalCalibrationPoints, calibration);
            let calibrationValues = interpolateVerticals(verticals);
            let currentSurfacePoints = calibrationCoordinates.map((coordinate, i) => [...coordinate, calibrationValues[i]]);
            let sectionVertexIndices = generateSectionVertexIndices(numCalibrationPointsPerSide);
            let currentSectionAveragePoints = computeSectionAveragePoints(currentSurfacePoints, sectionVertexIndices);
            setSurfacePoints(currentSurfacePoints);
            setSectionAveragePoints(currentSectionAveragePoints);
        }
    }, [calibrating]);

    useEffect(() => {
        if (!calibration.length) {
            setSectionVertexIndices([]);
            setSurfacePoints([]);
            setSectionAveragePoints([]);
        }
    }, [calibration]);

    const generateCalibrationSeries = (data) => {
        let calibrationSeries = {
            name: 'Calibration',
            type: 'scatter3D',
            silent: true,
            data: data.map((e, i) => [e.x + (-2 * (e.x - (deviceInfo?.center_calibration?.x || center.x))), e.y, e.z, e.x, e.index, Math.max(...e.processedSpectrum)]),
            color: '#434343',
        }
        return calibrationSeries;
    }

    const generatePathSeries = (opacity) => {
        return ([{
            type: 'line3D',
            silent: true,
            animationDurationUpdate: 300,
            animationEasingUpdate: 'elasticOut',
            lineStyle: {
                width: 3,
                opacity: opacity,
                color: '#1890ff',
            },
            data: currentPath.map(e => [e[0] + (-2 * (e[0] - (deviceInfo?.center_calibration?.x || center.x))), e[1], e[2]]),
        }
        ]);
    };

    const getVisualMapDataForPoint = (point, wavenumberFilterIndex, mlOutputFilterIndices) => {
        let visualMapValue = 0;
        if (mlOutputFilterIndices.length) {
            let sumOfMLOutput = mlOutputFilterIndices.reduce((tfOutputSum, substanceIndex) => {
                tfOutputSum += point.result[0][substanceIndex];
                return tfOutputSum;
            }, 0);
            visualMapValue += (sumOfMLOutput / maxMapValueForMLOutputFilterIndices.current);
        }
        if (wavenumberFilterIndex !== null) {
            if (!mlOutputFilterIndices.length) {
                visualMapValue += point.processedSpectrum[wavenumberFilterIndex];
            } else {
                visualMapValue = visualMapValue * (point.processedSpectrum[wavenumberFilterIndex] / maxMapValueAtFilteredWavenumberIndex.current);
            }
        }
        if (!mlOutputFilterIndices.length && wavenumberFilterIndex === null) {
            visualMapValue = Math.max(...point.processedSpectrum.slice(10, -10));
        }
        return visualMapValue * 100;
    }

    const getVisualMapMinMax = (newOption) => {
        let visualMapValues = newOption.series.reduce((values, series) => {
            if (series.type === 'scatter3D' && series.data.length) {
                values.push(...series.data.map(e => e[5]).filter(value => typeof value === 'number'));
            }
            return values;
        }, []);
        return ([Math.min(...visualMapValues), Math.max(...visualMapValues)]);
    }

    const generateMapSeries = (groupedByLabel) => {
        return Object.entries(groupedByLabel).map((e, i) => {
            return ({
                name: e[0],
                type: 'scatter3D',
                silent: currentUser?.attributes['custom:Role'] === '1' ? false : true,
                data: [...e[1].map(
                    e => {
                        let visualMapData = getVisualMapDataForPoint(e, wavenumberFilterIndex, mlOutputFilterIndices);
                        return [e.x + (-2 * (e.x - (deviceInfo?.center_calibration?.x || center.x))), e.y, e.z, e.x, e.index, visualMapData, e.mlOutput, e.reconstructionError, e.result[0][e.result[0].length - 1]]
                    }
                ),
                { value: [-100, -100, -100], itemStyle: { opacity: 0 } },
                { value: [100, 100, 100], itemStyle: { opacity: 0 } },
                ],
                color: colors[i],
                encode: {
                    index: 3,
                    symbolSize: 4
                },
                label: {
                    show: false,
                    formatter: ''
                },
            });
        });
    }

    const updateMap = () => {
        setMap3dOption(prevOption => {
            let newOption = cloneDeep(prevOption);
            let blendedMap = [...map, ...traceMap];
            if (wavenumberFilterIndex !== null && blendedMap.length) {
                maxMapValueAtFilteredWavenumberIndex.current = Math.max(...blendedMap.filter(e => Object.keys(e).length).map(e => e.processedSpectrum[wavenumberFilterIndex]));
            }
            if (mlOutputFilterIndices.length && blendedMap.length) {
                let mlOutputSums = blendedMap.filter(e => Object.keys(e).length).map(point => {
                    let sumOfMLOutput = mlOutputFilterIndices.reduce((tfOutputSum, substanceIndex) => {
                        tfOutputSum += point.result[0][substanceIndex];
                        return tfOutputSum;
                    }, 0);
                    return sumOfMLOutput
                });
                maxMapValueForMLOutputFilterIndices.current = Math.max(...mlOutputSums);
            }
            if (deviceInfo?.center_calibration) {
                newOption = updateAxes(newOption, { ...deviceInfo.center_calibration, z: defaultCenter.z });
            }
            newOption.prevSeriesCount = newOption.series.length;
            newOption.series = [];
            newOption.legend.data = [];
            if (calibrationInfo.calibrating) {
                let data = [];
                if (calibrationInfo.firstXYCalibration.active || calibrationInfo.firstHeightCalibration.active) {
                    data.push(...calibrationInfo.firstXYCalibration.data);
                    data.push(...calibrationInfo.firstHeightCalibration.data);
                } else if (calibrationInfo.secondXYCalibration.active || calibrationInfo.secondHeightCalibration.active) {
                    data.push(...calibrationInfo.secondXYCalibration.data);
                    data.push(...calibrationInfo.secondHeightCalibration.data);
                }
                newOption.series.push(generateCalibrationSeries(data));
                if (calibrationInfo.firstHeightCalibration.data.length) {
                    newOption.visualMap[0].min = Math.min(...data.map(e => Math.max(...e.processedSpectrum)));
                    newOption.visualMap[0].max = Math.max(...data.map(e => Math.max(...e.processedSpectrum)));
                } else {
                    newOption.visualMap[0].min = 150;
                    newOption.visualMap[0].max = 151;
                }
                newOption.visualMap[0].range = [newOption.visualMap[0].min, newOption.visualMap[0].max];
            }
            let groupedByLabel = blendedMap.filter(e => e.label != null).reduce((r, a) => {
                r[a.label] = r[a.label] || [];
                r[a.label].push(a);
                return r;
            }, Object.create(null));
            newOption.series.push(...generatePathSeries(blendedMap.length ? (scanning ? 0.4 : 0) : 0.8))
            let [visualMapMin, visualMapMax] = [null, null];
            if (map.length) {
                newOption.series.push(...generateMapSeries(groupedByLabel));
                [visualMapMin, visualMapMax] = (wavenumberFilterIndex === null && !mlOutputFilterIndices.length) ? [1, 1] : getVisualMapMinMax(newOption);
                newOption.visualMap[0].min = visualMapMin;
                newOption.visualMap[0].max = visualMapMax;
                newOption.visualMap[0].range = [newOption.visualMap[0].min, newOption.visualMap[0].max];
                newOption.legend.data = Object.entries(groupedByLabel).map((e, i) => {
                    return ({
                        name: e[0],
                        icon: 'circle',
                    });
                });
            };
            newOption.visualMap[0].show = (currentUser?.attributes['custom:Role'] === '1' && visualMapMin !== visualMapMax) ? true : false;
            return JSON.stringify(prevOption) === JSON.stringify(newOption) ? prevOption : newOption;
        });
    }

    const updateAxes = (option, center) => {
        let newOption = { ...option };
        let axes = ['xAxis3D', 'yAxis3D', 'zAxis3D'];
        for (let i = 0; i < axes.length; i++) {
            newOption[axes[i]].min = center[axes[i][0]] - 3;
            newOption[axes[i]].max = center[axes[i][0]] + 3;
        }
        return newOption;
    }

    const eChartsEvents = {
        click: e => {
            setInspectionModalFeed(mapRef.current[e.data[4]]);
            if (mapRef.current[e.data[4]]) {
                console.log(mapRef.current[e.data[4]]);
                console.table(fentanylLabels.map((label,i) => {
                    return {
                        label: label,
                        trace_value: mapRef.current[e.data[4]].fentanylPrediction[i]
                    }
                }));
                updateSpectrumLineChart({
                    index: e.data[4],
                    rawSpectrum: mapRef.current[e.data[4]].rawSpectrum,
                    badPixelCorrectedSpectrum: mapRef.current[e.data[4]].badPixelCorrectedSpectrum,
                    smoothedSpectrum: mapRef.current[e.data[4]].smoothedSpectrum,
                    baselineCorrectedSpectrum: mapRef.current[e.data[4]].baselineCorrectedSpectrum,
                    intensityCorrectedSpectrum: mapRef.current[e.data[4]].intensityCorrectedSpectrum,
                    normalizedSpectrum: mapRef.current[e.data[4]].normalizedSpectrum,
                    interpolatedSpectrum: mapRef.current[e.data[4]].interpolatedSpectrum,
                    processedSpectrum: mapRef.current[e.data[4]].processedSpectrum,
                    integrationTime: mapRef.current[e.data[4]].integrationTime
                });
            }
        }
    }

    useEffect(() => {
        let points = getExpandingSquareSearchPoints(center, scanWidth, scanHeight, pointsPerSide, numLevels, singlePointMultiplier);
        if (surfacePoints.length && sectionAveragePoints.length) {
            points = applyCalibrationOffset(points, center, surfacePoints, sectionAveragePoints)
        }
        setCurrentPath(points);
    }, [calibratingHeight, heightCalibration, wavenumberFilterIndex, map, colors, pointsPerSide, numLevels, scanWidth, scanHeight, singlePointMultiplier, center, calibration, calibrating, calibrationInfo, traceMap, mlOutputFilterIndices]);

    useEffect(() => {
        updateMap();
    }, [currentPath]);

    useEffect(() => {
        if (eChartsRef && eChartsRef.current) {
            eChartsRef.current.getEchartsInstance().setOption(map3dOption, map3dOption.prevSeriesCount === map3dOption.series.length ? false : true);
        }
    }, [map3dOption]);

    const map3D = useMemo(() =>
        <ReactEcharts
            option={map3dOption}
            ref={eChartsRef}
            style={{ height: "600px", width: "100%" }}
            onEvents={eChartsEvents}
        />,
        []
    );

    return (<>{map3D}</>);
}

export default MappingGrid;
