import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Table, Typography, Descriptions, Button, Input } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { useAtom } from "jotai";
import { userNameState, isAdminState, allSubstancesDataState } from "../stateManagement/commonState";
import { saveSubstanceInfo } from "../utils/dbHelpers";

const { Title, Text } = Typography;
const { TextArea } = Input;


const SubstanceManagement = () => {

    const [allSubstancesData] = useAtom(allSubstancesDataState);
    const [substanceManagementTableData, setSubstanceManagementTableData] = useState([]);
    const [substanceTableExpandedRow, setSubstanceTableExpandedRow] = useState([]);
    const [username] = useAtom(userNameState);
    const [isAdmin] = useAtom(isAdminState);
    const [isEditing, setIsEditing] = useState(false);
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };
    const handleReset = (clearFilters) => {
        clearFilters();
    };

    useEffect(() => {
        if (Object.keys(allSubstancesData).length > 0) {
            let substanceManagementTableData = Object.entries(allSubstancesData).map(([substance, data]) => ({
                key: substance,
                substance: substance,
                overview: data?.info?.overview,
                effects: data?.info?.effects,
                safety: data?.info?.safety
            }));
            setSubstanceManagementTableData(substanceManagementTableData);
        }
    }, [allSubstancesData]);

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownOpenChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        }
    });

    let substanceManagementTableColumns = [
        {
            title: 'Substance',
            dataIndex: 'substance',
            key: 'substance',
            align: 'center',
            width: 180,
            ...getColumnSearchProps('substance'),
        },
        {
            title: 'Overview',
            dataIndex: 'overview',
            key: 'overview',
            align: 'center',
            width: 180,
            render: text => (text ? (<div style={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}>{text}</div>) : null),
        },
        {
            title: 'Effects',
            dataIndex: 'effects',
            key: 'effects',
            align: 'center',
            width: 180,
            render: text => (text ? (<div style={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}>{text}</div>) : null),
        },
        {
            title: 'Safety',
            dataIndex: 'safety',
            key: 'safety',
            align: 'center',
            width: 180,
            render: text => (text ? (<div style={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}>{text}</div>) : null),
        },
    ];

    return (
        <>
            <Row style={{ marginTop: '12px' }}>
                <Title level={3}>Substance Management</Title>
            </Row>
            <Row justify="center" align="middle" style={{ marginTop: "6px" }}>
                <Col span={24} style={{ textAlign: "center" }} >
                    <Table
                        dataSource={substanceManagementTableData}
                        columns={substanceManagementTableColumns}
                        scroll={{ x: '480px' }}
                        sticky
                        pagination={{
                            position: ['bottomCenter'],
                            pageSize: 10,
                            showSizeChanger: false,
                        }}
                        expandable={{
                            expandRowByClick: true,
                            expandIconColumnIndex: -1,
                            expandedRowKeys: substanceTableExpandedRow,
                            onExpand: (expanded, record) => {
                                setSubstanceTableExpandedRow(expanded ? [record.key] : [])
                            },
                            expandedRowRender: record => {
                                return (<>
                                    <Descriptions title={record.substance} bordered={true} column={1} size="small" layout="vertical" items={[
                                        {
                                            key: 'overview',
                                            label: 'Overview',
                                            children: <>{
                                                isEditing ?
                                                    <TextArea
                                                        autoSize
                                                        defaultValue={record.overview}
                                                        onChange={e => { record.overview = e.target.value; }}
                                                    /> :
                                                    record.overview ? <Text style={{ whiteSpace: 'pre-line' }}>{record.overview}</Text> : 'No information available.'
                                            }</>
                                        },
                                        {
                                            key: 'effects',
                                            label: 'Effects',
                                            children: <>{
                                                isEditing ?
                                                    <TextArea
                                                        autoSize
                                                        defaultValue={record.effects}
                                                        onChange={e => { record.effects = e.target.value; }}
                                                    /> :
                                                    record.effects ?<Text style={{ whiteSpace: 'pre-line' }}>{record.effects}</Text> : 'No information available.'
                                            }</>
                                        },
                                        {
                                            key: 'safety',
                                            label: 'Safety',
                                            children: <>{
                                                isEditing ?
                                                    <TextArea
                                                        autoSize
                                                        defaultValue={record.safety}
                                                        onChange={e => { record.safety = e.target.value; }}
                                                    /> :
                                                    record.safety ? <Text style={{ whiteSpace: 'pre-line' }}>{record.safety}</Text> : 'No information available.'
                                            }</>
                                        }
                                    ]}>
                                    </Descriptions>
                                    {isAdmin && username === 'admin' ? <Button
                                        type="primary" ghost
                                        style={{ margin: '10px' }}
                                        disabled={!isAdmin}
                                        onClick={() => {
                                            if (isEditing) {
                                                saveSubstanceInfo({
                                                    label: record.substance,
                                                    info: {
                                                        overview: record.overview,
                                                        effects: record.effects,
                                                        safety: record.safety
                                                    }
                                                });
                                            };
                                            setIsEditing(!isEditing);
                                        }}
                                    >
                                        {isEditing ? 'Save' : 'Edit'}
                                    </Button> : null}
                                    {isAdmin && username === 'admin' && isEditing ? <Button
                                        type="primary" ghost
                                        style={{ margin: '10px' }}
                                        disabled={!isAdmin}
                                        onClick={() => {
                                            if (isEditing) {
                                                let originalData = allSubstancesData[record.substance];
                                                record.overview = originalData.info.overview;
                                                record.effects = originalData.info.effects;
                                                record.safety = originalData.info.safety;
                                            };
                                            setIsEditing(!isEditing);
                                        }}
                                    >
                                        Cancel
                                    </Button> : null}
                                </>)
                            }
                        }}
                    >
                    </Table>
                </Col>
            </Row>
        </>
    )


}

export default SubstanceManagement;