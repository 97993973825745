import React, { useEffect, useState } from 'react';
import { useAtom } from 'jotai';
import { Button, Modal, Input, Radio, Tooltip, Cascader } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { getWavenumbers } from '../utils/wavenumbers';
import { mapState } from '../stateManagement/commonState';

let type = 'processed'

const DownloadCSV = ({ deviceInfo, currentSerial }) => {
    const [map] = useAtom(mapState);
    const wavenumbers = deviceInfo ? getWavenumbers(500, deviceInfo.wavelength_calibration, deviceInfo.excitation_wavelength) : [];
    const standardWavenumbers = [...Array(2324 - 225 + 1)].map((_, i) => 225 + i);
    const [cascaderOptions, setCascaderOptions] = useState([]);
    const [labelsToExclude, setLabelsToExclude] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        if (map.length) {
            const uniqueLabels = [...new Set(map.map(e => e.label))];
            setCascaderOptions(uniqueLabels.map(label => ({ label, value: label })));
        }
    }, [map]);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const downloadCSV = labelsToExclude => {
        let header = type === 'processed' ? [null, ...standardWavenumbers] : [null, ...wavenumbers];
        let spectra;
        const filteredMap = map.filter(e => !labelsToExclude.includes(e.label));
        if (type === 'processed') {
            spectra = filteredMap.map(e => [e.label, ...e.processedSpectrum]);
        } else if (type === 'raw') {
            spectra = filteredMap.map(e => [e.label, ...e.rawSpectrum]);
        }
        let csv = [header, ...spectra].map(row => row.join(',')).join('\n');
        let blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        let url = URL.createObjectURL(blob);

        let a = document.createElement('a');
        a.href = url;
        a.setAttribute('download', `Scatr Map Serial ${currentSerial} - ${type}.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        setIsModalVisible(false);
    }

    const filter = (inputValue, path) => path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

    return (
        <>
            <Tooltip placement="bottomRight" title={`Download Map`}>
                <Button icon={<DownloadOutlined />} onClick={showModal} disabled={map.length ? false : true}></Button>
                <Modal title='Download' open={isModalVisible} onOk={() => { downloadCSV(labelsToExclude) }} onCancel={handleCancel}>
                    <div>Serial: {currentSerial}</div>
                    <div>Exclude Labels:</div>
                    <Cascader
                        multiple
                        maxTagCount='responsive'
                        style={{ width: '100%', margin: '10px 0' }}
                        options={cascaderOptions}
                        onChange={e => setLabelsToExclude(e.flat())}
                        placeholder="Add labels to exclude"
                        showSearch={{ filter }}
                    />
                    <Radio.Group defaultValue={type} buttonStyle="solid" onChange={e => type = e.target.value}>
                        <Radio.Button value="raw">Raw</Radio.Button>
                        <Radio.Button value="processed">Processed</Radio.Button>
                    </Radio.Group>
                </Modal>
            </Tooltip>
        </>
    )
};

export default DownloadCSV;