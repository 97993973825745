import React from 'react';
import { useAtom } from 'jotai';
import { Space, Button, Tooltip, Badge } from 'antd';
import { LineChartOutlined, SettingOutlined, InfoCircleOutlined, UserSwitchOutlined, WifiOutlined, ExperimentOutlined, SyncOutlined } from '@ant-design/icons';
import { mapState, currentUserState, userNameState, deviceInfoState, isInspectionModalOpenState, mapReprocessTriggerState } from '../stateManagement/commonState';
import { scanningState } from '../stateManagement/processState';
import { showChartsFlag } from '../stateManagement/actionButtonState';
import DownloadCSV from './DownloadCSV';

const ActionButtons = ({ signOut, settingsToggleHandler, deviceInfoToggleHandler, setShowReprocessMapModal, runTest, setWifiConfigModalOpen, wifiConfigModalOpen, bluetoothState, currentSerial }) => {
  const [map] = useAtom(mapState);
  const [scanning] = useAtom(scanningState);
  const [currentUser, setCurrentUser] = useAtom(currentUserState);
  const [username] = useAtom(userNameState);
  const [showCharts, setShowCharts] = useAtom(showChartsFlag);
  const [deviceInfo] = useAtom(deviceInfoState);
  const [, setIsInspectionModalOpen] = useAtom(isInspectionModalOpenState);
  const [mapReprocessTrigger, setMapReprocessTrigger] = useAtom(mapReprocessTriggerState);

  const signOutHandler = () => {
    setCurrentUser(null);
    signOut();
  }

  return (
    <div style={{ position: 'absolute', top: '30px', right: '6%' }}>
      <Space>
        {currentUser?.attributes['custom:Role'] !== '1' ?
          null : <>
            <Tooltip placement='bottomRight' title='Inspect'>
              <Button
                icon={<ExperimentOutlined />}
                onClick={() => { setIsInspectionModalOpen(true) }}
              />
            </Tooltip>
            <Tooltip placement='bottomRight' title='Charts'>
              <Button
                icon={<LineChartOutlined />}
                onClick={() => { setShowCharts(!showCharts) }}
              />
            </Tooltip>
            <Tooltip placement='bottomRight' title='Reprocess Map'>
              <Button
                icon={<SyncOutlined />}
                onClick={() => { setMapReprocessTrigger(!mapReprocessTrigger) }}
              />
            </Tooltip>
            <Tooltip placement='bottomRight' title='Settings'>
              <Button
                icon={<SettingOutlined />}
                onClick={settingsToggleHandler}
              />
            </Tooltip>
            <DownloadCSV deviceInfo={deviceInfo} currentSerial={currentSerial} />
            <Tooltip placement='bottomRight' title='Device Info'>
              <Button
                icon={<InfoCircleOutlined />}
                onClick={deviceInfoToggleHandler}
                disabled={scanning || bluetoothState === 'Disconnected'}
              />
            </Tooltip>
          </>
        }
        <Badge dot status={`${({ '1': 'success', '0': 'error', '-1': 'warning' }[deviceInfo?.device_network_status] || 'error')}`}>
          <Tooltip placement='bottomRight' title={`${deviceInfo?.device_network_status ? "" : "Connect Device to Wifi"}`}>
            <Button
              icon={<WifiOutlined />}
              onClick={() => setWifiConfigModalOpen(!wifiConfigModalOpen)}
              disabled={scanning || bluetoothState === 'Disconnected'}
            />
          </Tooltip>
        </Badge>
        <Tooltip placement='bottomRight' title={`Logout (${username})`}>
          <Button
            icon={<UserSwitchOutlined />}
            onClick={signOutHandler}
            disabled={scanning}
          />
        </Tooltip>
      </Space>
    </div>
  );
}

export default ActionButtons;
