import React, { useState, useRef, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import { Auth } from 'aws-amplify';
import ClientUploads from './components/ClientUploads';
import PrimaryCharts from './components/PrimaryCharts';
import { DeviceInfoTable } from './components/DeviceInfoTable';
import classes from './App.module.css';
import 'antd/dist/reset.css';
import { SettingsButtons } from './components/SettingsButtons';
import { useAtom } from 'jotai';
import {
  minLaserPowerState,
  maxLaserPowerState,
  minIntegrationTimeState,
  maxIntegrationTimeState,
  defaultCenter,
  centerState,
  signalThresholdState,
  reconstructionErrorThresholdState,
} from './stateManagement/controlButtonState';
import {
  mapState,
  currentUserState,
  userNameState,
  deviceInfoState,
  isAdminState,
  traceMapState,
  allSubstancesDataState,
  isNewRecordCreatedState,
  imageFilenameState,
  isInspectionModalOpenState,
  isTestInfoModalOpenState,
  isOperatorModalOpenState,
  mapReprocessTriggerState,
  wifiCharacteristicState,
} from './stateManagement/commonState';
import {
  scanningState,
  scanHeightState,
  scanWidthState,
  pointsPerSideState,
  numLevelsState,
  numPointsState,
  singlePointMultiplierState,
  currentPathState,
  calibrationInfoState,
  defaultCalibrationInfo,
  scanTypeState
} from './stateManagement/processState';
import {
  Row,
  Col,
  message,
  Modal,
  Layout,
  Radio,
  notification,
  Progress
} from 'antd';
import {
  LoadingOutlined,
  PlayCircleOutlined,
  VerticalAlignBottomOutlined,
  CopyOutlined,
  CheckOutlined,
  UndoOutlined,
  CloseOutlined,
  SyncOutlined,
  LinkOutlined,
} from '@ant-design/icons';
import { substanceArray, substanceMap, substanceThresholds } from './utils/substancesAndColors';
import {
  connectButtonHandler,
  subscribeToCharacteristic,
  disconnectBluetoothDevice,
  writeToDevice,
  readEEPROM
} from './utils/bluetoothLogic';
import {
  indexOfMax,
  calibrateRamanIntensity,
  removeBadPixels,
  interpolate,
  getWeek,
  chunkArrayIntoN,
  arrayBufferToBufferCycle
} from './utils/helpers';
import {
  uploadSpectra,
  fetchSpectrum,
  fetchSubstances,
  fetchDeviceInfo,
  updateDeviceFirmwareVersionInDB,
  updateDeviceNetworkStatusInDB,
  uploadResultsObject,
  updateDeviceCameraStatusInDB
} from './utils/dbHelpers';
import { interpolateVerticals } from './utils/calibrationHelpers';
import { getExpandingSquareSearchPoints } from './utils/getExpandingSquareSearch';
import logoImage from './images/ScatrLogo.svg';
import { parseEEPROM } from './utils/parseEEPROM';
import { getWavenumbers } from './utils/wavenumbers';
import ActionButtons from './components/ActionButtons';
import WifiConfigModal from './components/WifiConfigModal';
import { smooth } from './utils/modifiedSincSmoother.js'
import { AdditionalSettings } from './components/AdditionalSettings';
import { ProgressSteps } from './components/ProgressSteps';
import ResultsTable from './components/ResultsTable';
import { model1State, model2State } from './stateManagement/mlState';
import MassSpectrometryContainer from './components/MassSpectrometry/MassSpectrometryContainer';
import SubstanceInfoModal from './components/SubstanceInfoModal';
import SubstanceManagement from './components/SubstanceManagement';
import InspectionModal from './components/InspectionModal';
import RiskAwareness from './components/RiskAwareness';
import TestInfoModal from './components/TestInfoModal';
import OperatorModal from './components/OperatorModal';

import {
  getReconstructionError,
  getMLPredictionArray,
  getFentanylPrediction,
  getXylazinePrediction,
  getBenzodiazepinePrediction,
  getHydromorphonePrediction
} from './predict';

import { ASPLS } from './utils/baseline_aspls.js';

require('nerdamer/Solve');

const scanSettings = { 'quick': 7, 'standard': 13, 'detailed': 18 };

const standardWavenumbers = [...Array(2324 - 225 + 1)].map((_, i) => 225 + i);
const benzodiazepineLabels = ['Alprazolam', 'Bromazepam', 'Bromazolam', 'Clonazepam', 'Desalkylgidazepam', 'Diazepam', 'Etizolam', 'Flubromazepam', 'Flurazepam', 'Lorazepam', 'Oxazepam', 'Temazepam', 'Triazolam'];
const xylazineLabels = ['Xylazine', 'Xylazine hydrochloride'];

let scanTimestamp = null;

const { Footer, Content } = Layout;

const key = 'updatable';

const tf = require('@tensorflow/tfjs');

let device;
let characteristic;

let bluetoothStream = [];

let worker;
let tensorflowWorker = [];
let numTensorflowWorkers = 5;

let autoencoder;

(async () => {
  console.log(`NODE_ENV: ${process.env.NODE_ENV}`);
  await tf.ready();
  try {
    autoencoder = await tf.loadLayersModel(`/tf/autoencoder/model.json`);
  } catch (e) {
    console.error('Error loading autoencoder model.');
    console.error(e);
  }
})();

function App({ signOut }) {
  const [currentUser, setCurrentUser] = useAtom(currentUserState);
  const [username] = useAtom(userNameState);
  const [isAdmin, setIsAdmin] = useAtom(isAdminState);
  const [isInspectionModalOpen] = useAtom(isInspectionModalOpenState);
  const [isTestInfoModalOpen] = useAtom(isTestInfoModalOpenState);
  const [, setIsOperatorModalOpen] = useAtom(isOperatorModalOpenState);

  const [settings, setSettings] = useState(false);
  const [showDeviceInfo, setShowDeviceInfo] = useState(false);
  const [map, setMap] = useAtom(mapState);
  const [, setTraceMap] = useAtom(traceMapState);
  const calibratingRef = React.useRef(false);
  const footerRef = React.useRef(null);

  const [scanType, setScanType] = useAtom(scanTypeState);
  const [scanWidth, setScanWidth] = useAtom(scanWidthState);
  const [scanHeight, setScanHeight] = useAtom(scanHeightState);
  const [pointsPerSide, setPointsPerSide] = useAtom(pointsPerSideState);
  const [numLevels, setNumLevels] = useAtom(numLevelsState);
  const [numPoints, setNumPoints] = useAtom(numPointsState);
  const [singlePointMultiplier, setSinglePointMultiplier] = useAtom(singlePointMultiplierState);

  const [signalThreshold] = useAtom(signalThresholdState);
  const [minLaserPower] = useAtom(minLaserPowerState);
  const [maxLaserPower] = useAtom(maxLaserPowerState);
  const [minIntegrationTime] = useAtom(minIntegrationTimeState);
  const [maxIntegrationTime] = useAtom(maxIntegrationTimeState);
  const [center, setCenter] = useAtom(centerState);

  const [reconstructionErrorThreshold] = useAtom(reconstructionErrorThresholdState);
  const [shouldReprocessTraceMap, setShouldReprocessTraceMap] = useState(false);

  const [scanning, setScanning] = useAtom(scanningState);
  const scanningRef = React.useRef(false);
  const [calibrationInfo, setCalibrationInfo] = useAtom(calibrationInfoState);
  const [isNewRecordCreated, setIsNewRecordCreated] = useAtom(isNewRecordCreatedState);
  const [imageFilename] = useAtom(imageFilenameState);

  const [currentDataPoint, setCurrentDataPoint] = useState({});
  const [currentSerial, setCurrentSerial] = useState(null);
  const [currentMapID, setCurrentMapID] = useState(null);
  const [currentPath] = useAtom(currentPathState);

  const [deviceInfo, setDeviceInfo] = useAtom(deviceInfoState);
  const connectedDeviceInfoRef = useRef(null);
  const deviceInfoRef = useRef(null);
  const [deviceWavenumbers, setDeviceWavenumbers] = useState([]);
  const deviceWavenumbersRef = React.useRef([]);

  const workerMessageQueueRef = useRef([]);
  const workerMessageProcessingRef = useRef(false);
  const tensorflowWorkerMessageQueue = useRef([]);

  const warmupDelayTimeoutID = useRef(null);

  const [, setModel_1] = useAtom(model1State);
  const [, setModel_2] = useAtom(model2State);

  const consecutiveSaturatedCountRef = useRef(0);

  const [, setStoredMaps] = useState([]);
  const [wifiConfigModalOpen, setWifiConfigModalOpen] = useState(false);
  const [bluetoothState, setBluetoothState] = useState('Disconnected');
  const [resultsTableData, setResultsTableData] = useState([]);
  const [, setAllSubstancesData] = useAtom(allSubstancesDataState);
  const [latestUpload, setLatestUpload] = useState({});

  const [mapReprocessTrigger, setMapReprocessTrigger] = useAtom(mapReprocessTriggerState);
  const [notificationAPI, notificationContextHolder] = notification.useNotification();
  const [mapReprocessProgress, setMapReprocessProgress] = useState(0);

  const [wifiCharacteristic, setWifiCharacteristic] = useAtom(wifiCharacteristicState);

  useEffect(() => {
    (async _ => {
      let user = await Auth.currentAuthenticatedUser();
      setCurrentUser(user);
    })();

    worker = new Worker(new URL('./worker.js', import.meta.url));
    worker.onmessage = e => workerMessageHandler(e);
    for (let i = 0; i < numTensorflowWorkers; i++) {
      tensorflowWorker[i] = new Worker(new URL('./tensorflowWorker.js', import.meta.url));
      tensorflowWorker[i].onmessage = e => tensorflowWorkerMessageHandler(e);
    }

    const loadModels = async () => {
      const loadedModel1 = await tf.loadGraphModel('/tf/ml/model_1/model.json');
      const loadedModel2 = await tf.loadGraphModel('/tf/ml/model_2/model.json');
      setModel_1(loadedModel1);
      setModel_2(loadedModel2);
    };

    loadModels();

  }, []);

  useEffect(() => {
    if (bluetoothState === 'Disconnected') {
      setScanning(false);
      setStep(initialDisconnectedStepState);
      setCurrentStep(1);
    }
  }, [bluetoothState]);

  useEffect(() => {
    if (mapReprocessProgress > 0) {
      notificationAPI.open({
        key: 'reprocess',
        message: `Processing map...`,
        description: (<Progress percent={mapReprocessProgress} size="small" />),
        // placement: 'top',
        duration: 3,
        closeIcon: null
      });
    }
  }, [mapReprocessProgress]);

  useEffect(() => {
    if (mapReprocessTrigger) {

      const reprocessMap = async () => {
        let newMap = cloneDeep(map);
        for (const [i, dataPoint] of newMap.entries()) {
          setMapReprocessProgress(Math.round((i + 1) / newMap.length * 100));
          dataPoint["badPixelCorrectedSpectrum"] = removeBadPixels(dataPoint.rawSpectrum, deviceInfoRef.current.bad_pixels);
          dataPoint["smoothedSpectrum"] = smooth(dataPoint.badPixelCorrectedSpectrum, null);
          const baseline = ASPLS({ data: dataPoint["smoothedSpectrum"], x_data: deviceWavenumbersRef.current });
          dataPoint["baselineCorrectedSpectrum"] = dataPoint["smoothedSpectrum"].map((e, i) => Math.max(0, e - baseline[i]));
          dataPoint["intensityCorrectedSpectrum"] = calibrateRamanIntensity(dataPoint.baselineCorrectedSpectrum, deviceInfoRef.current.intensity_calibration);
          dataPoint["interpolatedSpectrum"] = [...dataPoint["processedSpectrum"]];
          dataPoint["processedSpectrum"] = interpolate(standardWavenumbers, deviceWavenumbersRef.current, dataPoint["intensityCorrectedSpectrum"]);
          let q = Math.hypot(...dataPoint["interpolatedSpectrum"]);
          dataPoint["normalizedSpectrum"] = dataPoint["interpolatedSpectrum"].map((e, i) => e / q);
          dataPoint.result = (await getMLPredictionArray([dataPoint.processedSpectrum]))[0];
          dataPoint.reconstructionError = (await getReconstructionError([dataPoint.processedSpectrum]))[0];
          dataPoint.fentanylPrediction = (await getFentanylPrediction([dataPoint.processedSpectrum]))[0][0];
          dataPoint.xylazinePrediction = (await getXylazinePrediction([dataPoint.processedSpectrum]))[0][0];
          dataPoint.benzodiazepinePrediction = (await getBenzodiazepinePrediction([dataPoint.processedSpectrum]))[0][0];
          dataPoint.hydromorphonePrediction = (await getHydromorphonePrediction([dataPoint.processedSpectrum]))[0][0];
          dataPoint.label = classifySpectrum(dataPoint.processedSpectrum, dataPoint.result, dataPoint.reconstructionError, dataPoint.rawSpectrum, dataPoint.benzodiazepinePrediction);
          dataPoint.mlOutput = dataPoint.result[0].map((f, i) => [substanceArray[i], f, dataPoint.result[1][i], dataPoint.result[2][i]]).sort((a, b) => b[1] - a[1]).map(g => [g[0], Math.round(g[1] * 1000) / 1000, Math.round(g[2] * 1000) / 1000, Math.round(g[3] * 1000) / 1000]);
        }
        setMap(newMap);
      }
      reprocessMap();
      setMapReprocessTrigger(false);
    }
  }, [mapReprocessTrigger]);

  useEffect(() => {
    setIsAdmin(currentUser?.attributes['custom:Role'] === '1');
    if (window.navigator.onLine) {
      fetchSubstances().then((substancesFromDB) => {
        const allSubstancesData = {};
        substancesFromDB.forEach(s => {
          allSubstancesData[s.label] = { info: s.info };
        });
        setAllSubstancesData(allSubstancesData);
      });
    }
  }, [currentUser]);

  useEffect(() => {
    setNumPoints(pointsPerSide === 1 && numLevels === 1 ? singlePointMultiplier : Math.pow(pointsPerSide, 2) * numLevels);
  }, [pointsPerSide, numLevels, singlePointMultiplier]);

  useEffect(() => {
    scanningRef.current = scanning;
    if (!scanning && map.length > 0) {
      (async () => {
        if (bluetoothState === 'Connected') {
          await writeToDevice(characteristic, [
            ['stopScan'],
            ['laserOFF'],
            ['clearCoordiantes'],
            ['move', [12.5e6, 20e6, 15e6]],
            ['move', [12.5e6, 50e6, 15e6]],
            ['executeCommands']
          ]);
        }
        stepEndScan();
      })();
      setCalibrationInfo(defaultCalibrationInfo);
    } else if (!scanning && map.length === 0) {
      clearTimeout(warmupDelayTimeoutID.current);
      (async () => {
        if (bluetoothState === 'Connected') {
          await writeToDevice(characteristic, [
            ['stopScan'],
            ['laserOFF'],
            ['clearCoordiantes'],
            ['move', [12.5e6, 20e6, 15e6]],
            ['move', [12.5e6, 50e6, 15e6]],
            ['executeCommands']
          ]);
        }
      })();
      setCalibrationInfo(defaultCalibrationInfo);
      setScanWidth(5);
      setPointsPerSide(scanSettings[scanType]);
      setCenter({ x: deviceInfo?.center_calibration.x || defaultCenter.x, y: deviceInfo?.center_calibration.y || defaultCenter.y, z: deviceInfo?.center_calibration.z || defaultCenter.z });
    }
  }, [scanning]);

  useEffect(() => {
    if (isNewRecordCreated) {
      scanButtonHandler();
      setIsNewRecordCreated(false);
    }
  }, [isNewRecordCreated]);

  useEffect(() => {
    if (!calibrationInfo.calibrating) {
      if (numPoints === map.length) {
        setScanning(false);
      }
      else if (scanning && map.length < numPoints) {
        stepScanHandler();
      }
      updateTraceResults(map);
    }
  }, [map]);

  useEffect(() => {
    deviceInfoRef.current = deviceInfo;
    if (deviceInfo) {
      setDeviceWavenumbers(
        getWavenumbers(500, deviceInfo.wavelength_calibration, deviceInfo.excitation_wavelength)
      );
    };
  }, [deviceInfo]);

  useEffect(() => {
    deviceWavenumbersRef.current = deviceWavenumbers;
  }, [deviceWavenumbers]);

  const mapViewButtonHandler = async (e) => {
    clear();
    let mapFromDB = await fetchSpectrum(e.map_id);
    let storedDeviceInfo = await fetchDeviceInfo(e.device_id);
    setDeviceInfo(storedDeviceInfo);
    setCurrentSerial(e.serial);
    let uniqueX = new Set(mapFromDB.map(e => e.coordinate[0]));
    let uniqueY = new Set(mapFromDB.map(e => e.coordinate[1]));
    let uniqueZ = new Set(mapFromDB.map(e => e.coordinate[2]));
    setCenter({
      x: (Math.max(...uniqueX) + Math.min(...uniqueX)) / 2,
      y: (Math.max(...uniqueY) + Math.min(...uniqueY)) / 2,
      z: (Math.max(...uniqueZ) + Math.min(...uniqueZ)) / 2,
    });
    let numPointsX = [...uniqueX].length;
    let numPointsY = [...uniqueY].length;
    setPointsPerSide(Math.max(numPointsX, numPointsY));
    setNumLevels([...new Set(mapFromDB.filter((e, i) => mapFromDB[0].coordinate[0] === e.coordinate[0] && mapFromDB[0].coordinate[1] === e.coordinate[1]).map(e => e.coordinate[2]))].length);
    let newScanWidth;
    if (numPointsX >= numPointsY) {
      newScanWidth = Math.max(...uniqueX) - Math.min(...uniqueX);
    } else {
      newScanWidth = Math.max(...uniqueY) - Math.min(...uniqueY);
    }
    setScanWidth(newScanWidth)
    setScanHeight(Math.max(...uniqueZ) - Math.min(...uniqueZ));
    if (newScanWidth === 0) { setSinglePointMultiplier(mapFromDB.length) }
    mapFromDB = mapFromDB.map(e => {
      let q = Math.hypot(...e.processed_spectrum);
      return { ...e, normalizedSpectrum: e.processed_spectrum.map(e => e / q) }
    });
    let chuckedMapDB = chunkArrayIntoN(mapFromDB, numTensorflowWorkers);
    for (let i = 0; i < numTensorflowWorkers; i++) {
      tensorflowWorker[i].postMessage(chuckedMapDB[i]);
    }
  }

  const clear = async () => {
    return new Promise(async (resolve, reject) => {
      bluetoothStream = [];
      setMap([]);
      setTraceMap([]);
      setCurrentSerial(null);
      consecutiveSaturatedCountRef.current = 0;
      if (connectedDeviceInfoRef?.current?.center_calibration) {
        setCenter(prevCenter => {
          let newCenter = { ...prevCenter };
          newCenter.x = connectedDeviceInfoRef.current.center_calibration.x;
          newCenter.y = connectedDeviceInfoRef.current.center_calibration.y;
          return newCenter
        });
      } else {
        setCenter({ x: defaultCenter.x, y: defaultCenter.y, z: defaultCenter.z });
      }
      setDeviceInfo(connectedDeviceInfoRef.current);
      resolve();
    });
  }

  const postScanHandler = async dataPoint => {

    dataPoint["badPixelCorrectedSpectrum"] = removeBadPixels(dataPoint.rawSpectrum, deviceInfoRef.current.bad_pixels);
    dataPoint["smoothedSpectrum"] = smooth(dataPoint.badPixelCorrectedSpectrum, null);
    if (calibratingRef.current) {
      setCurrentDataPoint(dataPoint);
    } else {
      worker.postMessage(dataPoint);
    }
  }

  function dequeueWorkerMessage() {
    if (workerMessageQueueRef.current.length > 0) {
      const dequeuedMessage = workerMessageQueueRef.current[0];
      workerMessageQueueRef.current = workerMessageQueueRef.current.slice(1);
      return dequeuedMessage;
    }
    return null;
  }

  async function processWorkerMessages() {
    if (workerMessageProcessingRef.current) return;
    workerMessageProcessingRef.current = true;

    while (workerMessageQueueRef.current.length > 0) {
      const dataPoint = dequeueWorkerMessage();
      dataPoint["intensityCorrectedSpectrum"] = calibrateRamanIntensity(dataPoint.baselineCorrectedSpectrum, deviceInfoRef.current.intensity_calibration);
      dataPoint["interpolatedSpectrum"] = interpolate(standardWavenumbers, deviceWavenumbersRef.current, dataPoint["intensityCorrectedSpectrum"]);
      dataPoint["processedSpectrum"] = dataPoint["interpolatedSpectrum"];
      let q = Math.hypot(...dataPoint["interpolatedSpectrum"]);
      dataPoint["normalizedSpectrum"] = dataPoint["interpolatedSpectrum"].map((e, i) => e / q);
      dataPoint.result = (await getMLPredictionArray([dataPoint.processedSpectrum]))[0];
      dataPoint.reconstructionError = (await getReconstructionError([dataPoint.processedSpectrum]))[0];
      dataPoint.fentanylPrediction = (await getFentanylPrediction([dataPoint.processedSpectrum]))[0][0];
      dataPoint.xylazinePrediction = (await getXylazinePrediction([dataPoint.processedSpectrum]))[0][0];
      dataPoint.benzodiazepinePrediction = (await getBenzodiazepinePrediction([dataPoint.processedSpectrum]))[0][0];
      dataPoint.hydromorphonePrediction = (await getHydromorphonePrediction([dataPoint.processedSpectrum]))[0][0];
      dataPoint.label = classifySpectrum(dataPoint.processedSpectrum, dataPoint.result, dataPoint.reconstructionError, dataPoint.rawSpectrum, dataPoint.benzodiazepinePrediction);
      dataPoint.mlOutput = dataPoint.result[0].map((f, i) => [substanceArray[i], f, dataPoint.result[1][i], dataPoint.result[2][i]]).sort((a, b) => b[1] - a[1]).map(g => [g[0], Math.round(g[1] * 1000) / 1000, Math.round(g[2] * 1000) / 1000, Math.round(g[3] * 1000) / 1000])
      setCurrentDataPoint(dataPoint);
      await new Promise((resolve) => setTimeout(resolve, 20)); // Allow for user interaction?
    }

    workerMessageProcessingRef.current = false;
  }

  const processtensorflowWorkerMessages = async () => {
    if (tensorflowWorkerMessageQueue.current.length === numTensorflowWorkers) {
      let sortedFragments = tensorflowWorkerMessageQueue.current.filter(arr => arr.length > 0).sort((a, b) => a[0].index - b[0].index);
      let mapFromDB = sortedFragments.flat();
      mapFromDB = mapFromDB.map(e => {
        const label = classifySpectrum(e.processed_spectrum, e.result, e.reconstructionError, e.raw_spectrum, e.benzodiazepinePrediction)
        if (e.result[0][substanceMap.get(label)] < substanceThresholds[label]) {
          e.result[0][substanceMap.get(label)] = substanceThresholds[label] + 0.001;
        }
        return { ...e, label: classifySpectrum(e.processed_spectrum, e.result, e.reconstructionError, e.raw_spectrum, e.benzodiazepinePrediction) }
      });
      mapFromDB = mapFromDB.map(e => { return { ...e, mlOutput: e.result[0].map((f, i) => [substanceArray[i], f, e.result[1][i], e.result[2][i]]).sort((a, b) => b[1] - a[1]).map(g => [g[0], Math.round(g[1] * 1000) / 1000, Math.round(g[2] * 1000) / 1000, Math.round(g[3] * 1000) / 1000]) } });
      mapFromDB = mapFromDB.map((e) => {
        return ({
          index: e.index,
          x: e.coordinate[0],
          y: e.coordinate[1],
          z: e.coordinate[2],
          power: e.laser_power,
          integrationTime: e.integration_time,
          rawSpectrum: e.raw_spectrum,
          processedSpectrum: e.processed_spectrum,
          normalizedSpectrum: e.normalizedSpectrum,
          result: e.result,
          label: e.label,
          mlOutput: e.mlOutput,
          reconstructionError: e.reconstructionError,
          fentanylPrediction: e.fentanylPrediction,
          xylazinePrediction: e.xylazinePrediction,
          benzodiazepinePrediction: e.benzodiazepinePrediction,
          hydromorphonePrediction: e.hydromorphonePrediction,
        })
      });
      setMap(mapFromDB);
      message.success({ content: 'Loaded!', key, duration: 3 });
      stepViewStored();
      tensorflowWorkerMessageQueue.current = [];
    }
  }

  const workerMessageHandler = e => {
    workerMessageQueueRef.current.push(e.data);
    processWorkerMessages();
  }

  const tensorflowWorkerMessageHandler = e => {
    tensorflowWorkerMessageQueue.current.push(e.data);
    processtensorflowWorkerMessages();
  }

  useEffect(() => {
    if (scanning && !calibrationInfo.calibrating) {
      setMap(prevMap => {
        let newMap = cloneDeep(prevMap);
        currentDataPoint.index = prevMap.length;
        newMap.push(currentDataPoint);
        return [...prevMap, currentDataPoint];
      });
    }
    else if (scanning && calibrationInfo.calibrating) {
      setCalibrationInfo(prev => {
        let newCalibrationInfo = cloneDeep(prev);
        let dataPoint = cloneDeep(currentDataPoint);
        dataPoint.processedSpectrum = dataPoint.processedSpectrum.slice(0, 1576);
        if (Math.max(...dataPoint.processedSpectrum) >= 65535) { dataPoint.processedSpectrum.fill(0); }
        if (prev.firstXYCalibration.active) {
          newCalibrationInfo.firstXYCalibration.data.push(dataPoint);
        } else if (prev.firstHeightCalibration.active) {
          newCalibrationInfo.firstHeightCalibration.data.push(dataPoint);
        } else if (prev.secondXYCalibration.active) {
          newCalibrationInfo.secondXYCalibration.data.push(dataPoint);
        } else if (prev.secondHeightCalibration.active) {
          newCalibrationInfo.secondHeightCalibration.data.push(dataPoint);
        }
        return newCalibrationInfo;
      });
    }
  }, [currentDataPoint]);

  const calculateCenterX = (pointsWithSignal) => {
    let minX = Math.min(...pointsWithSignal.map(point => point.x));
    let maxX = Math.max(...pointsWithSignal.map(point => point.x));
    let centerX = (minX + maxX) / 2;
    return centerX;
  }

  const calculateCenterY = (pointsWithSignal) => {
    let minY = Math.min(...pointsWithSignal.map(point => point.y));
    let maxY = Math.max(...pointsWithSignal.map(point => point.y));
    let centerY = (minY + maxY) / 2;
    return centerY;
  }

  const calculateCenterZ = (data) => {
    let centerZ = interpolateVerticals([data])[0]
    return centerZ;
  }

  const calculateScanWidth = (pointsWithSignal, scanWidthPadding) => {
    let minY = Math.min(...pointsWithSignal.map(point => point.y));
    let maxY = Math.max(...pointsWithSignal.map(point => point.y));
    let minX = Math.min(...pointsWithSignal.map(point => point.x));
    let maxX = Math.max(...pointsWithSignal.map(point => point.x));
    let rangeX = maxX - minX;
    let rangeY = maxY - minY;
    let scanWidth = Math.max(rangeX, rangeY) + (2 * scanWidthPadding);
    return scanWidth;
  }

  useEffect(() => {
    if (calibrationInfo.firstXYCalibration.data.length === (calibrationInfo.firstXYCalibration.settings.pointsPerSide ** 2) && calibrationInfo.firstXYCalibration.active) {
      let newCalibrationInfo = cloneDeep(calibrationInfo);
      let pointsWithSignal = newCalibrationInfo.firstXYCalibration.data.filter(point => Math.max(...point.processedSpectrum) >= newCalibrationInfo.firstXYCalibration.settings.signalThreshold);
      if (pointsWithSignal.length === 0) {
        setScanning(false);
        setCalibrationInfo(defaultCalibrationInfo);
        setStep(initialStepState);
        setCurrentStep(0);
        message.error({ content: 'No sample detected. Please try again.', key, duration: 10 });
        return;
      }
      newCalibrationInfo.firstXYCalibration.calculated.center_x = calculateCenterX(pointsWithSignal);
      newCalibrationInfo.firstXYCalibration.calculated.center_y = calculateCenterY(pointsWithSignal);

      let newCenter = cloneDeep(center);
      newCenter.x = newCalibrationInfo.firstXYCalibration.calculated.center_x;
      newCenter.y = newCalibrationInfo.firstXYCalibration.calculated.center_y;
      setCenter(newCenter);

      newCalibrationInfo.firstXYCalibration.calculated.scanWidth = calculateScanWidth(pointsWithSignal, 0.75);
      let newScanWidth = newCalibrationInfo.firstXYCalibration.calculated.scanWidth;
      setScanWidth(newScanWidth);

      newCalibrationInfo.firstXYCalibration.active = false;
      newCalibrationInfo.firstHeightCalibration.active = true;

      setCalibrationInfo(newCalibrationInfo);

      let maxCoordinates = newCalibrationInfo.firstXYCalibration.data.reduce((maxObj, curr) => Math.max(...curr.processedSpectrum) > maxObj.val ? { val: Math.max(...curr.processedSpectrum), coords: { x: curr.x, y: curr.y } } : maxObj, { val: -Infinity }).coords;

      let params = calibrationInfo.firstHeightCalibration.settings;
      let heightCalibrationCenter = { ...maxCoordinates, z: params.center_z };
      let points = getExpandingSquareSearchPoints(heightCalibrationCenter, newScanWidth, params.scanHeight, params.pointsPerSide, params.numLevels, 1);
      let path = points.map((point, i) => [Math.round(point[0] * 1e6), Math.round(point[1] * 1e6), Math.round(point[2] * 1e6)]);
      writeToDevice(characteristic, [['sendCoordinates', path], ['scanCoordinates'], ['executeCommands']]);

    } else if (calibrationInfo.firstHeightCalibration.data.length === (calibrationInfo.firstHeightCalibration.settings.numLevels) && calibrationInfo.firstHeightCalibration.active) {
      let newCalibrationInfo = cloneDeep(calibrationInfo);
      newCalibrationInfo.firstHeightCalibration.calculated.center_z = calculateCenterZ(newCalibrationInfo.firstHeightCalibration.data);
      let newCenter = cloneDeep(center);
      newCenter.z = newCalibrationInfo.firstHeightCalibration.calculated.center_z;
      setCenter(newCenter);

      newCalibrationInfo.firstHeightCalibration.active = false;
      newCalibrationInfo.secondXYCalibration.active = true;

      setCalibrationInfo(newCalibrationInfo);

      let params = calibrationInfo.secondXYCalibration.settings;
      let points = getExpandingSquareSearchPoints(newCenter, scanWidth, scanHeight, params.pointsPerSide, params.numLevels, 1);
      let path = points.map((point, i) => [Math.round(point[0] * 1e6), Math.round(point[1] * 1e6), Math.round(point[2] * 1e6)]);
      writeToDevice(characteristic, [['sendCoordinates', path], ['scanCoordinates'], ['executeCommands']]);

    } else if (calibrationInfo.secondXYCalibration.data.length === (calibrationInfo.secondXYCalibration.settings.pointsPerSide ** 2) && calibrationInfo.secondXYCalibration.active) {
      let newCalibrationInfo = cloneDeep(calibrationInfo);
      let pointsWithSignal = newCalibrationInfo.secondXYCalibration.data.filter(point => Math.max(...point.processedSpectrum) >= newCalibrationInfo.secondXYCalibration.settings.signalThreshold);
      if (pointsWithSignal.length === 0) {
        setScanning(false);
        setCalibrationInfo(defaultCalibrationInfo);
        setStep(initialStepState);
        setCurrentStep(0);
        message.error({ content: 'No sample detected. Please try again.', key, duration: 10 });
        return;
      }
      newCalibrationInfo.secondXYCalibration.calculated.center_x = calculateCenterX(pointsWithSignal);
      newCalibrationInfo.secondXYCalibration.calculated.center_y = calculateCenterY(pointsWithSignal);

      let newCenter = cloneDeep(center);
      newCenter.x = newCalibrationInfo.secondXYCalibration.calculated.center_x;
      newCenter.y = newCalibrationInfo.secondXYCalibration.calculated.center_y;
      setCenter(newCenter);

      newCalibrationInfo.secondXYCalibration.calculated.scanWidth = calculateScanWidth(pointsWithSignal, 0.05);
      let newScanWidth = newCalibrationInfo.secondXYCalibration.calculated.scanWidth;
      setScanWidth(newScanWidth);

      newCalibrationInfo.secondXYCalibration.active = false;
      newCalibrationInfo.secondHeightCalibration.active = true;

      setCalibrationInfo(newCalibrationInfo);

      let maxCoordinates = newCalibrationInfo.secondXYCalibration.data.reduce((maxObj, curr) => Math.max(...curr.processedSpectrum) > maxObj.val ? { val: Math.max(...curr.processedSpectrum), coords: { x: curr.x, y: curr.y } } : maxObj, { val: -Infinity }).coords;

      let params = calibrationInfo.secondHeightCalibration.settings;
      let points = getExpandingSquareSearchPoints({ ...maxCoordinates, z: center.z }, newScanWidth, params.scanHeight, params.pointsPerSide, params.numLevels, 1);
      let path = points.map((point, i) => [Math.round(point[0] * 1e6), Math.round(point[1] * 1e6), Math.round(point[2] * 1e6)]);
      writeToDevice(characteristic, [['sendCoordinates', path], ['scanCoordinates'], ['executeCommands']]);

    } else if (calibrationInfo.secondHeightCalibration.data.length === (calibrationInfo.secondHeightCalibration.settings.numLevels) && calibrationInfo.secondHeightCalibration.active) {
      let newCalibrationInfo = cloneDeep(calibrationInfo);
      newCalibrationInfo.secondHeightCalibration.calculated.center_z = calculateCenterZ(newCalibrationInfo.secondHeightCalibration.data);

      let newCenter = cloneDeep(center);
      newCenter.z = newCalibrationInfo.secondHeightCalibration.calculated.center_z;
      if (pointsPerSide === 1) {
        newCenter.x = newCalibrationInfo.secondHeightCalibration.data[0].x;
        newCenter.y = newCalibrationInfo.secondHeightCalibration.data[0].y;
      }

      setCenter(newCenter);

      newCalibrationInfo.secondHeightCalibration.active = false;
      newCalibrationInfo.calibrating = false;

      setCalibrationInfo(newCalibrationInfo);

      let points = getExpandingSquareSearchPoints(newCenter, scanWidth, scanHeight, pointsPerSide, numLevels, singlePointMultiplier);
      let path = points.map((point, i) => [Math.round(point[0] * 1e6), Math.round(point[1] * 1e6), Math.round(point[2] * 1e6)]);
      let commands = [];
      if (minLaserPower === maxLaserPower && minIntegrationTime === maxIntegrationTime) {
        commands.push(
          ['setIntegrationTime', maxIntegrationTime],
          ['setLaserPower', maxLaserPower],
          ['sendCoordinates', path],
          ['scanCoordinates']
        );
      } else {
        let laserPowers = Array.from({ length: currentPath.length }).map((e, i, _, step = (maxLaserPower - minLaserPower) / (currentPath.length - 1)) => Math.round(minLaserPower + i * step));
        let integrationTimes = Array.from({ length: currentPath.length }).map((e, i, _, step = (maxIntegrationTime - minIntegrationTime) / (currentPath.length - 1)) => Math.round(minIntegrationTime + i * step));
        for (let i = 0; i < path.length; i++) {
          commands.push(
            ['setIntegrationTime', integrationTimes[i]],
            ['setLaserPower', laserPowers[i]],
            ['sendCoordinates', path[i]],
            ['scanCoordinates']
          );
        }
      }
      commands.push(['executeCommands']);
      writeToDevice(characteristic, commands);
    }
  }, [calibrationInfo]);

  const classifySpectrum = (processedSpectrum, predictionArray, reconstructionError, rawSpectrum, benzodiazepinePrediction) => {
    let classification = 'Unknown'
    let maxRawValue = Math.max(...rawSpectrum);
    if (maxRawValue >= 65535) { return 'Unknown' }
    let maxProcessedValue = Math.max(...processedSpectrum);
    if (maxProcessedValue < signalThreshold) {
      classification = 'No Signal';
    }
    else if (processedSpectrum.indexOf(maxProcessedValue) > 1576) {
      classification = 'No Signal';
    }
    else if (reconstructionError > reconstructionErrorThreshold) {
      classification = 'Unknown';
      let index = indexOfMax(predictionArray[0]);
      if (
        ['Caffeine', 'Dimethyl sulfone', 'Mannitol', 'Erythritol', 'Inositol', 'Sorbitol', 'Xylitol'].includes(substanceArray[index])
        && predictionArray[0][index] > 0.42
        && reconstructionError < 0.00024
      ) {
        classification = substanceArray[index];
      }
    }
    else {
      let index = indexOfMax(predictionArray[0]);
      classification = predictionArray[0][index] > substanceThresholds[substanceArray[index]] ? substanceArray[index] : 'Unknown';
    }

    if (benzodiazepineLabels.includes(classification)) {
      const topTraceBenzodiazepineResult = benzodiazepineLabels.map((label, i) => ({ label: label, prediction: benzodiazepinePrediction[i] })).sort((a, b) => b.prediction - a.prediction)[0]
      if (topTraceBenzodiazepineResult.prediction > 0.2) {
        classification = benzodiazepineLabels.map((label, i) => ({ label: label, prediction: benzodiazepinePrediction[i] })).sort((a, b) => b.prediction - a.prediction)[0].label;
      } else {
        classification = 'Unknown';
      }
    }

    return classification
  }

  const sendMap = async () => {
    let filteredMap = map.filter(e => e.label);
    message.loading({ content: 'Saving...', key, duration: 0 });
    let resultsJSON = resultsTableData.reduce(function (acc, cur) {
      acc[cur.substance] = Math.round(cur.percentComposition / 100 * 10000) / 10000;
      return acc;
    }, {});
    let traceResultsJSON = resultsTableData.reduce(function (acc, cur) {
      let value = Math.round(cur.tracePercentComposition / 100 * 10000) / 10000;
      if (value > 0) {
        acc[cur.substance] = value;
      }
      return acc;
    }, {});
    await uploadResultsObject(resultsJSON, currentMapID, currentSerial, traceResultsJSON);
    uploadSpectra({
      indices: filteredMap.map(e => e.index),
      coordinates: filteredMap.map(e => [e.x, e.y, e.z]),
      laserPowers: filteredMap.map(e => e.power),
      integrationTimes: filteredMap.map(e => e.integrationTime),
      rawSpectra: filteredMap.map(e => e.rawSpectrum),
      processedSpectra: filteredMap.map(e => e.processedSpectrum),
      labels: filteredMap.map(e => e.label),
      username,
      scanTimestamp: scanTimestamp || Date.now(),
      device_id: deviceInfoRef.current.device_id,
      week: getWeek(),
      map_id: currentMapID
    }, message, key, initiateTableUpdate).then(res => {
      if (res.offline) {
        message.success({ content: 'Saved locally. Will upload when online!', key, duration: 5 });
      }
      initiateTableUpdate({ mapID: res.mapID, filteredMap });
    }).catch(e => console.error(`Error uploading spectra: ${e}`));
  }

  const initiateTableUpdate = ({ mapID, filteredMap }) => {
    setLatestUpload({ mapID, filteredMap });
    stepUploaded();
  }

  const handleCharacteristicValueChanged = async (event) => {
    if (!scanningRef.current) { return }
    let Uint16View = new Uint16Array(event.target.value.buffer);
    bluetoothStream.push(...Array.from(Uint16View));
    if (bluetoothStream.length >= 510) {
      let spectrumData = bluetoothStream.splice(0, 510);
      let rawSpectrum = spectrumData.slice(0, 500);
      let isSaturated = rawSpectrum.some(value => value >= 65535);
      if (isSaturated) {
        consecutiveSaturatedCountRef.current += 1;
        let numSaturatedPixels = rawSpectrum.filter(value => value >= 65535);
        if (consecutiveSaturatedCountRef.current === 1) {
          message.warning({ content: `Sensor saturation detected. ${numSaturatedPixels.length} pixel(s) affected.`, duration: 9 });
        } else if (consecutiveSaturatedCountRef.current === 2) {
          message.warning({ content: `Two consecutive sensor saturation events detected.`, duration: 9 });
        } else if (consecutiveSaturatedCountRef.current === 3) {
          setScanning(false);
          setStep(initialStepState);
          setCurrentStep(0);
          message.error({ content: `Three consecutive sensor saturation events detected. Aborting scan.`, duration: 9 });
        }
      } else if (consecutiveSaturatedCountRef.current > 0 && !isSaturated) {
        consecutiveSaturatedCountRef.current = 0;
      }
      let metaData16 = spectrumData.slice(500, 510);
      let metaData16View = new Uint16Array(metaData16);
      let metaData32View = new Uint32Array(metaData16View.buffer);
      let metaData = Array.from(metaData32View);
      let dataPoint = {};
      dataPoint.x = metaData[0] / 1e6;
      dataPoint.y = metaData[1] / 1e6;
      dataPoint.z = metaData[2] / 1e6;
      dataPoint.power = metaData[3];
      dataPoint.integrationTime = metaData[4];
      dataPoint.rawSpectrum = rawSpectrum;
      if (dataPoint.power > 450 || dataPoint.x > 25 || dataPoint.y > 50 || dataPoint.z > 20) {
        setScanning(false);
        setStep(initialStepState);
        setCurrentStep(0);
        message.error({ content: `Bluetooth transmission error detected. Please refresh the page.`, duration: 3600 });
        throw new Error('Bluetooth transmission error detected.');
      } else {
        postScanHandler(dataPoint);
      }
    }
  }

  const detectTraceFentanyl = (label, processedSpectrum, result, fentanylMLOutput, mlOutput) => {
    const approvedLabels = ['Caffeine', 'Dimethyl sulfone', 'Mannitol', 'Erythritol', 'Inositol', 'Sorbitol', 'Xylitol'];
    if (!approvedLabels.includes(label)) { return { hasTraceFentanyl: false, traceFentanylLabel: null } };
    const { maxFEMLValue, maxFEMLIndex } = fentanylMLOutput.reduce((acc, value, index) => {
      if (value > acc.maxFEMLValue) {
        return { maxFEMLValue: value, maxFEMLIndex: index };
      }
      return acc;
    }, { maxFEMLValue: -Infinity, maxFEMLIndex: -1 });
    const rankings = mlOutput.map(e => e[0]);
    const indexTopBase = rankings.findIndex(name => name.toLowerCase().includes('fent'));
    const maxMLValue = Math.max(mlOutput[indexTopBase][2], mlOutput[indexTopBase][3]);
    const maxInRegion = Math.max(...processedSpectrum.slice(1000 - 225 - 5, 1000 - 225 + 5 + 1));
    const isPeak = Math.max(...processedSpectrum.slice(1000 - 225 - 10, 1000 - 225 - 5)) < maxInRegion && Math.max(...processedSpectrum.slice(1000 - 225 + 6, 1000 - 225 + 11)) < maxInRegion;
    let threshold = 0.75;
    threshold = maxMLValue > 0.01 ? 0.035 : threshold;
    threshold = (isPeak && maxMLValue > 0.01) ? 0.015 : threshold;
    if (maxFEMLValue >= threshold && processedSpectrum[1000 - 225] > 15) {
      return { hasTraceFentanyl: true, traceFentanylLabel: 'Fentanyl' };
    }
    return { hasTraceFentanyl: false, traceFentanylLabel: null };
  }

  const detectTraceHydromorphone = (label, processedSpectrum, result, hydromorphonePrediction) => {
    if (!['Lactose', 'alpha-Lactose'].includes(label)) { return false; }
    if (hydromorphonePrediction[1] > 0.55) { return true; } else { return false; }
  }

  const detectTraceBenzodiazepine = (label, processedSpectrum, result, benzodiazepinePrediction, mlOutput) => {
    const approvedLabels = ['Caffeine', 'Dimethyl sulfone', 'Mannitol', 'Erythritol', 'Inositol', 'Sorbitol', 'Xylitol', 'Acetylfentanyl', 'Carfentanil', 'Despropionyl fentanyl', 'Fentanyl', 'Furanylfentanyl', 'Parafluorofentanyl', 'Remifentanil'];
    if (!approvedLabels.includes(label)) { return { hasTraceBenzodiazepine: false, traceBenzodiazepineLabel: null } };
    const benzodiazepinePredictionClassification = benzodiazepinePrediction;
    const passingBenzodiazepines = [];
    benzodiazepinePredictionClassification.forEach((e, i) => {
      let threshold = benzodiazepineLabels[i] === 'Bromazolam' ? 0.3 : 0.7;
      threshold = benzodiazepineLabels[i] === 'Oxazepam' ? 0.9 : threshold;
      if (e >= threshold && result[0][substanceMap.get(benzodiazepineLabels[i])] >= 0.01) {
        const indexML = mlOutput.findIndex(row => row[0] === benzodiazepineLabels[i]);
        const values = [mlOutput[indexML][2], mlOutput[indexML][3]];
        const max = Math.max(...values);
        const min = Math.min(...values);
        const difference = max / min;
        const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
        const variance = values.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / values.length;
        const standardDeviation = Math.sqrt(variance);
        passingBenzodiazepines.push({ label: benzodiazepineLabels[i], traceMLValue: e, standardDeviation, difference });
      }
    });
    if (passingBenzodiazepines.length > 0) {
      passingBenzodiazepines.sort((a, b) => a.traceMLValue - b.traceMLValue);
      return { hasTraceBenzodiazepine: true, traceBenzodiazepineLabel: passingBenzodiazepines[0].label }
    }
    return { hasTraceBenzodiazepine: false, traceBenzodiazepineLabel: null };

  }

  const detectTraceXylazine = (label, processedSpectrum, result, mlOutput, xylazinePrediction) => {
    const approvedLabels = ['Caffeine', 'Dimethyl sulfone', 'Mannitol', 'Erythritol', 'Inositol', 'Sorbitol', 'Xylitol', 'Acetylfentanyl', 'Carfentanil', 'Despropionyl fentanyl', 'Fentanyl', 'Furanylfentanyl', 'Parafluorofentanyl', 'Remifentanil'];
    if (!approvedLabels.includes(label)) { return { hasTraceXylazine: false, traceXylazineLabel: null } };
    const xylazinePredictionClassification = xylazinePrediction;
    const passingXylazines = [];
    xylazinePredictionClassification.forEach((e, i) => {
      if (e >= 0.15) {
        const indexML = mlOutput.findIndex(row => row[0] === xylazineLabels[i]);
        const values = [mlOutput[indexML][2], mlOutput[indexML][3]];
        const max = Math.max(...values);
        const min = Math.min(...values);
        const difference = max / min;
        const mean = values.reduce((sum, value) => sum + value, 0) / values.length;
        const variance = values.reduce((sum, value) => sum + Math.pow(value - mean, 2), 0) / values.length;
        const standardDeviation = Math.sqrt(variance);
        let threshold = e >= 0.9 ? 0.0075 : 0.02;
        if (min >= threshold) {
          passingXylazines.push({ label: xylazineLabels[i], traceMLValue: e, standardDeviation, difference });
        }
      }
    });
    if (passingXylazines.length > 0) {
      return { hasTraceXylazine: true, traceXylazineLabel: 'Xylazine' }
    }
    return { hasTraceXylazine: false, traceXylazineLabel: null };
  }

  const updateTraceResults = (newMap) => {
    if (newMap.length === 0) {
      setTraceMap([]);
      return;
    }
    setTraceMap(draft => {
      if (shouldReprocessTraceMap) {
        draft.splice(0, draft.length);
      }
      let startIndex = shouldReprocessTraceMap ? 0 : draft.length;
      for (let i = startIndex; i < newMap.length; i++) {
        let z = newMap[i].z;
        const { label, processedSpectrum, result, mlOutput, fentanylPrediction, xylazinePrediction, benzodiazepinePrediction, hydromorphonePrediction } = newMap[i];

        let { hasTraceFentanyl, traceFentanylLabel } = detectTraceFentanyl(label, processedSpectrum, result, fentanylPrediction, mlOutput);
        let { hasTraceXylazine, traceXylazineLabel } = detectTraceXylazine(label, processedSpectrum, result, mlOutput, xylazinePrediction);
        let hasTraceHydromorphone = detectTraceHydromorphone(label, processedSpectrum, result, hydromorphonePrediction);
        let { hasTraceBenzodiazepine, traceBenzodiazepineLabel } = detectTraceBenzodiazepine(label, processedSpectrum, result, benzodiazepinePrediction, mlOutput);

        if (hasTraceFentanyl) { z += 0.1; draft.push({ ...newMap[i], z: z, label: traceFentanylLabel }); }
        if (hasTraceHydromorphone) { z += 0.1; draft.push({ ...newMap[i], z: z, label: 'Hydromorphone' }); }
        if (hasTraceBenzodiazepine) { z += 0.1; draft.push({ ...newMap[i], z: z, label: traceBenzodiazepineLabel }); }
        if (hasTraceXylazine) { z += 0.1; draft.push({ ...newMap[i], z: z, label: traceXylazineLabel }); }
        if (!hasTraceFentanyl && !hasTraceHydromorphone && !hasTraceBenzodiazepine && !hasTraceXylazine) { draft.push({}); }
      }

      setResultsTableData(updateTable(newMap, cloneDeep(draft)));
    });

    if (shouldReprocessTraceMap) {
      setShouldReprocessTraceMap(false);
    }
  }

  const updateTable = (currentMap, currentTraceMap) => {
    const signalPoints = currentMap.filter(point => point?.label !== 'No Signal');
    const topPoint = { indexML: 200, mapIndex: -Infinity, mlOutput: null };
    for (let i = 0; i < signalPoints.length; i++) {
      const rankings = signalPoints[i].mlOutput.map(e => e[0]);
      const indexML = rankings.findIndex(name => name.toLowerCase().includes('fent'));
      if (indexML < topPoint.indexML) {
        topPoint.indexML = indexML;
        topPoint.mapIndex = signalPoints[i].index;
        topPoint.mlOutput = signalPoints[i].mlOutput;
      }
    }

    let substanceStatsArray = [];
    let mlResultsSum = new Array(substanceArray.length).fill(0);
    let mapWithSignalOnly = currentMap.filter(e => e.label !== 'No Signal');
    const uniqueLabels = [...new Set(mapWithSignalOnly.map(e => e.label))];
    for (let i = 0; i < mapWithSignalOnly.length; i++) {
      let numOfClassifiable = 0;
      let pointMLResultsSum = new Array(substanceArray.length).fill(0);
      if (mapWithSignalOnly[i].label === 'Unknown') {
        numOfClassifiable += 1;
      }
      for (let j = 0; j < mapWithSignalOnly[i].result[0].length; j++) {
        if (mapWithSignalOnly[i].result[0][j] > substanceThresholds[substanceArray[j]] && uniqueLabels.includes(substanceArray[j])) {
          numOfClassifiable += 1;
        }
      }
      for (let j = 0; j < mapWithSignalOnly[i].result[0].length; j++) {
        if (mapWithSignalOnly[i].result[0][j] > substanceThresholds[substanceArray[j]] && uniqueLabels.includes(substanceArray[j])) {
          pointMLResultsSum[j] += (1 / numOfClassifiable);
        }
      }
      if (mapWithSignalOnly[i].label === 'Unknown') {
        pointMLResultsSum[substanceArray.length - 1] += (1 / numOfClassifiable);
      }
      mlResultsSum = mlResultsSum.map((e, i) => e + pointMLResultsSum[i]);
    }
    const uniqueTraceLabels = [...new Set(currentTraceMap.map(e => e.label).filter(Boolean))];
    const traceResults = uniqueTraceLabels.map(label => {
      let labelIndex = substanceArray.findIndex(e => e === label);
      let labelPoints = currentTraceMap.filter(point => point.label === label);
      let pointCount = labelPoints.length;
      let indicesToAverage;

      if (label === 'Fentanyl') {
        indicesToAverage = substanceArray.reduce((acc, curr, idx) =>
          ['Parafluorofentanyl', 'Fentanyl', 'Furanylfentanyl', 'Acetylfentanyl'].includes(curr) ? [...acc, idx] : acc, []);
      } else {
        indicesToAverage = [labelIndex];
      }
      let max = 0;
      let datapointsForStats = [];
      let labelMLOutputAverage = labelPoints.reduce((totalSum, currentPoint) => {
        totalSum += indicesToAverage.reduce((pointSum, currentResultIndex) => {
          datapointsForStats.push(currentPoint.result[0][currentResultIndex]);
          max = Math.max(max, Math.max(currentPoint.result[1][currentResultIndex], currentPoint.result[2][currentResultIndex]))
          pointSum += Math.max(currentPoint.result[1][currentResultIndex], currentPoint.result[2][currentResultIndex]);
          return pointSum;
        }, 0) / indicesToAverage.length;
        return totalSum;
      }, 0) / pointCount;

      return {
        traceLabel: label,
        indexInSubstanceArray: labelIndex,
        numberOfTracePoints: pointCount,
        estimatedPercent: labelMLOutputAverage
      };
    });
    let totalTracePercent = traceResults.reduce((acc, curr) => (acc += curr.estimatedPercent), 0);

    for (let i = 0; i < substanceArray.length; i++) {
      let percent = Math.round((((mlResultsSum[i] / mapWithSignalOnly.length) * (1 - totalTracePercent) * 100) + Number.EPSILON) * 100) / 100;
      let tracePercent = Math.round((((traceResults.find(r => r.indexInSubstanceArray === i)?.estimatedPercent || 0) * 100) + Number.EPSILON) * 100) / 100;
      let totalPercent = Math.round((percent + tracePercent + Number.EPSILON) * 100) / 100;
      if (totalPercent > 0) {
        substanceStatsArray.push({
          key: substanceArray[i],
          substance: substanceArray[i],
          percentComposition: totalPercent,
          tracePercentComposition: tracePercent
        });
      }
    }
    return substanceStatsArray;
  }

  const connectButtonCallback = async () => {
    try {
      if (bluetoothState === 'Connected') {
        await disconnectBluetoothDevice(device);
        setBluetoothState('Disconnected');
      } else {
        const [connectedDevice, bluetoothCharacteristic, wifiCharacteristic] = await connectButtonHandler(onBluetoothDisconnect, message, key);
        device = connectedDevice;
        characteristic = bluetoothCharacteristic;
        setWifiCharacteristic(wifiCharacteristic);
        let status = await subscribeToCharacteristic(characteristic, handleCharacteristicValueChanged);
        setBluetoothState(status);
        if (status === 'Connected') {
          message.success({ content: 'Connected to Series One', key, duration: 3 });
          let eepromData = await readEEPROM(characteristic);
          let eepromDataBuffer = arrayBufferToBufferCycle(eepromData.buffer);
          let deviceInfo = { ...parseEEPROM(eepromDataBuffer) }
          if (deviceInfo.device_firmware_version) {
            await updateDeviceFirmwareVersionInDB(deviceInfo.device_id, deviceInfo.device_firmware_version);
          }
          await updateDeviceNetworkStatusInDB(deviceInfo?.device_id, deviceInfo?.device_network_status);
          await updateDeviceCameraStatusInDB(deviceInfo?.device_id, deviceInfo?.device_camera_status);
          let storedDeviceInfo = await fetchDeviceInfo(deviceInfo.device_id);
          if (storedDeviceInfo.center_calibration) {
            deviceInfo.center_calibration = storedDeviceInfo.center_calibration;
            setCenter(prevCenter => {
              let newCenter = { ...prevCenter };
              newCenter.x = storedDeviceInfo.center_calibration.x;
              newCenter.y = storedDeviceInfo.center_calibration.y;
              return newCenter
            });
          }
          connectedDeviceInfoRef.current = deviceInfo;
          setDeviceInfo(deviceInfo);
          await writeToDevice(characteristic, [
            ['setStageAccelerationAndSpeedXY', [120, 18e6]],
            ['setStageAccelerationAndSpeedZ', [120, 42e6]],
            ['executeCommands']
          ]);
          return true
        } else {
          message.warning({ content: 'Failed to connect.', key, duration: 3 });
          return false;
        }
      }
    } catch (error) {
      message.warning({ content: 'Failed to connect.', key, duration: 3 });
    }
  }

  const onBluetoothDisconnect = () => {
    setBluetoothState('Disconnected');
    connectedDeviceInfoRef.current = null;
    setDeviceInfo(null);
  }
  const settingsToggleHandler = _ => { setSettings(!settings) };
  const deviceInfoToggleHandler = async () => {
    if (characteristic) {
      setShowDeviceInfo(!showDeviceInfo)
    } else {
      try {
        await connectButtonCallback();
        if (bluetoothState === 'Connected') {
          setShowDeviceInfo(!showDeviceInfo);
        } else {
          message.warning({ content: 'Failed to connect.', key, duration: 3 });
        }
      } catch (reason) {
        console.error(reason);
      }
    }
  };

  const scanButtonHandler = async () => {
    let path = currentPath.map((point, i) => [Math.round(point[0] * 1e6), Math.round(point[1] * 1e6), Math.round(point[2] * 1e6)]);

    let currentStep = cloneDeep(step);
    currentStep.text[1] = 'Initializing scan...';
    currentStep.subTitle[1] = '';
    setStep(currentStep);
    let initCommands = [];
    let laserWarmupTime = 15000;
    if (deviceInfo.device_camera_status && deviceInfo.device_network_status) {
      laserWarmupTime = 28000;
      initCommands.push(
        ['move', [12.5e6, 45e6, 0e6]],
        ['captureImage', [imageFilename]],
      );
    }
    initCommands.push(
      ['move', [12.5e6, 24e6, 15e6]],
      ['setLaserPower', 450],
      ['laserON'],
      ['executeCommands']
    );
    await writeToDevice(characteristic, initCommands);
    warmupDelayTimeoutID.current = setTimeout(async () => {
      let commands = [];
      if (!calibrationInfo.calibrate) {
        if (minLaserPower === maxLaserPower && minIntegrationTime === maxIntegrationTime) {
          commands.push(
            ['setIntegrationTime', maxIntegrationTime],
            ['setLaserPower', maxLaserPower],
            ['sendCoordinates', path],
            ['scanCoordinates']
          );
        } else {
          let laserPowers = Array.from({ length: currentPath.length }).map((e, i, _, step = (maxLaserPower - minLaserPower) / (currentPath.length - 1)) => Math.round(minLaserPower + i * step));
          let integrationTimes = Array.from({ length: currentPath.length }).map((e, i, _, step = (maxIntegrationTime - minIntegrationTime) / (currentPath.length - 1)) => Math.round(minIntegrationTime + i * step));
          for (let i = 0; i < path.length; i++) {
            commands.push(
              ['setIntegrationTime', integrationTimes[i]],
              ['setLaserPower', laserPowers[i]],
              ['sendCoordinates', path[i]],
              ['scanCoordinates']
            );
          }
        }
        commands.push(['executeCommands']);
        await writeToDevice(characteristic, commands);
      } else {
        let currentStep = cloneDeep(step);
        currentStep.text[1] = 'Detecting...';
        setStep(currentStep);
        setCalibrationInfo(prevCalibrationInfo => {
          let newCalibrationInfo = cloneDeep(prevCalibrationInfo);
          newCalibrationInfo.firstXYCalibration.active = true;
          return newCalibrationInfo;
        });
        let params = calibrationInfo.firstXYCalibration.settings;
        let points = getExpandingSquareSearchPoints(center, params.scanWidth, scanHeight, params.pointsPerSide, params.numLevels, 1);
        let path = points.map((point, i) => [Math.round(point[0] * 1e6), Math.round(point[1] * 1e6), Math.round(point[2] * 1e6)]);
        commands.push(
          ['setLaserPower', params.laserPower],
          ['setIntegrationTime', params.integrationTime],
          ['sendCoordinates', path],
          ['scanCoordinates'],
          ['executeCommands']
        );
        await writeToDevice(characteristic, commands);
      }
    }, laserWarmupTime);
  }

  let initialDisconnectedStepState = {
    text: ['Connect', 'Start Scan', 'Results'],
    subTitle: ['', '', ''],
    icon: [<LinkOutlined />, <PlayCircleOutlined />, <CopyOutlined />],
    disabled: [false, true, true]
  };

  let initialStepState = {
    text: ['Load Sample', 'Start Scan', 'Results'],
    subTitle: ['', '', ''],
    icon: [<VerticalAlignBottomOutlined />, <PlayCircleOutlined />, <CopyOutlined />],
    disabled: [false, false, true]
  };

  const stepsClickHandler = async e => {
    if (step.text[0] === 'Connect') {
      await connectButtonCallback().then((res) => {
        if (res) {
          setStep(initialStepState);
          setCurrentStep(0);
          writeToDevice(characteristic, [
            ['laserOFF'],
            ['move', [12.5e6, 50e6, 20e6]],
            ['executeCommands']
          ]);
        }
      }).catch(() => { });
    } else {
      if (e === 1 && !scanning && currentStep === 0) {
        setIsOperatorModalOpen(true);
      } else if (e === 1 && !scanning && currentStep === 2 && step.text[1] === 'Upload') {
        sendMap();
        let currentStep = cloneDeep(step);
        currentStep.text[1] = 'Uploading';
        currentStep.icon[1] = <LoadingOutlined />;
        setStep(currentStep);
      } else if (e === 1 && !scanning && currentStep === 2 && step.text[1] === 'Clear') {
        if (bluetoothState === 'Disconnected') {
          setStep(initialDisconnectedStepState);
          setCurrentStep(1);
          clear();
          setScanWidth(5);
          setPointsPerSide(scanSettings[scanType]);
        } else {
          setStep(initialStepState);
          setCurrentStep(0);
          clear();
          setScanWidth(5);
          setPointsPerSide(scanSettings[scanType]);
        }
      } else if (e === 0 && scanning && currentStep === 1) {
        setScanning(false);
        setStep(initialStepState);
        setCurrentStep(0);
      } else if (e === 0 && !scanning) {
        setStep(initialStepState);
        setCurrentStep(0);
        clear();
        setScanWidth(5);
        setPointsPerSide(scanSettings[scanType]);
        setCenter({ x: deviceInfo.center_calibration.x, y: deviceInfo.center_calibration.y, z: defaultCenter.z });
      }
    }
  }

  const stepUploaded = _ => {
    let currentStep = cloneDeep(step);
    currentStep.text = ['Clear', 'Uploaded', 'Results'];
    currentStep.icon = [<UndoOutlined />, <CheckOutlined />, <CopyOutlined />];
    currentStep.subTitle[1] = '';
    currentStep.disabled = [false, true, false];
    setStep(currentStep);
  }

  const stepViewStored = _ => {
    let currentStep = cloneDeep(step);
    currentStep.text[0] = 'Load Sample';
    currentStep.icon[0] = <VerticalAlignBottomOutlined />;
    currentStep.text[1] = 'Clear';
    currentStep.icon[1] = <UndoOutlined />;
    currentStep.subTitle[1] = '';
    currentStep.disabled = [true, false, false];
    setStep(currentStep);
    setCurrentStep(2);
  }

  const stepScanHandler = _ => {
    if (step.text[1] !== 'Scanning') {
      let currentStep = cloneDeep(step);
      currentStep.text[1] = 'Scanning';
      currentStep.icon[1] = <LoadingOutlined />;
      currentStep.subTitle[1] = `${0} % `;
      setStep(currentStep);
    }
    else if (numPoints === map.length) {
      setScanning(false);
    }
    else if (scanning) {
      let newStep = cloneDeep(step);
      newStep.subTitle[1] = `${Math.round((map.length / numPoints) * 100)}% `;
      setStep(newStep);
    }
  }

  const stepStartScan = _ => {
    setScanning(true);
    if (calibrationInfo.calibrate) {
      setCalibrationInfo(prevCalibrationInfo => {
        let newCalibrationInfo = cloneDeep(prevCalibrationInfo);
        newCalibrationInfo.calibrating = true;
        return newCalibrationInfo;
      });
    }
    scanTimestamp = Date.now();
    let newStep = cloneDeep(step);
    newStep.text = ['End Scan', 'Preparing', 'Results'];
    newStep.icon = [<CloseOutlined />, <SyncOutlined spin />, <CopyOutlined />];
    setStep(newStep);
    setCurrentStep(1);
  }

  const stepEndScan = _ => {
    sendMap();
    let newStep = cloneDeep(step);
    newStep.text = ['End Scan', 'Uploading', 'Results']
    newStep.icon = [<CloseOutlined />, <LoadingOutlined />, <CopyOutlined />];
    newStep.subTitle[1] = '';
    newStep.disabled = [true, true, false];
    setStep(newStep);
    setCurrentStep(2);
  }

  const [step, setStep] = useState(initialDisconnectedStepState);
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    if (wifiConfigModalOpen) {
      // setDeviceInfo(connectedDeviceInfoRef.current);
      // TODO: Determine why center is set here.
      // if (connectedDeviceInfoRef.current.center_calibration) {
      //   setCenter(prevCenter => {
      //     let newCenter = { ...prevCenter };
      //     newCenter.x = connectedDeviceInfoRef.current.center_calibration.x;
      //     newCenter.y = connectedDeviceInfoRef.current.center_calibration.y;
      //     return newCenter
      //   });
      // }
    }
  }, [wifiConfigModalOpen]);

  return (
    <Layout style={{ maxWidth: '1000px', margin: '0 auto', position: 'relative', background: 'white' }}>
      <Content>
        <img src={logoImage} height={"36px"} alt="" style={{ position: 'absolute', top: '30px', left: '6%' }} />
        {notificationContextHolder}
        {isInspectionModalOpen ? <InspectionModal map={map} /> : null}
        {isTestInfoModalOpen ? <TestInfoModal currentSerial={currentSerial} /> : null}
        <WifiConfigModal
          isModalOpen={wifiConfigModalOpen}
          setWifiConfigModalOpen={setWifiConfigModalOpen}
          bluetoothState={bluetoothState}
        />
        <OperatorModal stepStartScan={stepStartScan} />
        <Modal
          open={showDeviceInfo}
          title='Device Info'
          footer={[]}
          closable={true}
          onCancel={deviceInfoToggleHandler}
          width='80%'
        >
          <DeviceInfoTable />
        </Modal>
        <SubstanceInfoModal />
        <ActionButtons
          signOut={signOut}
          settingsToggleHandler={settingsToggleHandler}
          deviceInfoToggleHandler={deviceInfoToggleHandler}
          setWifiConfigModalOpen={setWifiConfigModalOpen}
          wifiConfigModalOpen={wifiConfigModalOpen}
          bluetoothState={bluetoothState}
          currentSerial={currentSerial}
        />
        <div style={{ width: '100%', height: '90px' }}></div>
        {
          settings ?
            <AdditionalSettings /> : null
        }
        <Row justify="center">
          <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
            <Radio.Group value={scanType} disabled={map.length} >
              <Radio.Button value="quick" onChange={() => { setScanType('quick'); setPointsPerSide(scanSettings.quick); setScanWidth(5) }} style={{ width: 120, height: 'auto', textAlign: 'center', lineHeight: 1, padding: '8px 0' }}>⚡️&nbsp;&nbsp;Quick<br /><span style={{ fontSize: '9px' }}>4 min</span></Radio.Button>
              <Radio.Button value="standard" onChange={() => { setScanType('standard'); setPointsPerSide(scanSettings.standard); setScanWidth(5) }} style={{ width: 120, height: 'auto', textAlign: 'center', lineHeight: 1, padding: '8px 0' }}>⚖️&nbsp;&nbsp;Standard<br /><span style={{ fontSize: '9px' }}>8 min</span></Radio.Button>
              <Radio.Button value="detailed" onChange={() => { setScanType('detailed'); setPointsPerSide(scanSettings.detailed); setScanWidth(5) }} style={{ width: 120, height: 'auto', textAlign: 'center', lineHeight: 1, padding: '8px 0' }}>🔬&nbsp;&nbsp;Detailed<br /><span style={{ fontSize: '9px' }}>14 min</span></Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
        <div className={classes.mapContainer}>
          <PrimaryCharts
            currentSerial={currentSerial}
            bluetoothState={bluetoothState}
          />
        </div>
        <ProgressSteps
          currentStep={currentStep}
          stepsClickHandler={stepsClickHandler}
          step={step}
        />
        {
          settings ?
            <>
              <SettingsButtons />
              <ResultsTable resultsTableData={resultsTableData} />
            </>
            : null
        }
        <ClientUploads
          mapViewButtonHandler={mapViewButtonHandler}
          setStoredMaps={setStoredMaps}
          latestUpload={latestUpload}
          setCurrentSerial={setCurrentSerial}
          setCurrentMapID={setCurrentMapID}
          resultsTableData={resultsTableData}
          footerRef={footerRef}
        />
        <Row justify="center" align="middle">
          <Col span={21}>
            <RiskAwareness />
          </Col>
        </Row>
        <Row justify="center" align="middle">
          <Col span={21}>
            <MassSpectrometryContainer />
          </Col>
        </Row>
        {isAdmin && username === 'admin' ?
          <Row justify="center" align="middle">
            <Col span={21}>
              <SubstanceManagement />
            </Col>
          </Row> : null
        }
      </Content >
      <Footer ref={footerRef} style={{ marginTop: '30px', textAlign: 'center', backgroundColor: 'white' }}>
        Copyright © {new Date().getFullYear()} Scatr Inc. All rights reserved. Oct 28 Version
      </Footer>
    </Layout >
  );
}

export default App;
