import React, { useState, useEffect, useRef } from "react";
import { Button, Descriptions, Progress, Table } from "antd"
import { CloseOutlined } from '@ant-design/icons';
import { Rnd } from "react-rnd";
import { useAtom } from "jotai";
import { isTestInfoModalOpenState, isImageModalOpenState, imageInfoSerialState, allScanDataState, allSubstancesDataState } from "../stateManagement/commonState";
import useResizeObserver from '@react-hook/resize-observer';

const TestInfoModal = ({ currentSerial }) => {
    const [isTestInfoModalOpen, setIsTestInfoModalOpen] = useAtom(isTestInfoModalOpenState);
    const [, setIsImageModalOpen] = useAtom(isImageModalOpenState);
    const [testInfoWindowState, setTestInfoWindowState] = useState({
        width: window.innerWidth > 1000 ? 700 : window.innerWidth * 0.55,
        height: '300px',
        x: window.innerWidth > 1000 ? window.innerWidth / 2 - 212 : window.innerWidth - (window.innerWidth * 0.6) - 12,
        y: 16,
    });
    const [allSubstancesData] = useAtom(allSubstancesDataState);
    const [imageInfoSerial, setImageInfoSerial] = useAtom(imageInfoSerialState);
    const [allScanData] = useAtom(allScanDataState);
    const [record, setRecord] = useState(null);
    const [resultsTableData, setResultsTableData] = useState([]);
    const descriptionsComponentRef = useRef(null);

    useEffect(() => {
        setImageInfoSerial(currentSerial);
    }, [currentSerial]);

    useEffect(() => {
        if (allScanData.length && imageInfoSerial) {
            const record = { ...allScanData.find(e => e.serial === imageInfoSerial) };
            setRecord(record);
        }
    }, [allScanData, imageInfoSerial]);

    useEffect(() => {
        const resultsTableData = record?.results ? Object.entries(record.results).map(([key, value]) => {
            return {
                key: key,
                substance: key,
                percentComposition: Math.round(value * 100 * 100) / 100,
                tracePercentComposition: record.trace_results?.[key] ? Math.round(record.trace_results[key] * 100 * 100) / 100 : null
            }
        }) : [];
        setResultsTableData(resultsTableData);

    }, [record]);

    useResizeObserver(descriptionsComponentRef, (entry) => {
        setTestInfoWindowState(prevState => ({
            ...prevState,
            height: entry.contentRect.height
        }));

    });

    const resultTableColumns = [
        {
            title: 'Substance',
            dataIndex: 'substance',
            key: 'substance',
            width: '50%',
            render: (substance) => (<>{substance}</>),
            // render: (substance) => (<>{substance} {allSubstancesData?.[substance]?.info?.overview?.length ? <InfoCircleOutlined style={{ float: 'right' }} /> : null}</>),
            // onCell: record => { return { onClick: _ => { if (allSubstancesData?.[record.substance]?.info?.overview?.length) { setInfoModalSubstance(record.substance); setIsSubstanceInfoModalOpen(true); } } } },
        },
        {
            title: 'Percent Composition',
            dataIndex: 'percentComposition',
            key: 'percentComposition',
            width: '50%',
            defaultSortOrder: 'descend',
            sortDirections: ['descend', 'ascend', 'descend'],
            sorter: (a, b) => a.percentComposition - b.percentComposition,
            render: (percentComposition, results) => (
                <>
                    <Progress strokeColor={'#91caff'} percent={percentComposition} success={{ percent: results.tracePercentComposition ? (percentComposition - results.tracePercentComposition) : percentComposition, strokeColor: '#1677ff' }} style={{ padding: '0 24px 0 0' }} status="normal" />
                </>
            ),
        }
    ];

    function CustomCell(props) {
        const shouldInteract = allSubstancesData?.[props.children?.[1]?.props?.children?.[0]]?.info?.overview?.length;
        if (shouldInteract) {
            return (
                <td {...props} style={{ cursor: 'pointer' }} />
            );
        }
        return <td {...props} />;
    }

    return (
        <>
            {isTestInfoModalOpen && (
                <div style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    zIndex: 100,
                    pointerEvents: 'none',
                }}
                >
                    <Rnd
                        style={{
                            backgroundColor: '#fafafa',
                            pointerEvents: 'auto',
                            borderRadius: '8px',
                            position: 'relative',
                            boxShadow: `0 1px 2px rgba(0,0,0,0.08), 
                                        0 2px 4px rgba(0,0,0,0.08), 
                                        0 4px 8px rgba(0,0,0,0.08), 
                                        0 8px 16px rgba(0,0,0,0.08),
                                        0 16px 32px rgba(0,0,0,0.08), 
                                        0 32px 64px rgba(0,0,0,0.08)`
                        }}
                        size={{ width: testInfoWindowState.width, height: testInfoWindowState.height }}
                        position={{ x: testInfoWindowState.x, y: testInfoWindowState.y }}
                        enableResizing={{ top:false, right:true, bottom:false, left:true, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
                        onDragStop={(e, d) => {
                            setTestInfoWindowState(prev => ({ ...prev, x: d.x, y: d.y }))
                        }}
                        onResize={(e, direction, ref, delta, position) => {
                            setTestInfoWindowState(prev => ({
                                ...prev,
                                width: ref.style.width,
                                height: ref.style.height,
                                ...position
                            }));
                        }}
                    // lockAspectRatio={true}
                    minWidth={450}
                    maxWidth={875}
                    >
                        <Button
                            type="primary"
                            danger
                            shape="circle"
                            size="small"
                            icon={<CloseOutlined style={{ fontSize: '9px' }} />}
                            onClick={() => {
                                setIsTestInfoModalOpen(false);
                                setIsImageModalOpen(false);
                                setTestInfoWindowState({
                                    width: window.innerWidth > 1000 ? 700 : window.innerWidth * 0.55,
                                    height: '300px',
                                    x: window.innerWidth > 1000 ? window.innerWidth / 2 - 212 : window.innerWidth - (window.innerWidth * 0.6) - 12,
                                    y: 16,
                                });
                            }}
                            style={{
                                position: 'fixed',
                                top: '13px',
                                right: '21px',
                                minWidth: '15px',
                                width: '15px',
                                height: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                zIndex: 103,
                            }}
                            onMouseMove={(e) => { e.stopPropagation(); }}
                        />
                        <div ref={descriptionsComponentRef}>
                            <Descriptions bordered={true} column={1} size="small">
                                <Descriptions.Item label="Serial" labelStyle={{ width: '30px' }}><div>
                                    {record && record.serial}
                                </div></Descriptions.Item>
                                {record?.expected_substances ?
                                    <Descriptions.Item label="Expected">
                                        {record.expected_substances}
                                    </Descriptions.Item> : null
                                }
                                <Descriptions.Item label="Results" contentStyle={{ padding: 0 }}>
                                    <Table
                                        style={{
                                            width: '100%',
                                        }}
                                        size="small"
                                        dataSource={resultsTableData}
                                        columns={resultTableColumns}
                                        pagination={{ position: ['bottomCenter'], pageSize: 5, size: 'small' }}
                                        components={{
                                            body: {
                                                cell: CustomCell
                                            }
                                        }}
                                    />
                                </Descriptions.Item>
                                {record?.notes ?
                                    <Descriptions.Item label="Notes">
                                        {record.notes}
                                    </Descriptions.Item> : null
                                }
                            </Descriptions>
                        </div>
                    </Rnd>
                </div>
            )}
        </>
    )
}

export default TestInfoModal;