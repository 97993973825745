import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Table, Tag, Descriptions, Select, Input, Button, AutoComplete, Modal, Space, message, Typography, Tour } from 'antd';
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import classes from './MassSpectrometry.module.css';
import { useAtom } from 'jotai';
import { userNameState, isAdminState } from '../../stateManagement/commonState';
import ResultsTable from './ResultsTable';
import { massSpectrometryTableDataState, massSpectrometryResultsDataState, clientCodesState, massSpectrometryTableExpandedRowState, currentMassSpecIDState, massSpecTourOpenState, expectedSubstancesState } from "../../stateManagement/commonState";
import { isUUID, hasKey } from "../../utils/helpers";
import { fetchAllMassSpecData, fetchMassSpecDataByUser, deleteMassSpecRow, saveMassSpecRow } from "../../utils/dbHelpers";
import dayjs from 'dayjs';
const { Option } = Select;
const { TextArea } = Input;
const { Text } = Typography;
const { confirm } = Modal;

const statuses = { 'Pending Transit': 'geekblue', 'In Progress': 'orange', 'Analyzed': 'green', 'Exception': 'red' };

function MassSpectrometryTable({ massSpecDateRange, filteredInfo, setFilteredInfo }) {
    const [username] = useAtom(userNameState);
    const [isAdmin] = useAtom(isAdminState);
    const [dataLoading, setDataLoading] = useState(false);
    const [massSpectrometryTableData, setMassSpectrometryTableData] = useAtom(massSpectrometryTableDataState);
    const [massSpectrometryResultsData, setMassSpectrometryResultsData] = useAtom(massSpectrometryResultsDataState);
    const [clientCodes] = useAtom(clientCodesState);
    const [massSpectrometryTableExpandedRow, setMassSpectrometryTableExpandedRow] = useAtom(massSpectrometryTableExpandedRowState);
    const [resultsAreValid, setResultsAreValid] = useState(true);
    const searchInput = useRef(null);
    const massSpectrometryTableRef = useRef(null);
    const tourRef = useRef(null);
    const [currentMassSpecID] = useAtom(currentMassSpecIDState);
    const [massSpecTourOpen, setMassSpecTourOpen] = useAtom(massSpecTourOpenState);
    const [isEditing, setIsEditing] = useState(false);
    const recordRef = useRef(null);
    const [expectedSubstances] = useAtom(expectedSubstancesState);

    useEffect(() => {
        if (username && massSpecDateRange) {
            const fetchMassSpecData = async () => {
                const response = isAdmin ? await fetchAllMassSpecData(massSpecDateRange) : await fetchMassSpecDataByUser(massSpecDateRange, username);
                const tableData = response.map(e => ({ key: e.id, ...e }));
                let newMassSpectrometryResultsData = {};
                response.forEach(e => {
                    newMassSpectrometryResultsData[e.id] = e.results;
                });
                setMassSpectrometryResultsData(newMassSpectrometryResultsData);
                setMassSpectrometryTableData(tableData);
            };
            fetchMassSpecData();
        }
    }, [username, isAdmin, massSpecDateRange]);

    const massSpecTourSteps = [
        {
            title: 'Sample ID',
            description: 'Please write this code on the sample container.',
            placement: 'right',
            target: () => tourRef.current,
            nextButtonProps: {
                children: "Done"
            }
        },
    ];

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };

    const handleReset = clearFilters => {
        clearFilters();
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) => record?.[dataIndex]?.toString()?.toLowerCase()?.includes(value?.toLowerCase()),
        onFilterDropdownOpenChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select(), 100);
            }
        }
    });

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 130,
            align: 'center',
            render: id => <Text ref={id === currentMassSpecID ? tourRef : null} >{id.slice(0, 8).toUpperCase()}</Text>,
            ...getColumnSearchProps('id'),
            onFilter: (value, record) => record?.['id']?.toString()?.toLowerCase()?.slice(0, 8)?.includes(value.toLowerCase()),
            filteredValue: filteredInfo.id || null,
        },
        {
            title: 'Date',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 100,
            align: 'center',
            render: created_at => (created_at ? dayjs(created_at).format('MMM D, YYYY') : null)
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 125,
            align: 'center',
            render: status => (
                <Tag
                    key={status}
                    color={{ 'Pending Transit': 'geekblue', 'In Progress': 'orange', 'Analyzed': 'green', 'Exception': 'red' }[status]}
                    style={{ margin: 0 }}>
                    {status}
                </Tag>
            ),
            filters: Object.keys(statuses).map(status => ({ text: status, value: status })),
            onFilter: (value, record) => record.status === value,
            filteredValue: filteredInfo.status || null,
        },
        {
            title: 'Expected Substance(s)',
            dataIndex: 'expected_substances',
            key: 'expected_substances',
            width: 150,
            align: 'center',
            render: expected_substances => (
                <>
                    {expected_substances?.map(substance => (
                        <Tag color="blue" key={substance} style={{ margin: 0 }}>
                            {substance}
                        </Tag>
                    ))}
                </>
            ),
            filters: [...expectedSubstances.sort().map(e => ({ text: e, value: e })), { text: 'Other', value: 'Other' }],
            onFilter: (value, record) => record.expected_substances?.includes(value),
            filteredValue: filteredInfo.expected_substances || null,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            width: 240,
            align: 'center',
            render: text => (text ? (<div style={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}>{text}</div>) : null),
            ...getColumnSearchProps('description'),
            filteredValue: filteredInfo.description || null,
        },
        {
            title: 'Client Code',
            dataIndex: 'client_code',
            key: 'client_code',
            width: 130,
            align: 'center',
            render: text => (text ? (<div style={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            }}>{text}</div>) : null),
            ...getColumnSearchProps('client_code'),
            filteredValue: filteredInfo.client_code || null,
        },
        ...(isAdmin
            ? [
                {
                    title: 'Username',
                    dataIndex: 'username',
                    key: 'username',
                    width: 180,
                    align: 'center',
                    render: text => (text ? (<div style={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}>{text}</div>) : null),
                    ...getColumnSearchProps('username'),
                    filteredValue: filteredInfo.username || null,
                },
                {
                    title: 'Lab Comments',
                    dataIndex: 'laboratory_comments',
                    key: 'laboratory_comments',
                    width: 180,
                    align: 'center',
                    render: text => (text ? (<div style={{
                        width: '100%',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}>{text}</div>) : null),
                    ...getColumnSearchProps('laboratory_comments'),
                    filteredValue: filteredInfo.laboratory_comments || null,
                },
            ]
            : []),
        {
            title: '',
            dataIndex: '',
            key: '',
            width: 0,
            fixed: 'right',
        }
    ];

    const confirmRecordDelete = (record) => {
        confirm({
            width: 600,
            title: 'Do you want to delete this record?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            content: (
                <div>
                    <br />
                    <table style={{ fontWeight: 'normal' }}>
                        <tbody>
                            <tr><td style={{ padding: '0 10px' }}>{'ID'}</td><td><b>{record.id.slice(0, 8).toUpperCase()}</b></td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Date'}</td><td>{record.created_at.slice(0, 10)}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Description'}</td><td>{record.description}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Expected Substance(s)'}</td><td>{record.expected_substances?.join(', ')}</td></tr>
                            <tr><td style={{ padding: '0 10px' }}>{'Client Code'}</td><td>{record.client_code ? record.client_code : 'N/A'}</td></tr>
                        </tbody>
                    </table>
                </div>
            ),
            onOk: async () => {
                setMassSpectrometryTableExpandedRow(() => {
                    setIsEditing(false);
                    recordRef.current = null;
                    return [];
                });
                let deletedRow = await deleteMassSpecRow(record.id);
                let deletedRowIndex = massSpectrometryTableData.findIndex(row => row.id === deletedRow.id);
                let updatedTableData = [...massSpectrometryTableData];
                updatedTableData.splice(deletedRowIndex, 1);
                setMassSpectrometryTableData(updatedTableData);
            }
        });
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setFilteredInfo(filters);
    };

    const autoSaveMassSpecData = async (record) => {
        try {
            const index = massSpectrometryTableData.findIndex(row => row.id === record.id);
            await saveMassSpecRow({ ...massSpectrometryTableData[index], results: massSpectrometryResultsData[record.id] });
            message.success(`Request info for ${record.id.slice(0, 8).toUpperCase()} auto saved.`);
        } catch {
            message.error(`Failed to save request info for ${record.id.slice(0, 8).toUpperCase()}.`);
        }
    }

    useEffect(() => {
        const nextRecord = massSpectrometryTableData.find(row => row.id === massSpectrometryTableExpandedRow[0]);
        if (isEditing && (recordRef?.current?.id !== nextRecord?.id)) {
            autoSaveMassSpecData(recordRef.current);
            setIsEditing(false);
        }
        recordRef.current = nextRecord;
    }, [massSpectrometryTableExpandedRow]);

    return (
        <>
            <Row justify="center" align="middle" style={{ marginTop: "6px" }}>
                <Col span={24} style={{ textAlign: "center" }} >
                    <Table
                        ref={massSpectrometryTableRef}
                        onChange={handleTableChange}
                        dataSource={massSpectrometryTableData}
                        columns={columns}
                        className={classes.massSpectrometryTable}
                        loading={dataLoading}
                        expandable={{
                            expandRowByClick: true,
                            expandIconColumnIndex: -1,
                            expandedRowKeys: massSpectrometryTableExpandedRow,
                            onExpand: (expanded, record) => {
                                setResultsAreValid(true);
                                setMassSpectrometryTableExpandedRow(expanded ? [record.key] : [])
                            },
                            expandedRowRender: record => {
                                if (record.id !== massSpectrometryTableExpandedRow[0]) {
                                    return null;
                                }
                                return (
                                    <Descriptions title="Analysis Details" bordered={true} column={1} size="small" className={classes.expandedTable}>
                                        <Descriptions.Item label="ID" labelStyle={{ width: '125px' }}><div>{record.id.slice(0, 8).toUpperCase()}</div></Descriptions.Item>
                                        <Descriptions.Item label="Date">{record.created_at.slice(0, 10)}</Descriptions.Item>
                                        <Descriptions.Item label="Status">
                                            {isAdmin && isEditing ?
                                                <Select
                                                    style={{ width: '100%' }}
                                                    // className={(record.expected_substances || []).length > 0 && !record.newExpectedSubstances ? classes.cellHasData : null}
                                                    value={record.status ? record.status : undefined}
                                                    onChange={e => {
                                                        setMassSpectrometryTableData(rows => {
                                                            const index = rows.findIndex(row => row.key === record.key && row.id === record.id);
                                                            rows[index].status = e;
                                                        })
                                                    }}
                                                >
                                                    {Object.keys(statuses).map(status => <Option key={status}>{status === 'Exception' ? 'Exception (add reason to comments)' : status}</Option>)}
                                                </Select> :
                                                <Tag color={statuses[record.status]} style={{ margin: 0 }}>
                                                    {record.status === 'Exception' ? 'Exception (see comments)' : record.status}
                                                </Tag>
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Expected Substance(s)">
                                            {
                                                isAdmin || record.status !== 'Pending Transit' || !isEditing ?
                                                    record.expected_substances?.map(substance => (
                                                        <Tag color="blue" key={substance} style={{ margin: 0 }}>
                                                            {substance}
                                                        </Tag>
                                                    )) :
                                                    <Select
                                                        mode="multiple"
                                                        allowClear
                                                        style={{ width: '100%' }}
                                                        // className={(record.expected_substances || []).length > 0 && !record.newExpectedSubstances ? classes.cellHasData : null}
                                                        placeholder="Add expected substance(s)"
                                                        value={record.expected_substances ? record.expected_substances : undefined}
                                                        onChange={e => {
                                                            setMassSpectrometryTableData(rows => {
                                                                const index = rows.findIndex(row => row.key === record.key && row.id === record.id);
                                                                rows[index].expected_substances = e;
                                                            })
                                                        }}
                                                    >
                                                        {expectedSubstances.map(substance => <Option key={substance}>{substance}</Option>)}
                                                        <Option key={'Other'}>{'Other (Please specify in description)'}</Option>
                                                    </Select>
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Description">
                                            {
                                                (!isAdmin && record.status !== 'Pending Transit') || (isAdmin && record.status === 'Pending Transit') || !isEditing ? record.description :
                                                    <TextArea
                                                        // className={record.notes && !record.newNotes ? classes.cellHasData : null}
                                                        autoSize
                                                        value={record.description}
                                                        onChange={e => {
                                                            setMassSpectrometryTableData(rows => {
                                                                const index = rows.findIndex(row => row.key === record.key && row.id === record.id);
                                                                rows[index].description = e.target.value;
                                                            })
                                                        }}
                                                    />
                                            }
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Client Code">
                                            {
                                                isAdmin || record.status !== 'Pending Transit' || !isEditing ? record.client_code :
                                                    <AutoComplete
                                                        style={{ width: '100%' }}
                                                        // className={record.client_code && !record.newClientCode ? classes.cellHasData : null}
                                                        options={clientCodes.map(e => ({ value: e.client_code })).filter(e => !isUUID(e.value))}
                                                        placeholder="Add client code"
                                                        onChange={(e) => {
                                                            setMassSpectrometryTableData(rows => {
                                                                const index = rows.findIndex(row => row.key === record.key && row.id === record.id);
                                                                rows[index].client_code = e;
                                                            })
                                                        }}
                                                        allowClear={true}
                                                        onClear={(e) => {
                                                            setMassSpectrometryTableData(rows => {
                                                                const index = rows.findIndex(row => row.key === record.key && row.id === record.id);
                                                                rows[index].client_code = null;
                                                            })
                                                        }}
                                                        value={record.client_code ? record.client_code : ``}
                                                        filterOption={(inputValue, option) =>
                                                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                        }
                                                    />
                                            }
                                        </Descriptions.Item>
                                        {
                                            (isAdmin && record.status !== 'Pending Transit') || (!isAdmin && ['Analyzed', 'Exception'].includes(record.status)) ?
                                                <>
                                                    {
                                                        (isAdmin && record.status !== 'Pending Transit') || (!isAdmin && (record.status === 'Analyzed' || record.status === 'Exception') && hasKey(record.results)) ?
                                                            <Descriptions.Item label="Mass Spectroscopy Results">
                                                                <ResultsTable parentRecord={record} setResultsAreValid={setResultsAreValid} results={massSpectrometryResultsData[record.id]} setResults={setMassSpectrometryResultsData} isEditing={isEditing} />
                                                            </Descriptions.Item> : null
                                                    }
                                                    <Descriptions.Item label="Laboratory Comments">
                                                        {
                                                            isAdmin && isEditing ?
                                                                <TextArea
                                                                    // className={record.notes && !record.newNotes ? classes.cellHasData : null}
                                                                    autoSize
                                                                    value={record.laboratory_comments}
                                                                    onChange={e => {
                                                                        setMassSpectrometryTableData(rows => {
                                                                            const index = rows.findIndex(row => row.key === record.key && row.id === record.id);
                                                                            rows[index].laboratory_comments = e.target.value;
                                                                        })
                                                                    }}
                                                                /> : record.laboratory_comments
                                                        }
                                                    </Descriptions.Item>
                                                </> : null
                                        }
                                        {
                                            isAdmin || record.status === 'Pending Transit' ?
                                                <Descriptions.Item label="Action">
                                                    <Button
                                                        type="primary" ghost
                                                        disabled={!resultsAreValid}
                                                        onClick={async () => {
                                                            if (isEditing) {
                                                                try {
                                                                    await saveMassSpecRow({ ...record, results: massSpectrometryResultsData[record.id] });
                                                                    message.success(`Request info for ${record.id.slice(0, 8).toUpperCase()} saved.`);
                                                                    setIsEditing(false);
                                                                } catch {
                                                                    message.error(`Failed to save request info for ${record.id.slice(0, 8).toUpperCase()}.`);
                                                                }
                                                            } else {
                                                                setIsEditing(true);
                                                            }
                                                        }}
                                                    >
                                                        {isEditing ? 'Save' : 'Edit'}
                                                    </Button>
                                                    {
                                                        username === record.username ?
                                                            <Button
                                                                type="primary" danger ghost
                                                                style={{ float: 'right' }}
                                                                onClick={() => {
                                                                    confirmRecordDelete(record);
                                                                }}
                                                            >
                                                                Delete
                                                            </Button> : null
                                                    }

                                                </Descriptions.Item> : null
                                        }

                                    </Descriptions>
                                )
                            }
                        }}
                        pagination={{
                            position: ['bottomCenter'],
                            pageSize: 10,
                            showSizeChanger: false,
                        }}
                        scroll={{ x: '875px' }}
                        sticky
                    >
                    </Table>
                </Col>
            </Row>
            <Tour open={massSpecTourOpen} onClose={() => { setMassSpecTourOpen(false); setIsEditing(true); }} steps={massSpecTourSteps} scrollIntoViewOptions={false} />
        </>
    )
}

export default MassSpectrometryTable;