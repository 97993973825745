import { useEffect, useRef, useState } from "react";
import { Row, Col, Typography, Carousel, Card, Button, Tag } from "antd";
import { CaretDownOutlined, CaretUpOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { useAtom } from "jotai";
import { allScanDataState, imageInfoSerialState, imageFilenameState, isImageModalOpenState, isTestInfoModalOpenState, riskAwarenessShouldUpdateState } from '../stateManagement/commonState';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { fetchDevices } from '../utils/dbHelpers';
import './RiskAwareness.css';

dayjs.extend(relativeTime);

const { Title, Text } = Typography;
const strengthOrder = {
    'Overdose': 1,
    'Very Strong': 2,
    'Strong': 3,
    'Normal': 4,
    'Weak': 5
};

const RiskAwareness = () => {
    const [allScanData] = useAtom(allScanDataState);
    const [, setImageInfoSerial] = useAtom(imageInfoSerialState);
    const [, setImageFilename] = useAtom(imageFilenameState);
    const [, setIsImageModalOpen] = useAtom(isImageModalOpenState);
    const [, setIsTestInfoModalOpen] = useAtom(isTestInfoModalOpenState);
    const [scansWithImages, setScansWithImages] = useState([]);
    const [slidesToShow, setSlidesToShow] = useState(1);
    const [numImagesPerRow, setNumImagesPerRow] = useState(null);
    const [numImageRows, setNumImageRows] = useState(null);
    const [currentRowIndex, setCurrentRowIndex] = useState(0);
    const [riskAwarenessShouldUpdate, setRiskAwarenessShouldUpdate] = useAtom(riskAwarenessShouldUpdateState);
    const carouselRef = useRef(null);
    const carouselSettings = {
        vertical: true,
        dots: false,
        infinite: false,
        swipeToSlide: true,
        draggable: true,
        slidesPerRow: 5,
        slidesToShow: slidesToShow,
        lazyLoad: true,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesPerRow: 4,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesPerRow: 3,
                }
            },
        ],
        beforeChange: (_, nextIndex) => setCurrentRowIndex(nextIndex)
    };

    const getNumberOfImagesPerRow = () => {
        if (window.innerWidth >= 900) {
            return 5;
        } else if (window.innerWidth > 600) {
            return 4;
        } else {
            return 3;
        }
    }

    const handleResize = () => {
        const numImagesPerRow = getNumberOfImagesPerRow();
        setNumImagesPerRow(numImagesPerRow);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        setNumImageRows(Math.ceil(scansWithImages.length / numImagesPerRow));
    }, [numImagesPerRow, scansWithImages]);

    useEffect(() => {
        setSlidesToShow(numImageRows >= 3 ? 3 : numImageRows);
    }, [numImageRows]);

    const updateImages = async (allScanData) => {
        let scansWithImages = allScanData.filter(e => (e.image_filename && e.substance_strength));
        if (scansWithImages.length === 0) {
            return;
        }
        let deviceIDs = [...new Set(scansWithImages.map(e => e.device_id))].filter(d => Boolean(d));
        let devices = await fetchDevices(deviceIDs);
        devices = devices.reduce((acc, obj) => { acc[obj.device_id] = { ...obj }; return acc; }, {});
        scansWithImages = scansWithImages.map(({ serial, username, image_filename, substance_strength, created_at, device_id }) => ({
            serial,
            username,
            image_filename,
            substance_strength,
            created_at,
            image_offset: devices[device_id].image_offset ?? { x: 0, y: -6 },
            image_transform: devices[device_id].image_transform ?? { "scale": 2.2, "rotate": "0deg", "translate": { "x": "0px", "y": "0px" } }
        }));
        let sortedScansWithImages = scansWithImages.sort((a, b) => {
            const strengthComparison = strengthOrder[a.substance_strength] - strengthOrder[b.substance_strength];
            if (strengthComparison !== 0) { return strengthComparison };
            return b.serial - a.serial;
        });
        setNumImageRows(Math.ceil(sortedScansWithImages.length / numImagesPerRow));
        setScansWithImages(sortedScansWithImages);
    }

    useEffect(() => {
        if (riskAwarenessShouldUpdate) {
            setRiskAwarenessShouldUpdate(false);
            setNumImagesPerRow(getNumberOfImagesPerRow());
            updateImages(allScanData);
        }
    }, [riskAwarenessShouldUpdate, allScanData]);


    const getColor = (strength) => {
        let color;
        switch (strength) {
            case 'Weak':
                color = null;
                break;
            case 'Normal':
                color = null;
                break;
            default:
                color = null;
        }
        if (strength === 'Strong') {
            color = "#ffd591";
        } else if (strength === 'Very Strong') {
            color = "#ffbb96";
        } else if (strength === 'Overdose') {
            color = "#ffa39e";
        }
        return color;
    }

    const handleCarouselUpDownRequest = next => {
        if (next && currentRowIndex + 1 < numImageRows - (slidesToShow - 1)) {
            carouselRef.current.next();
        } else if (!next) {
            carouselRef.current.prev();
        }
    }

    // const isVersionHigher = (version, targetVersion = "1.0.79") => {
    //     if (version === undefined) { return false }
    //     const versionParts = version.split(".");
    //     const targetParts = targetVersion.split(".");
    //     for (let i = 0; i < versionParts.length; i++) {
    //         if (parseInt(versionParts[i]) > parseInt(targetParts[i])) {
    //             return true;
    //         } else if (parseInt(versionParts[i]) < parseInt(targetParts[i])) {
    //             return false;
    //         }
    //     }
    //     return false;
    // }

    const extractVersion = (filename) => {
        const match = filename.match(/_(\d+\.\d+\.\d+)\.png$/);
        return match ? match[1] : undefined;
    };
    
    const isVersionHigher = (filename, targetVersion = "1.0.79") => {
        const version = extractVersion(filename);
        if (!version) return false;
    
        const versionParts = version.split(".");
        const targetParts = targetVersion.split(".");
    
        for (let i = 0; i < Math.max(versionParts.length, targetParts.length); i++) {
            const vPart = parseInt(versionParts[i] || 0);
            const tPart = parseInt(targetParts[i] || 0);
            
            if (vPart > tPart) return true;
            if (vPart < tPart) return false;
        }
    
        return false;
    };
    

    return (
        <>
            {
                scansWithImages.length ?
                    <>
                        <Row style={{ marginTop: '16px' }}>
                            <Title level={3}>Risk Awareness</Title>
                        </Row>
                        <Row justify="center" align="middle" style={{ marginBottom: '16px' }}>
                            <Col span={24} style={{ textAlign: "center", position: 'relative' }} >
                                <Carousel ref={carouselRef} className="riskAwarenessCarousel" {...carouselSettings} style={{ marginBottom: '8px', height: '100% !important' }}>
                                    {
                                        scansWithImages.map((e, i) => {

                                            // const transform = e.image_filename?.split('_')[3] && isVersionHigher(e.image_filename?.split('_')[3]) ?
                                            const transform = e.image_filename?.split('_')[3] && isVersionHigher(e.image_filename) ?
                                                `scale(${e?.image_transform?.scale}) translate(${e?.image_transform?.translate?.x}, ${e?.image_transform?.translate?.y}) rotate(${e?.image_transform?.rotate})`
                                                : `scale(2.2) translate(${e?.image_offset?.x}px, ${e?.image_offset?.y}px)`;

                                            return (
                                                <Card
                                                    key={i}
                                                    bordered={false}
                                                    bodyStyle={{
                                                        position: 'relative',
                                                        overflow: 'hidden',
                                                        borderRadius: '8px',
                                                        padding: 0,
                                                    }}
                                                    // styles={{
                                                    //     body: {
                                                    //         position: 'relative',
                                                    //         overflow: 'hidden',
                                                    //         borderRadius: '8px',
                                                    //         padding: 0,
                                                    //     }
                                                    // }}
                                                    onClick={() => {
                                                        setImageInfoSerial(e.serial);
                                                        setImageFilename(e.image_filename);
                                                        setIsImageModalOpen(true);
                                                        setIsTestInfoModalOpen(true);
                                                    }}
                                                >
                                                    <img
                                                        src={`https://scatr-sample-images.s3.amazonaws.com/${e.image_filename}`}
                                                        style={{
                                                            width: '100%',
                                                            height: '100%',
                                                            transform: transform
                                                        }}
                                                        onDragStart={(e) => { e.preventDefault() }}
                                                        alt=""
                                                    />
                                                    <Tag style={{
                                                        position: 'absolute',
                                                        bottom: '30px',
                                                        left: '50%',
                                                        transform: 'translateX(-50%)',
                                                        color: 'white',
                                                        borderColor: getColor(e.substance_strength),
                                                        background: 'rgb(67,67,67,0.5)'
                                                    }}>
                                                        {e.substance_strength}
                                                    </Tag>
                                                    <Text style={{
                                                        position: 'absolute',
                                                        bottom: '8px',
                                                        left: 0,
                                                        right: 0,
                                                        color: '#434343',
                                                    }}>
                                                        {dayjs(e.created_at).fromNow()}
                                                    </Text>
                                                    <InfoCircleOutlined className="sampleImageHoverIcon" />
                                                    {/* <div style={{
                                                    position: 'absolute',
                                                    top: `25%`,
                                                    left: `50%`,
                                                    transform: `translate(-50%, 0)`,
                                                    border: `solid 1px red`,
                                                    width: '36%',
                                                    aspectRatio: `1 / 1`,
                                                }}></div> */}
                                                </Card>
                                            )
                                        })
                                    }
                                </Carousel>
                                <Button style={{ float: 'right' }} onClick={() => handleCarouselUpDownRequest(true)} icon={<CaretDownOutlined />} />
                                <Button style={{ float: 'right' }} onClick={() => handleCarouselUpDownRequest(false)} icon={<CaretUpOutlined />} />
                            </Col>
                        </Row>
                    </>
                    : null
            }
        </>
    )

}

export default RiskAwareness;