import React, { useState, useEffect, useRef } from 'react';
import classes from './MassSpectrometry.module.css';
import { useAtom } from 'jotai';
import { Row, Col, Typography, Button, DatePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { userNameState, isAdminState, massSpectrometryTableDataState, massSpectrometryTableExpandedRowState, currentMassSpecIDState, massSpecTourOpenState } from '../../stateManagement/commonState';
import { PlusOutlined, RedoOutlined } from '@ant-design/icons';
import MassSpectrometryTable from './MassSpectrometryTable';
import { addMassSpecRow } from '../../utils/dbHelpers';
import PipelineSummaryTable from './PipelineSummaryTable';

const { Title } = Typography;
const { RangePicker } = DatePicker;

const defaultRange = {
    from: dayjs().subtract(8, 'weeks'),
    to: dayjs()
};

function MassSpectrometryContainer() {
    const [username] = useAtom(userNameState);
    const [isAdmin] = useAtom(isAdminState);
    const [massSpecDateRange, setMassSpecDateRange] = useState(defaultRange);
    const [massSpectrometryTableData, setMassSpectrometryTableData] = useAtom(massSpectrometryTableDataState);
    const [, setMassSpectrometryTableExpandedRow] = useAtom(massSpectrometryTableExpandedRowState);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [massSpecTourOpen, setMassSpecTourOpen] = useAtom(massSpecTourOpenState);
    const [currentMassSpecID, setCurrentMassSpecID] = useAtom(currentMassSpecIDState);
    const newMassSpecRowRef = useRef(null);

    const rangePickerChangeHandler = async (_, range) => {
        setMassSpecDateRange({ from: range[0], to: range[1] });
    }

    const addAnalysisRequestHandler = async () => {
        let res = await addMassSpecRow(username);
        setCurrentMassSpecID(res[0].id);
        newMassSpecRowRef.current = { key: res[0].id, ...res[0] };
        setFilteredInfo({});
    }

    useEffect(() => {
        if (currentMassSpecID) {
            setMassSpectrometryTableData([newMassSpecRowRef.current, ...massSpectrometryTableData]);
            setMassSpectrometryTableExpandedRow([newMassSpecRowRef.current?.key]);
            setMassSpecTourOpen(true);
            newMassSpecRowRef.current = null;
        }
    }, [currentMassSpecID])

    return (
        <>
            <Row style={{ marginTop: '12px' }}>
                <Title level={3}>Laboratory Tests</Title>
            </Row>
            <Row>
                <Col span={24} style={{ display: 'flex', alignItems: 'center' }}>
                    <Tooltip title={isAdmin ? "Add Analysis" : "Request Analysis"} placement="topLeft">
                        <Button
                            type="default"
                            icon={<PlusOutlined />}
                            onClick={() => { addAnalysisRequestHandler() }}
                        />
                    </Tooltip>
                    <RangePicker
                        style={{ flex: '1', margin: '0 3px' }}
                        className={classes.rangePicker}
                        onChange={rangePickerChangeHandler}
                        value={[dayjs(massSpecDateRange.from), dayjs(massSpecDateRange.to)]}
                        presets={[
                            { label: 'This Week', value: [dayjs().startOf('week'), dayjs().endOf('week')] },
                            { label: 'Last Week', value: [dayjs().subtract(1, 'weeks').startOf('week'), dayjs().subtract(1, 'weeks').endOf('week')] },
                            { label: 'This Month', value: [dayjs().startOf('month'), dayjs().endOf('month')] },
                            { label: 'Last 4 Weeks', value: [dayjs().subtract(3, 'weeks').startOf('week'), dayjs().endOf('week')] },
                            { label: 'Last Month', value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] },
                            { label: 'This Year', value: [dayjs().startOf('year'), dayjs().endOf('year')] }
                        ]}
                        allowClear={false}
                        format={(value) => dayjs(value).format('MMM D, YYYY')}
                    />
                    <Tooltip title="Clear Filters" placement="topRight">
                        <Button
                            type="default"
                            icon={<RedoOutlined />}
                            onClick={() => { setFilteredInfo({}); }}
                        />
                    </Tooltip>
                </Col>
            </Row>
            <Row justify="center" align="middle">
                <Col span={24} style={{ textAlign: "center" }} >
                    <MassSpectrometryTable massSpecDateRange={massSpecDateRange} filteredInfo={filteredInfo} setFilteredInfo={setFilteredInfo} />
                </Col>
            </Row>
            {
                isAdmin ?
                    <>
                        <Row style={{ marginTop: '12px' }}>
                            <Title level={3}>Pipeline Summary</Title>
                        </Row>
                        <Row justify="center" align="middle">
                            <Col span={24} style={{ textAlign: "center" }} >
                                <PipelineSummaryTable />
                            </Col>
                        </Row>
                    </> : null
            }
        </>
    )
}

export default MassSpectrometryContainer;
