export const getIntensityScatterChartConfig = (colors, data, map, numPoints) => {
    return {
        tooltip: {
            trigger: 'axis',
            position: function (pos, params, dom, rect, size) {
                var obj = { top: 60 };
                obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 5;
                return obj;
            },
            axisPointer: {
                type: 'shadow'
            },
            formatter: function (params) {
                return `
                <table>
                <tr>
                <td>Index</td>
                <td>${params[0].value[0]}</td>
                </tr>
                <tr>
                <td>Label</td>
                <td>${params[0].value[2]}</td>
                </tr>
                <tr>
                <td>Coordinate</td>
                <td>(${params[0].value[3]}, ${params[0].value[4]}, ${params[0].value[5]})</td>
                </tr>
                ${params[0].value[6] ? `
                <tr>
                <td>Laser Power</td>
                <td>${params[0].value[7]}</td>
                </tr>
                <tr>
                <tr>
                <td style="padding-right:5px">Integration Time</td>
                <td>${params[0].value[8]}</td>
                </tr>
                <tr>
                <td>Intensity</td>
                <td>${params[0].value[1]}</td>
                </tr>
                <tr>
                <td>RE</td>
                <td>${params[0].value[9]}</td>
                </tr>
                <tr>
                <td>Output</td>
                </tr>
                <tr>
                <td></td>
                <td>${params[0].value[6][0][0]}</td>
                <td>(${params[0].value[6][0][1]})</td>
                </tr>
                <tr>
                <td></td>
                <td>${params[0].value[6][1][0]}</td>
                <td>(${params[0].value[6][1][1]})</td>
                </tr>
                ` : ''}
                </table>
                `;
            }
        },
        dataZoom: [
            {
                startValue: 0
            },
            {
                type: 'inside'
            }
        ],
        xAxis: {
            type: 'value',
        },
        yAxis: {
            type: 'value',
            scale: true
        },
        grid: {
            top: '10px',
            right: '15px',
            bottom: '50px',
            left: '15px',
            containLabel: true,
          },
        series: [
            {
                type: 'scatter',
                dimensions: ['index', 'intensity', 'label', 'x', 'y', 'z'],
                data: new Array(numPoints).fill(null).map(_ => 0),
                symbolSize: 6,
                markArea: {
                    silent: true,
                    itemStyle: {
                        color: 'rgba(24, 144, 255, 0.05)'
                    },
                    data
                }
            }
        ],
        visualMap: {
            top: 10,
            left: 'center',
            type: 'scroll',
            orient: 'horizontal',
            type: 'piecewise',
            dimension: 2,
            categories: [...new Set(map.map(e => e.label))],
            inRange: {
                colors
            },
            showLabel: false,
            itemSymbol: 'none',
        }
    }
};